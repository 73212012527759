export const forgotPasswordStrings = {
    headerText: {
        tr: 'Şifremi unuttum',
        en: 'Forgot password'
    },
    orText: {
        tr: 'veya',
        en: 'or'
    },
    loginText: {
        tr: 'Giriş yap',
        en: 'Login'
    },
    emailLabel: {
        tr: 'E-posta',
        en: 'E-mail'
    },
    emailPlaceholder: {
        tr: 'E-posta adresiniz',
        en: 'Your e-mail address'
    },
    buttonText: {
        tr: 'Şifreyi sıfırla',
        en: 'Reset password'
    },
    completedText: {
        tr: 'E-posta, parola sıfırlama bağlantısını içeren <email> adresine gönderildi. Şifrenizi belirledikten sonra giriş yapabilirsiniz.',
        en: 'E-mail has been sent to <email> including the password reset link. You can login after setting your password.'
    }
}