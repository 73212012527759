import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import UserContext from '../../contexts/UserContext';
import HeaderWrapper from '../HeaderWrapper';
import { formContainerStrings } from './constants';
import iconClasses from '../../constants/iconClasses';

const FormHeader = ({ hasList, goBackUrl }) => {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const { pathname } = window.location;

    const lastSlashIndex = pathname.lastIndexOf('/');

    const url = goBackUrl || pathname.substring(0, lastSlashIndex);

    return (
        <HeaderWrapper>
            <Button
                className="cms-button-outlined"
                onClick={() => navigate(url)}
                disabled={!hasList || !url}
                icon={iconClasses.arrowLeft}
            >
                {formContainerStrings.goBackText[lang]}
            </Button>
            <div className="flex-grow-1" />
        </HeaderWrapper>
    );
};

export default FormHeader;
