import { useContext, useEffect, useState } from 'react';
import Table from '../../components/Table';
import UserContext from '../../contexts/UserContext';
import { callRoute } from '../../helpers/request';
import CardWrapper from '../CardWrapper';
import ListHeader from './ListHeader';
import Select from '../../components/Select';
import FormContext from '../../contexts/FormContext';

const ListContainer = ({
    route = '',
    headerMapping = {},
    valueMapping = {},
    newButtonText = 'New',
    titleText = 'Title',
    disableNew = false,
    customFilter = {},
    editActionAllowed = true,
    deleteActionAllowed = true,
    customActions = [],
    disableHeader = false,
    filters = [],
    noCard = false,
}) => {
    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const { lang } = userContext;

    const [data, setData] = useState([]);

    const [localFilters, setLocalFilters] = useState({});

    const [localFilterOptions, setLocalFilterOptions] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    const load = async () => {
        setIsLoading(true);

        try {
            // add pagination by states + cache on pages
            const { data } = await callRoute({
                route,
                method: 'GET',
                lang,
                params: {
                    ...customFilter,
                    ...localFilters,
                },
            });

            setData(data);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    };

    const loadFilterOptions = async () => {
        // add route to filters

        for (let filter of filters) {
            if (filter.options) {
                const x = {};

                x[filter.field.name] = filter.options;

                setLocalFilterOptions(x);
            }
        }
    };

    useEffect(() => {
        const dispatch = async () => {
            formContext.init(filters.map((item) => item.field));

            await loadFilterOptions();

            formContext.update({});
        };

        dispatch();
    }, []);

    // useEffect(() => {
    //     setLocalFilters({
    //         ...formContext.data,
    //     });
    // }, [formContext.data]);

    // useEffect(() => {
    //     load();
    // }, [localFilters]);

    useEffect(() => {
        load();
    }, []);

    const content = (
        <>
            <h3 className="my-3">{titleText}</h3>
            <div className="row">
                {filters.map((item) => (
                    <div className="col-6 col-lg-3">
                        <Select
                            field={item.field}
                            options={localFilterOptions[item.field.name]}
                        />
                    </div>
                ))}
            </div>
            <Table
                data={data}
                headerMapping={headerMapping}
                valueMapping={valueMapping}
                editActionAllowed={editActionAllowed}
                deleteActionAllowed={deleteActionAllowed}
                route={route}
                load={load}
                customActions={customActions}
                isLoading={isLoading}
            />
        </>
    );

    if (
        filters.length > 0 &&
        Object.values(localFilters).filter(
            (item) => item !== null && item !== undefined
        ).length === 0
    )
        return null;

    return (
        <>
            {!disableHeader && (
                <ListHeader
                    newButtonText={newButtonText}
                    disableNew={disableNew}
                />
            )}
            {noCard ? <div className='p-3'>{content}</div> : <CardWrapper>{content}</CardWrapper>}
        </>
    );
};

export default ListContainer;
