import React, { useContext } from 'react';
import {  useNavigate } from 'react-router-dom';
import { companyStrings } from './constants';
import iconClasses from '../../../constants/iconClasses';
import Button from '../../../components/Button';
import HeaderWrapper from '../../../reusables/HeaderWrapper';
import UserContext from '../../../contexts/UserContext';

const CompanyPortalHeader = ({
    goBackUrl,
}) => {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const { lang } = userContext;

    return (
        <HeaderWrapper>
            <Button
                className="cms-button-outlined"
                onClick={() => navigate(goBackUrl)}
                disabled={!goBackUrl}
                icon={iconClasses.arrowLeft}
            >
                {companyStrings.goBackText[lang]}
            </Button>
            <div className="flex-grow-1" />
        </HeaderWrapper>
    );
};

export default CompanyPortalHeader;
