import cookieNames from '../../constants/cookieNames';
import { getCookie } from '../cookie';
import { getParam } from '../url';

export const resolveOptions = (
    method = 'GET',
    body = null,
    headers = {},
    signal = null
) => {
    const options = {
        method,
        headers,
    };

    if (body)
        options.body = body instanceof FormData ? body : JSON.stringify(body);

    const headerKeys = Object.keys(headers);

    const token = getParam('token') || getCookie(cookieNames.jwt);

    if (token) options.headers['Authorization'] = `Basic ${token}`;

    if (
        headerKeys.indexOf('Content-Type') === -1 &&
        !(body instanceof FormData)
    )
        options.headers['Content-Type'] = 'application/json';

    if (headerKeys.indexOf('Accept') === -1)
        options.headers['Accept'] = 'application/json';

    if (signal) options.signal = signal;

    return options;
};
