export const depositProductStrings = {
    listTitleText: {
        tr: 'Bakiye paketleri',
        en: 'Deposit products',
    },
    createFormTitleText: {
        tr: 'Yeni bakiye paketi',
        en: 'New deposit product',
    },
    newDepositProductButtonText: {
        tr: 'Yeni bakiye paketi',
        en: 'New deposit product',
    },
};

export const depositProductFields = {
    name: {
        name: 'name',
        label: {
            tr: 'Paket ismi',
            en: 'Product name',
        },
        showInList: true,
    },
    price: {
        name: 'price',
        label: {
            tr: 'Fiyat',
            en: 'Price',
        },
        showInList: true,
    },
    tokenAmount: {
        name: 'tokenAmount',
        label: {
            tr: 'Jeton miktarı',
            en: 'Token amount',
        },
        showInList: true,
    },
    countryId: {
        name: 'countryId',
        label: {
            tr: 'Ülke',
            en: 'Country',
        },
        showInList: true,
    },
};