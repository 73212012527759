import React, { useContext } from 'react';
import UserContext from '../../../../contexts/UserContext';
import { routes } from '../../../../helpers/request';
import ListContainer from '../../../../reusables/ListContainer';
import { getFieldMapping } from '../../../../helpers/mapping';
import { getDateListMapping } from '../../../../helpers/list';
import { blackoutStrings, blackoutFields } from './constants';

const BlackoutList = ({}) => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    return (
        <ListContainer
            route={routes.blackout}
            headerMapping={getFieldMapping(blackoutFields, 'label')}
            valueMapping={{
                startDate: getDateListMapping,
                endDate: getDateListMapping,
            }}
            titleText={blackoutStrings.listTitleText[lang]}
            newButtonText={blackoutStrings.newDepositProductButtonText[lang]}
            editActionAllowed={false}
        />
    );
};

export default BlackoutList;
