import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import iconClasses from '../../constants/iconClasses';
import UserContext from '../../contexts/UserContext';
import { callRoute } from '../../helpers/request';
import {
    showConfirmationModal,
    showErrorNotification,
    showSuccessNotification,
} from '../../helpers/popup';
import Button from '../Button';
import Icon from '../Icon';
import { tableStrings } from './constants';

import './styles.scss';
import FormContext from '../../contexts/FormContext';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from '../Select';

const Table = ({
    data = [],
    headerMapping = {},
    valueMapping = {},
    editActionAllowed = false,
    deleteActionAllowed = false,
    route = '',
    load = () => {},
    customActions = [],
    hideActions = () => false,
    isLoading = false,
}) => {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const [submittingItemId, setSubmittingItemId] = useState(null);

    const { lang, currency } = userContext;

    const { lists } = formContext;

    const headerMappingExists = Object.keys(headerMapping).length > 0;

    const isActionsPresent =
        editActionAllowed || deleteActionAllowed || customActions.length > 0;

    const handleDelete = async (_id) => {
        setSubmittingItemId(_id);

        try {
            showConfirmationModal(
                async () => {
                    try {
                        const { data } = await callRoute({
                            route,
                            method: 'DELETE',
                            body: {
                                _id,
                            },
                            lang,
                        });

                        if (data?.name || data?.fullName)
                            showSuccessNotification(
                                (data?.name || data?.fullName) +
                                    tableStrings.deletedSuccessfuly[lang]
                            );

                        load();
                    } catch (e) {
                        showErrorNotification(e);
                    }
                },
                lang,
                tableStrings.deleteConfirmationTitle[lang],
                tableStrings.deleteConfirmationDescription[lang]
            );
        } catch (error) {
            showErrorNotification(error);
        }

        setSubmittingItemId(null);
    };

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 32,
                    marginBottom: 32,
                }}
            >
                <ClipLoader color={'#003850'} size={40} />
            </div>
        );
    } else if (data.length === 0) {
        return (
            <p className="my-4 text-align-center" style={{ fontSize: 17 }}>
                {tableStrings.noRecord[lang]}
            </p>
        );
    }

    return (
        <>
            <table className="cms-table table">
                <thead className="cms-table-head">
                    <tr className="cms-table-row">
                        {Object.keys(
                            headerMappingExists ? headerMapping : data[0]
                        ).map((key) => (
                            <th className="cms-table-cell">
                                {headerMappingExists
                                    ? headerMapping[key][lang]
                                    : key}
                            </th>
                        ))}
                        {isActionsPresent && (
                            <th className="cms-table-cell">
                                {tableStrings.actions[lang]}
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody className="cms-table-body">
                    {data.map((item) => (
                        <tr className="cms-table-row">
                            {Object.keys(
                                headerMappingExists ? headerMapping : item
                            ).map((key) => (
                                <td className="cms-table-cell">
                                    {Object.keys(valueMapping).indexOf(key) > -1
                                        ? valueMapping[key]({
                                              value: item[key],
                                              lang,
                                              lists,
                                              entity: item,
                                              currency,
                                          })
                                        : item[key]}
                                </td>
                            ))}
                            {isActionsPresent && !hideActions(item) && (
                                <td className="cms-table-cell">
                                    <div className="d-flex">
                                        {customActions.map((itemAction) => (
                                            <Button
                                                onClick={() => {
                                                    setSubmittingItemId(
                                                        item._id
                                                    );

                                                    itemAction.action(
                                                        item,
                                                        load
                                                    );

                                                    setSubmittingItemId(null);
                                                }}
                                                className={
                                                    'cms-table-button mx-2 ' +
                                                    itemAction.className
                                                }
                                                disabled={
                                                    submittingItemId ===
                                                        item._id ||
                                                    (itemAction.disabled &&
                                                        !itemAction.disabled(
                                                            item
                                                        ))
                                                }
                                            >
                                                <Icon
                                                    className={itemAction.icon}
                                                />
                                            </Button>
                                        ))}
                                        {editActionAllowed && (
                                            <Button
                                                onClick={() =>
                                                    navigate(item._id)
                                                }
                                                className="cms-table-button mx-2"
                                                disabled={
                                                    submittingItemId ===
                                                    item._id
                                                }
                                            >
                                                <Icon
                                                    className={iconClasses.edit}
                                                />
                                            </Button>
                                        )}
                                        {deleteActionAllowed && (
                                            <Button
                                                onClick={() =>
                                                    handleDelete(item._id)
                                                }
                                                className="cms-table-button mx-2"
                                                disabled={
                                                    submittingItemId ===
                                                    item._id
                                                }
                                            >
                                                <Icon
                                                    className={
                                                        iconClasses.delete
                                                    }
                                                />
                                            </Button>
                                        )}
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default Table;
