import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../../contexts/UserContext';
import { routes } from '../../../../helpers/request';
import FormContainer from '../../../../reusables/FormContainer';
import { blackoutFields, blackoutStrings } from './constants';
import pages from '../../../../constants/pages';
import DatePicker from '../../../../components/DatePicker';
import FormContext from '../../../../contexts/FormContext';

const BlackoutForm = () => {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const { lang } = userContext;

    const { startDate, endDate } = blackoutFields;

    return (
        <FormContainer
            route={routes.blackout}
            fields={blackoutFields}
            createTitleText={blackoutStrings.createFormTitleText[lang]}
            goBackUrl={pages.blackoutList.path}
            afterSubmit={() => navigate(pages.blackoutList.path)}
        >
            <div className="col-lg-6">
                <DatePicker field={startDate} minDate={new Date()} />
            </div>
            <div className="col-lg-6">
                <DatePicker
                    field={endDate}
                    minDate={new Date(formContext.data.startDate)}
                    disabled={!formContext.data.startDate}
                />
            </div>
        </FormContainer>
    );
};

export default BlackoutForm;
