import React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import './styles.scss';

const ForgotPassword = () => {
    return (
        <div className="forgot-password-container">
            <ForgotPasswordForm />
            <div className="forgot-password-wallpaper-container">
              
            </div>
        </div>
    );
};

export default ForgotPassword;