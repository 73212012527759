import React, { useContext } from 'react';
import FormContext from '../../contexts/FormContext';
import UserContext from '../../contexts/UserContext';
import './styles.scss';
import { default as DatePicker, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';
import { numbersToTime } from '../../helpers/string';
import { timePickerConstants } from './constants';
import { languages } from '../../constants/languages';

registerLocale('tr', tr);

const SLOT_STEP = 15;

const TimePicker = ({
    field = null,
    name = '',
    label = '',
    value = '',
    placeholder = '',
    onChange = null,
    containerStyle = {},
    labelStyle = {},
}) => {
    const formContext = useContext(FormContext);

    const userContext = useContext(UserContext);

    const { lang } = userContext;

    if (field?.name) name = field.name;

    if (field?.label) label = field.label[lang];

    if (field?.placeholder) placeholder = field.placeholder[lang];

    const update = (value) => {
        const updateObject = {};

        updateObject[name] = value;

        formContext.update(updateObject);
    };

    const timeToDate = (time) => {
        if (!time) return null;

        const timeComponents = time.split(':');

        const hour = parseInt(timeComponents[0]);

        const minute = parseInt(timeComponents[1]);

        const result = new Date();

        result.setHours(hour);

        result.setMinutes(minute);

        return result;
    };

    const dateToTime = (date) => {
        if (!date) return null;

        const hour = date.getHours();

        const minute = date.getMinutes();

        return numbersToTime(hour, minute);
    };

    const getLocale = () => {
        if (lang === languages.tr) {
            return {
                locale: languages.tr,
                format: 'HH:mm',
            };
        } else if (lang === languages.en) {
            return {
                locale: languages.en,
                format: 'HH:mm a',
            };
        }

        return null;
    };

    return (
        <>
            <div style={containerStyle} className="cms-form-control">
                {label && (
                    <label
                        style={labelStyle}
                        htmlFor={name}
                        className="cms-form-label"
                    >
                        {label}
                    </label>
                )}
                <DatePicker
                    id={name}
                    className="cms-datetime-picker"
                    onChange={(time) =>
                        onChange
                            ? onChange(dateToTime(time))
                            : update(dateToTime(time))
                    }
                    selected={timeToDate(value || formContext.data[name])}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={SLOT_STEP}
                    placeholderText={placeholder}
                    locale={getLocale().locale}
                    timeCaption={timePickerConstants.timeText[lang]}
                    dateFormat={getLocale().format}
                    isClearable
                    onFocus={(e) => {
                        e.target.readOnly = true;
                    }}
                />
            </div>
        </>
    );
};

export default TimePicker;
