import React, { useContext, useRef, useState } from 'react';
import FormContext from '../../contexts/FormContext';
import UserContext from '../../contexts/UserContext';
import { default as DatePicker, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import tr from 'date-fns/locale/tr';
import { dateTimePickerConstants } from './constants';
import { getTimeZone, timeToLocale } from '../../helpers/date';
import { callRoute, routes } from '../../helpers/request';
import { parseSlot } from '../../helpers/reservation';
registerLocale('tr', tr);

const DateTimePicker = ({
    field = null,
    name = '',
    label = '',
    value = null,
    onChange = null,
    containerStyle = {},
    labelStyle = {},
    placeholder = '',
    required = false,
    filterTime = null,
    disabled = false,
    useAvailability = false,
    reservationStaffId = null,
    reservationTypeId = null,
    excludeReservationIds = [],
    minDate = null,
    maxDate = null,
}) => {
    const abortController = useRef(null);

    const formContext = useContext(FormContext);

    const userContext = useContext(UserContext);

    const [allowedDates, setAllowedDates] = useState([]);

    const { lang } = userContext;

    if (field) {
        if (field.name) name = field.name;
        if (field.label) label = field.label[lang];
        if (field.placeholder) placeholder = field.placeholder[lang];
        if (field.required) required = field.required;
    }

    const update = (value) => {
        const updateObject = {};
        updateObject[name] = value;
        formContext.update(updateObject);
    };

    const defaultFilterTime = (time) => {
        if (!useAvailability) return true;

        if (!reservationStaffId || !reservationTypeId) return false;

        const currentDate = new Date(time);

        return allowedDates.indexOf(currentDate.getTime()) > -1;
    };

    const handleDateSelect = async (date) => {
        if (!useAvailability || !reservationStaffId || !reservationTypeId)
            return;

        const payloadDate = new Date(date);

        payloadDate.setHours(0);

        payloadDate.setMinutes(0);

        payloadDate.setSeconds(0);

        payloadDate.setMilliseconds(0);

        try {
            if (abortController.current) abortController.current.abort();

            abortController.current = new AbortController();

            const { data } = await callRoute({
                route: routes.reservationAvailableSlots,
                method: 'POST',
                lang,
                body: {
                    reservationStaffId,
                    reservationTypeId,
                    timezone: getTimeZone(),
                    date: payloadDate,
                    excludeReservationIds,
                },
                signal: abortController.current.signal,
            });

            const selectedDate = new Date(date);

            selectedDate.setSeconds(0);

            selectedDate.setMilliseconds(0);

            const slots = data.slots[reservationStaffId];

            const allowedDates = slots.map((slot) => {
                const [hour, minute] = parseSlot(timeToLocale(slot));

                const allowedDate = new Date(selectedDate);

                allowedDate.setHours(hour);

                allowedDate.setMinutes(minute);

                return allowedDate.getTime();
            });

            setAllowedDates(allowedDates);
        } catch (e) {
            console.error(e);
        }
    };

    const selected = value || formContext.data[name];

    return (
        <div style={containerStyle} className="cms-form-control">
            {label && (
                <label
                    style={labelStyle}
                    htmlFor={name}
                    className="cms-form-label"
                >
                    {label}{' '}
                    {required && <span style={{ color: 'red' }}>*</span>}
                </label>
            )}
            <DatePicker
                id={name}
                className="cms-datetime-picker"
                onChange={(date) => (onChange ? onChange(date) : update(date))}
                selected={selected}
                showTimeSelect
                timeIntervals={15}
                dateFormat="Pp"
                placeholderText={placeholder}
                locale={lang}
                timeCaption={dateTimePickerConstants.timeText[lang]}
                isClearable={!disabled}
                filterTime={filterTime || defaultFilterTime}
                disabled={disabled}
                onSelect={handleDateSelect}
                onFocus={(e) => {
                    e.target.readOnly = true;

                    selected && handleDateSelect(new Date(selected));
                }}
                minDate={minDate}
                maxDate={maxDate}
            />
        </div>
    );
};

export default DateTimePicker;
