import { enMonthMapping, trMonthMapping } from '../../constants/date';
import { languages } from '../../constants/languages';

export const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
};

export const addHours = (date, hours) => {
    date.setHours(date.getHours() + hours);
    return date;
};

export const addMinutes = (date, minutes) => {
    date.setMinutes(date.getMinutes() + minutes);
    return date;
};

export const getTimeZone = () => {
    const offset = new Date().getTimezoneOffset(),
        o = Math.abs(offset);
    return Math.floor(o / 60);
};

export const getMonthString = (month, lang) => {
    if (lang === languages.tr) return trMonthMapping[month];
    else if (lang === languages.en) return enMonthMapping[month];
    return '';
};

export const dateToUtc = (date) => {
    const now_utc = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );

    return now_utc;
};

export const dateToLocale = (date) => {
    const now_locale = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    );

    return new Date(now_locale);
};

export const getCustomDayIndex = (day) => {
    const map = {
        0: 6,
        1: 0,
        2: 1,
        3: 2,
        4: 3,
        5: 4,
        6: 5,
    };

    return map[day];
};

export const timeToUtc = (time, timezone = getTimeZone()) => {
    if (!time) return null;

    const timeComponents = time.split(':');

    const hours = parseInt(timeComponents[0]);

    const newHour = hours - timezone;

    if (newHour < 0)
        return (
            (newHour > -10 ? '-0' : '-') +
            Math.abs(newHour).toString() +
            ':' +
            timeComponents[1]
        );

    return (
        (newHour < 10 ? '0' : '') + newHour.toString() + ':' + timeComponents[1]
    );
};

export const timeToLocale = (time, timezone = getTimeZone()) => {
    if (!time) return null;

    const timeComponents = time.split(':');

    const hours = parseInt(timeComponents[0]);

    const newHour = hours + timezone;

    if (newHour < 0)
        return (
            (newHour > -10 ? '-0' : '-') +
            Math.abs(newHour).toString() +
            ':' +
            timeComponents[1]
        );

    return (
        (newHour < 10 ? '0' : '') + newHour.toString() + ':' + timeComponents[1]
    );
};
