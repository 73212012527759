import { useContext, useEffect, useState } from 'react';
import CardWrapper from '../../../../reusables/CardWrapper';
import DepositProduct from '../../../../components/DepositProduct';
import { callRoute, routes } from '../../../../helpers/request';
import UserContext from '../../../../contexts/UserContext';
import { depositFields, depositStrings } from './constants';
import Table from '../../../../components/Table';
import { getFieldMapping } from '../../../../helpers/mapping';
import {
    getDateListMapping,
    getPaymentStatusListMapping,
    getPriceListMapping,
    getPaymentMethodMapping,
    getTokenAmountListMapping,
} from '../../../../helpers/list';
import DepositCheckoutModal from '../../../../modals/DepositCheckoutModal';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../../helpers/popup';

const Deposit = ({ iframe }) => {
    const userContext = useContext(UserContext);

    const [depositProducts, setDepositProducts] = useState([]);

    const [depositHistory, setDepositHistory] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [depositItem, setDepositItem] = useState(null);

    const { lang, company, currency } = userContext;

    const onTransfer = async () => {
        try {
            await callRoute({
                route: routes.depositTransfer,
                method: 'POST',
                body: {
                    depositProductId: depositItem._id,
                },
                lang,
            });

            loadDepositHistory();

            showSuccessNotification(
                depositStrings.depositCreatedSuccessfuly[lang]
            );
        } catch (e) {
            showErrorNotification(e);
        }
    };

    const loadDepositProducts = async () => {
        try {
            let { data } = await callRoute({
                route: routes.depositProduct,
                method: 'GET',
                params: {
                    countryId: company.country._id,
                },
                lang,
            });

            data = data.sort((a, b) => a.price > b.price);

            setDepositProducts(data);
        } catch (error) {
            console.error(error);
        }
    };

    const loadDepositHistory = async () => {
        try {
            const { data } = await callRoute({
                route: routes.deposit,
                method: 'GET',
                lang,
            });

            setDepositHistory(data);
        } catch (error) {
            console.error(error);
        }
    };

    const load = async () => {
        setIsLoading(true);

        loadDepositProducts();

        loadDepositHistory();

        setIsLoading(false);
    };

    useEffect(() => {
        if (company) load();
    }, [company]);

    return (
        <>
            <CardWrapper>
                <h3 className="my-3">
                    {depositStrings.packagesTitleText[lang]}
                </h3>
                <div className="row">
                    {depositProducts.map((item) => (
                        <div className="col-6 col-xl-3">
                            <DepositProduct
                                item={item}
                                onClick={() =>
                                    setDepositItem({
                                        ...item,
                                        currency,
                                    })
                                }
                            />
                        </div>
                    ))}
                </div>
                <div className="alert alert-info" role="alert">
                    {depositStrings.depositInfoDetails[lang].map((item) => (
                        <p>{item}</p>
                    ))}
                </div>
                <h3 className="my-3">
                    {depositStrings.historyTitleText[lang]}
                </h3>
                <Table
                    data={depositHistory}
                    headerMapping={getFieldMapping(depositFields, 'label')}
                    valueMapping={{
                        createdAt: getDateListMapping,
                        status: getPaymentStatusListMapping,
                        amount: getPriceListMapping,
                        tokenAmount: getTokenAmountListMapping,
                        paymentMethod: getPaymentMethodMapping,
                    }}
                    editActionAllowed={false}
                    deleteActionAllowed={false}
                    load={loadDepositHistory}
                    isLoading={isLoading}
                />
            </CardWrapper>
            {depositItem && (
                <DepositCheckoutModal
                    title={depositStrings.modalTitleText[lang]}
                    item={depositItem}
                    onTransfer={onTransfer}
                    onClose={() => setDepositItem(null)}
                />
            )}
        </>
    );
};

export default Deposit;
