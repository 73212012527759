import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cookieNames from '../constants/cookieNames';
import { languages } from '../constants/languages';
import pages from '../constants/pages';
import { deleteCookie, getCookie, setCookie } from '../helpers/cookie';
import { callRoute, routes } from '../helpers/request';
import {
    showErrorNotification,
    showSuccessNotification,
    showWarningNotification,
} from '../helpers/popup';
import { getPage, getParam } from '../helpers/url';
import images from '../images';
import { reservationStatuses } from '../constants/reservationStatuses';
import { addDays } from '../helpers/date';
import { userContextStrings } from './constants';

const UserContext = createContext(null);

export const UserContextProvider = ({ children }) => {
    const navigate = useNavigate();

    const [data, setData] = useState(null);

    const [company, setCompany] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [lang, setLang] = useState(languages.tr);

    const [currency, setCurrency] = useState(null);

    const [isAdmin, setIsAdmin] = useState(false);

    const [isAffiliate, setIsAffiliate] = useState(false);

    const [credit, setCredit] = useState(0);

    const [pendingReservationCount, setPendingReservationCount] = useState(0);

    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);

    const login = async (email, password, isAffiliate = false) => {
        try {
            const response = await callRoute({
                route: routes.login,
                method: 'POST',
                body: {
                    email,
                    password,
                    isAffiliate,
                },
                lang,
            });

            const { message, data } = response;

            const { token } = data;

            const today = new Date();

            const expireDate = addDays(today, 30);

            setCookie(cookieNames.jwt, token, {
                expires: expireDate,
            });

            await load();

            showSuccessNotification(message);
        } catch (error) {
            showErrorNotification(error);
        }
    };

    const logout = () => {
        const jwt = getCookie(cookieNames.jwt);

        if (jwt) {
            deleteCookie(cookieNames.jwt);

            setData(null);

            setCompany(null);

            setCurrency(null);

            setCredit(0);

            setIsAffiliate(false);

            navigate(pages.login.path);
        }

        setIsLoading(false);
    };

    const load = async () => {
        const jwt = getCookie(cookieNames.jwt);

        if (jwt) {
            setIsLoading(true);

            try {
                const response = await callRoute({
                    route: routes.userData,
                    method: 'GET',
                    lang,
                });

                const { user, company, isAffiliate } = response.data;

                if (isAffiliate) {
                    setData(user);

                    setLang(user.lang);

                    setIsAffiliate(true);

                    navigate(pages.affiliateDashboard.path);

                    return;
                }

                setLang(company.lang);

                const page = getPage(user.isAdmin);

                setIsAdmin(user.isAdmin && (page.isAdmin || page.guestPage));

                setData(user);

                setCompany(company);

                setCurrency(company.country.currency);

                setCredit(company.credit);
            } catch (error) {
                console.error(error);
            }
        }

        setIsLoading(false);
    };

    const checkNotifications = async () => {
        if (!data?._id) return;

        try {
            const { data } = await callRoute({
                route: routes.getNotifications,
                method: 'GET',
                lang,
            });

            data.forEach((item) => {
                new Notification(item.title, {
                    body: item.body,
                    icon: images.logo,
                });
            });

            setTimeout(() => checkNotifications(), 30000);
        } catch (error) {}
    };

    const checkPendingReservationCount = async (reccursive = false) => {
        if (!data?._id) return;

        try {
            const { data } = await callRoute({
                route: routes.reservation,
                method: 'GET',
                params: {
                    status: reservationStatuses.pending,
                },
                lang,
            });

            setPendingReservationCount((data || []).length);

            if (reccursive)
                setTimeout(() => checkPendingReservationCount(true), 15000);
        } catch (error) {}
    };

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (!isAffiliate) {
            checkNotifications();
            checkPendingReservationCount(true);
        }
    }, [data]);

    useEffect(() => {
        if (credit > 0 && credit < 50) {
            showWarningNotification(userContextStrings.lowCredit[company.lang]);
        }
    }, [credit]);

    return (
        <UserContext.Provider
            value={{
                data,
                isLoading,
                login,
                logout,
                lang,
                isAdmin,
                setIsAdmin,
                company,
                load,
                pendingReservationCount,
                checkPendingReservationCount,
                currency,
                credit,
                isAffiliate,
                isSidebarOpen,
                setIsSidebarOpen,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
