import React, { useContext } from 'react';
import FormContext from '../../contexts/FormContext';
import UserContext from '../../contexts/UserContext';
import './styles.scss';

const Toggle = ({
    field = null,
    name = '',
    label = '',
    value = '',
    onChange = null,
    labelStyle = {},
    containerStyle = {},
    inline = false,
}) => {
    const formContext = useContext(FormContext);
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    if (field) {
        if (field.name) name = field.name;
        if (field.label) label = field.label[lang];
    }

    const update = (value) => {
        const updateObject = {};
        updateObject[name] = value;
        formContext.update(updateObject);
    };

    return (
        <div style={containerStyle} className="cms-form-control">
            {label && !inline && (
                <label
                    style={labelStyle}
                    htmlFor={name}
                    className="cms-form-label"
                >
                    {label}
                </label>
            )}
            <div
                className="form-check form-switch form-switch-md"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 38,
                    paddingBottom: 4,
                    margin: 0,
                }}
            >
                <input
                    className="form-check-input d-flex align-items-center"
                    type="checkbox"
                    role="switch"
                    id={name}
                    onChange={(e) =>
                        onChange
                            ? onChange(e.target.checked)
                            : update(e.target.checked)
                    }
                    checked={value || formContext.data[name] || false}
                />
                {label && inline && (
                    <label
                        style={{
                            ...labelStyle,
                            marginBottom: 0,
                            marginLeft: 8,
                            marginTop: 3,
                        }}
                        htmlFor={name}
                        className="cms-form-label"
                    >
                        {label}
                    </label>
                )}
            </div>
        </div>
    );
};

export default Toggle;
