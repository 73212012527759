import React, { useContext, useEffect, useState } from 'react';
import { loadingFixedTextConstants } from './constants';
import UserContext from '../../contexts/UserContext';
import BeatLoader from 'react-spinners/BeatLoader';

import './styles.scss';

const LoadingFixedText = ({ isOpen }) => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    if (!isOpen) return null;

    return (
        <div className="cms-loading-fixed-text">
            <BeatLoader color={'#fff'} size={5} style={{ marginRight: 6 }} />
            {loadingFixedTextConstants.text[lang]}
        </div>
    );
};

export default LoadingFixedText;
