import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserContextProvider } from './contexts/UserContext';
import { FormContextProvider } from './contexts/FormContext';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Router>
            <UserContextProvider>
                <FormContextProvider>
                    <App />
                </FormContextProvider>
            </UserContextProvider>
        </Router>
    </React.StrictMode>
);

reportWebVitals();