import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ConfirmModal from '../../modals/ConfirmModal';
import CancellationModal from '../../modals/CancellationModal';

const toastOptions = {
    position: toast.POSITION.BOTTOM_RIGHT,
};

export const showSuccessNotification = (message) => {
    toast.success(message, toastOptions);
};

export const showErrorNotification = (message) => {
    toast.error(message, toastOptions);
};

export const showInfoNotification = (message) => {
    toast.info(message, toastOptions);
};

export const showWarningNotification = (message) => {
    toast.warning(message, toastOptions);
};

export const showConfirmationModal = (onSubmit, lang, title, description) => {
    return confirmAlert({
        title: title,
        message: description,
        customUI: ({ title, message, onClose }) => (
            <ConfirmModal
                title={title}
                message={message}
                onSubmit={onSubmit}
                onClose={onClose}
                lang={lang}
            />
        ),
        overlayClassName: 'cms-confirmation-overlay',
    });
};

export const showCancellationModal = (onSubmit, lang, title) => {
    return confirmAlert({
        title: title,
        customUI: ({ title, onClose }) => (
            <CancellationModal
                title={title}
                onSubmit={onSubmit}
                onClose={onClose}
                lang={lang}
            />
        ),
        overlayClassName: 'cms-confirmation-overlay',
    });
};
