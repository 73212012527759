import { routes } from '../../../helpers/request';

export const companyStrings = {
    listTitleText: {
        tr: 'Şirketler',
        en: 'Companies',
    },
    newButtonText: {
        tr: 'Yeni şirket',
        en: 'New company',
    },
    createFormTitleText: {
        tr: 'Yeni şirket',
        en: 'New company',
    },
    usersHeaderText: {
        tr: 'Kullanıcılar',
        en: 'Users',
    },
    newUserButtonText: {
        tr: 'Yeni kullanıcı',
        en: 'New user',
    },
    activationMailSent: {
        tr: 'Hesap aktivasyon maili gönderildi.',
        en: 'Account activation mail has been sent.',
    },
    invalidShiftHours: {
        tr: 'Geçersiz mesai saatleri.',
        en: 'Invalid shift hours.',
    },
    generalHeaderText: {
        tr: 'Genel',
        en: 'General',
    },
    shiftHoursHeaderText: {
        tr: 'Mesai saatleri',
        en: 'Shift hours',
    },
    socialMediaHeaderText: {
        tr: 'Medya',
        en: 'Media',
    },
    adminSettingsHeaderText: {
        tr: 'Admin ayarları',
        en: 'Admin settings',
    },
    locationHeaderText: {
        tr: 'Konum',
        en: 'Location',
    },
    goBackText: {
        tr: 'Geri git',
        en: 'Go back',
    },
    failedToOpenPortal: {
        tr: 'Portal açılamadı (Kayıtlı kullanıcı yok)',
        en: 'Failed to open portal (No registered users)',
    },
};

export const companyFields = {
    name: {
        name: 'name',
        label: {
            tr: 'İsim',
            en: 'Name',
        },
        placeholder: {
            tr: 'Şirket ismi',
            en: 'Company name',
        },
        showInList: true,
        required: true,
    },
    companyTypeId: {
        name: 'companyTypeId',
        label: {
            tr: 'İşletme tipi',
            en: 'Company type',
        },
        placeholder: {
            tr: 'İşletme tipi ismi',
            en: 'Company type name',
        },
        showInList: true,
        required: true,
    },
    countryId: {
        name: 'countryId',
        label: {
            tr: 'Ülke',
            en: 'Country',
        },
        placeholder: {
            tr: 'Ülke ismi',
            en: 'Country name',
        },
        showInList: true,
        required: true,
    },
    stateId: {
        name: 'stateId',
        label: {
            tr: 'İl',
            en: 'State',
        },
        placeholder: {
            tr: 'İl ismi',
            en: 'State name',
        },
        showInList: true,
        required: true,
    },
    cityId: {
        name: 'cityId',
        label: {
            tr: 'İlçe',
            en: 'City',
        },
        placeholder: {
            tr: 'İlçe ismi',
            en: 'City name',
        },
        showInList: true,
        required: true,
    },
    address: {
        name: 'address',
        label: {
            tr: 'Açık adres',
            en: 'Full address',
        },
        placeholder: {
            tr: 'Detaylı açık adres',
            en: 'Full address with detail',
        },
        showInList: false,
    },
    description: {
        name: 'description',
        label: {
            tr: 'Şirket açıklaması',
            en: 'Company description',
        },
        placeholder: {
            tr: 'Şirket açıklaması (hizmetler, geçmiş, vs.)',
            en: 'Company description (services, history, etc.)',
        },
        showInList: false,
    },
    googleMapsUrl: {
        name: 'googleMapsUrl',
        label: {
            tr: 'Google maps url ',
            en: 'Google maps url',
        },
        placeholder: {
            tr: 'Google maps url (Paylaş url)',
            en: 'Google maps url (Share url)',
        },
        showInList: false,
    },
    instagramUsername: {
        name: 'instagramUsername',
        label: {
            tr: 'Instagram kullanıcı adı',
            en: 'Instragram username',
        },
        placeholder: {
            tr: 'İşletmenin instagram kullanıcı adı',
            en: 'Instragram username of the company',
        },
    },
    lang: {
        name: 'lang',
        label: {
            tr: 'İşletme dili',
            en: 'Company language',
        },
        placeholder: {
            tr: 'İşletme dili',
            en: 'Company language',
        },
        showInList: false,
        required: true,
    },
    timezone: {
        name: 'timezone',
        label: {
            tr: 'İşletme zaman dilimi',
            en: 'Company time zone',
        },
        placeholder: {
            tr: 'İşletme zaman dilimi',
            en: 'Company time zone',
        },
        required: true,
    },
    credit: {
        name: 'credit',
        label: {
            tr: 'Jeton',
            en: 'Token',
        },
        showInList: true,
    },
    logoUrl: {
        name: 'logoUrl',
        label: {
            tr: 'Logo',
            en: 'Logo',
        },
        showInList: false,
        required: true,
    },
    inactive: {
        name: 'inactive',
        label: {
            tr: 'İnaktif',
            en: 'Inactive',
        },
        showInList: true,
        listText: {
            tr: 'Durum',
            en: 'Status',
        },
    },
    discountPercentage: {
        name: 'discountPercentage',
        label: {
            tr: 'İndirim oranı',
            en: 'Discount percentage',
        },
        placeholder: {
            tr: 'İndirim oranı',
            en: 'Discount percentage',
        },
    },
    mondayShiftStartTime: {
        name: 'mondayShiftStartTime',
        label: {
            tr: 'Pazartesi mesai başlangıcı',
            en: 'Monday shift start time',
        },
        showInList: false,
    },
    mondayShiftEndTime: {
        name: 'mondayShiftEndTime',
        label: {
            tr: 'Pazartesi mesai bitişi',
            en: 'Monday shift end time',
        },
        showInList: false,
    },
    tuesdayShiftStartTime: {
        name: 'tuesdayShiftStartTime',
        label: {
            tr: 'Salı mesai başlangıcı',
            en: 'Tuesday shift start time',
        },
        showInList: false,
    },
    tuesdayShiftEndTime: {
        name: 'tuesdayShiftEndTime',
        label: {
            tr: 'Salı mesai bitişi',
            en: 'Tuesday shift end time',
        },
        showInList: false,
    },
    wednesdayShiftStartTime: {
        name: 'wednesdayShiftStartTime',
        label: {
            tr: 'Çarsamba mesai başlangıcı',
            en: 'Wednesday shift start time',
        },
        showInList: false,
    },
    wednesdayShiftEndTime: {
        name: 'wednesdayShiftEndTime',
        label: {
            tr: 'Çarsamba mesai bitişi',
            en: 'Wednesday shift end time',
        },
        showInList: false,
    },
    thursdayShiftStartTime: {
        name: 'thursdayShiftStartTime',
        label: {
            tr: 'Perşembe mesai başlangıcı',
            en: 'Thursday shift start time',
        },
        showInList: false,
    },
    thursdayShiftEndTime: {
        name: 'thursdayShiftEndTime',
        label: {
            tr: 'Perşembe mesai bitişi',
            en: 'Thursday shift end time',
        },
        showInList: false,
    },
    fridayShiftStartTime: {
        name: 'fridayShiftStartTime',
        label: {
            tr: 'Cuma mesai başlangıcı',
            en: 'Friday shift start time',
        },
        showInList: false,
    },
    fridayShiftEndTime: {
        name: 'fridayShiftEndTime',
        label: {
            tr: 'Cuma mesai bitişi',
            en: 'Friday shift end time',
        },
        showInList: false,
    },
    saturdayShiftStartTime: {
        name: 'saturdayShiftStartTime',
        label: {
            tr: 'Cumartesi mesai başlangıcı',
            en: 'Saturday shift start time',
        },
        showInList: false,
    },
    saturdayShiftEndTime: {
        name: 'saturdayShiftEndTime',
        label: {
            tr: 'Cumartesi mesai bitişi',
            en: 'Saturday shift end time',
        },
        showInList: false,
    },
    sundayShiftStartTime: {
        name: 'sundayShiftStartTime',
        label: {
            tr: 'Pazar mesai başlangıcı',
            en: 'Sunday shift start time',
        },
        showInList: false,
    },
    sundayShiftEndTime: {
        name: 'sundayShiftEndTime',
        label: {
            tr: 'Pazar mesai bitişi',
            en: 'Sunday shift end time',
        },
        showInList: false,
    },
};

export const userFields = {
    fullName: {
        name: 'fullName',
        label: {
            tr: 'Ad soyad',
            en: 'Full name',
        },
        placeholder: {
            tr: 'Kullanıcı ad soyadı',
            en: 'User full name',
        },
        showInList: true,
    },
    email: {
        name: 'email',
        label: {
            tr: 'E-posta adresi',
            en: 'E-mail address',
        },
        placeholder: {
            tr: 'Kullanıcı e-posta adresi',
            en: 'User e-mail address',
        },
        showInList: true,
    },
    status: {
        name: 'status',
        label: {
            tr: 'Durum',
            en: 'Status',
        },
        showInList: true,
    },
};

export const companyListFilters = {
    tr: [
        {
            field: {
                name: 'inactive',
                label: 'Durum',
                placeholder: 'Durum',
            },
            options: [
                {
                    label: 'Aktif',
                    value: false,
                },
                {
                    label: 'İnaktif',
                    value: true,
                },
            ],
        },
    ],
    en: [
        {
            field: {
                name: 'inactive',
                label: 'Status',
                placeholder: 'Status',
            },
            options: [
                {
                    label: 'Active',
                    value: false,
                },
                {
                    label: 'Inactive',
                    value: true,
                },
            ],
        },
    ],
};

export const discountPercentageOptions = [
    {
        label: '0%',
        value: 0,
    },
    {
        label: '10%',
        value: 10,
    },
    {
        label: '20%',
        value: 20,
    },
    {
        label: '30%',
        value: 30,
    },
    {
        label: '40%',
        value: 40,
    },
    {
        label: '50%',
        value: 50,
    },
    {
        label: '60%',
        value: 60,
    },
    {
        label: '70%',
        value: 70,
    },
    {
        label: '80%',
        value: 80,
    },
    {
        label: '90%',
        value: 90,
    },
    {
        label: '100%',
        value: 100,
    },
];
