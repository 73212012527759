export const MARGIN_PER_DEPTH = 16;

export const sidebarStrings ={ 
    logoutText: {
        tr: 'Çıkış yap',
        en: 'Log-out'
    },
    switchToAdmin: {
        tr: 'Admin görünümüne geç',
        en: 'Switch to admin view'
    },
    switchToUser: {
        tr: 'Kullanıcı görünümüne geç',
        en: 'Switch to user view'
    },
    userInfo: {
        tr: 'Kullanıcı: <fullName>',
        en: 'User: <fullName>'
    },
    creditInfo: {
        tr: 'Bakiye: <credit> jeton',
        en: 'Balance: <credit> tokens'
    },
    affiliateTitleText: {
        tr: 'Satış ortaklığı programı',
        en: 'Affiliate program'
    },
    exitPortal: {
        tr: 'Portaldan çık',
        en: 'Exit portal'
    }
}

export const images = {
    brandPlaceholder: '/assets/img/200x100.png'
}