export const resolveParams = (params = {}, lang = 'en') => {
    params.lang = lang;

    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((k) => {
        if (Array.isArray(params[k]))
            params[k].forEach((p) => searchParams.append(k, p));
        else searchParams.append(k, params[k]);
    });

    return `?${searchParams.toString()}`;
};
