import { languages } from '../../constants/languages';
import { paymentStatusStrings } from '../../constants/paymentStatuses';
import { getDurationOptions } from '../option';
import { routes } from '../request';
import { englishSort, turkishSort } from '../string';

export const getCompanyTypeListMapping = ({ value, lists, lang }) => {
    const list = lists[routes.companyType];

    const record = (list || []).find((item) => item._id === value);

    return record ? record[lang] : '';
};

export const getCountryListMapping = ({ value, lists }) => {
    const list = lists[routes.country];

    const record = (list || []).find((item) => item._id === value);

    return record ? record.name : '';
};

export const getStateListMapping = ({ value, lists }) => {
    const list = lists[routes.state];

    const record = (list || []).find((item) => item._id === value);

    return record ? record.name : '';
};

export const getCityListMapping = ({ value, lists }) => {
    const list = lists[routes.city];

    const record = (list || []).find((item) => item._id === value);

    return record ? record.name : '';
};

export const getDurationListMapping = ({ value, lang }) => {
    const options = getDurationOptions(lang);

    for (let i = 0; i < options.length; i++) {
        const option = options[i];

        if (option.value === value) return option.label;
    }

    return value;
};

export const getInactiveListMapping = ({ value, lang }) => {
    if (lang === 'tr') return !value ? 'Aktif' : 'İnaktif';
    else return !value ? 'Active' : 'Inactive';
};

export const getPriceListMapping = ({ value, currency, entity }) => {
    if (!value) return '-';
    else if (!currency) return value;
    return `${value} ${entity?.currency || currency}`;
};

export const getUserStatusMapping = ({ entity, lang }) => {
    if (lang === 'tr')
        return entity.isAdmin
            ? 'Aktif (Admin)'
            : !entity.activatedAt
            ? 'Aktivasyon e-postası gönderildi'
            : 'Aktif';
    else if (lang === 'en')
        return entity.isAdmin
            ? 'Active (Admin)'
            : !entity.activatedAt
            ? 'Activation e-mail has been sent'
            : 'Active';
};

export const getDatetimeListMapping = ({ value, lang }) => {
    let locale = null;

    if (lang === 'tr') locale = 'tr-TR';
    else if (lang === 'en') locale = 'en-EN';

    return new Date(value).toLocaleString(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
};

export const getDateListMapping = ({ value, lang }) => {
    let locale = null;

    if (lang === 'tr') locale = 'tr-TR';
    else if (lang === 'en') locale = 'en-EN';

    return new Date(value).toLocaleString(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

export const getTimeListMapping = ({ value, lang }) => {
    let locale = null;

    if (lang === 'tr') locale = 'tr-TR';
    else if (lang === 'en') locale = 'en-EN';

    return new Date(value).toLocaleString(locale, {
        hour: '2-digit',
        minute: '2-digit',
    });
};

export const getNameListMapping = ({ value }) => {
    return value?.name || value?.fullName;
};

export const getPercentageListMapping = ({ value }) => {
    return `${value}%`;
};

export const getPaymentStatusListMapping = ({ value, lang }) => {
    if (Object.keys(paymentStatusStrings).indexOf(value) === -1) return '';

    return paymentStatusStrings[value][lang];
};

export const getPaymentMethodMapping = ({ value, lang }) => {
    const map = {
        tr: {
            gift: 'Hoşgeldin hediyesi',
            transfer: 'Havale',
            creditCard: 'Kredi kartı',
        },
        en: {
            gift: 'Welcome gift',
            transfer: 'Transfer',
            creditCard: 'Credit card',
        },
    };

    return map[lang][value];
};

export const getTokenAmountListMapping = ({ value, lang }) => {
    const map = {
        tr: `${value} jeton`,
        en: `${value} token`,
    };

    return map[lang];
};

export const localSort = (list, lang, reverse = false) => {
    if (!list) return [];

    let sortType;

    if (lang === languages.tr) sortType = turkishSort;
    else if (lang === languages.en) sortType = englishSort;

    list = list.sort(sortType);

    if (reverse) list = list.reverse();

    return list;
};
