export const modalConstants = {
    delete: {
        tr: 'Sil',
        en: 'Delete',
    },
    update: {
        tr: 'Güncelle',
        en: 'Update',
    },
    yes: {
        tr: 'Evet',
        en: 'Yes',
    },
    no: {
        tr: 'Hayır',
        en: 'No',
    },
    save: {
        tr: 'Kaydet',
        en: 'Save',
    },
    cancel: {
        tr: 'İptal',
        en: 'Cancel',
    },
    name: {
        tr: 'İsim',
        en: 'Name',
    },
    note: {
        tr: 'Not',
        en: 'Note',
    },
    hasBeenSavedSuccessfully: {
        tr: ' başarıyla kaydedildi.',
        en: ' has been saved successfully.',
    },
    reservationCancelledSuccessfully: {
        tr: 'Randevu başarıyla iptal edildi.',
        en: 'Reservation cancelled successfully.',
    },
    viewReservationHeaderText: {
        tr: 'Randevu detayları',
        en: 'Reservation details',
    },
    userModalHeaderText: {
        tr: 'Yeni kullanıcı oluştur',
        en: 'Create new user',
    },
    userModalFullNameLabel: {
        tr: 'Ad soyad',
        en: 'Full name',
    },
    userModalFullNamePlaceholder: {
        tr: 'Kullanıcı ad soyadı',
        en: 'User full name',
    },
    userModalEmailLabel: {
        tr: 'E-posta',
        en: 'E-mail',
    },
    userModalEmailPlaceholder: {
        tr: 'Kullanıcı e-posta adresi',
        en: 'User email address',
    },
    cancelReservation: {
        tr: 'İptal et',
        en: 'Cancel',
    },
    cancelConfirmationTitle: {
        tr: 'Randevuyu iptal etmek istediğinizden emin misiniz?',
        en: 'Are you sure you want to cancel the reservation?',
    },
    startDate: {
        tr: 'Başlangıç tarihi',
        en: 'Start date',
    },
    endDate: {
        tr: 'Bitiş tarihi',
        en: 'End date',
    },
    reservationType: {
        tr: 'Randevu tipi',
        en: 'Reservation type',
    },
    reservationStaff: {
        tr: 'Randevu personeli',
        en: 'Reservation staff',
    },
    duration: {
        tr: 'Süre',
        en: 'Duration',
    },
    confirmSuccessText: {
        tr: 'Randevu onaylandı.',
        en: 'Confirmed reservation.',
    },
    cancelSuccessText: {
        tr: 'Randevu reddedildi.',
        en: 'Rejected reservation.',
    },
    confirm: {
        tr: 'Onayla',
        en: 'Confirm',
    },
    customerName: {
        tr: 'Müşteri ismi',
        en: 'Customer name',
    },
    customerPhone: {
        tr: 'Müşteri telefonu',
        en: 'Customer phone',
    },
    price: {
        tr: 'Fiyat',
        en: 'Price',
    },
    goBack: {
        tr: 'Geri git',
        en: 'Go back',
    },
    uploadImage: {
        tr: 'Resim yükle',
        en: 'Upload image',
    },
    payTransfer: {
        tr: 'Havale',
        en: 'Transfer',
    },
    payCreditCard: {
        tr: 'Kredi kartı ',
        en: 'Credit card',
    },
    depositCheckoutDetails: {
        tr: [
            '• Kredi kartı ile ödeme seçtiğiniz takdirde ödeme gerçekleştikten sonra jetonlarınız aktif hale gelir',
            "• Havale ile ödemeyi seçtiğiniz takdirde oluşan ÖDEME NUMARASI'NI para aktarımı esnasında açıklama kısmına girmeniz gereklidir ve ZORUNLUDUR. Girilmediği takdirde ödeme kabul edilmez.",
            '• Aşağıdaki tuşlardan hangi ödeme yöntemi ile ödeme yapmak istediğinizi seçebilirsiniz',
        ],
        en: [
            '• If you choose to pay by credit card, your tokens will be activated after the payment is made.',
            '• If you choose to pay with transfer, you must and MUST enter the PAYMENT NUMBER in the description section during the money transfer. If it is not entered, the payment will not be accepted.',
            '• You can choose which payment method you want to pay with from the buttons below',
        ],
    },
    depositPrice: {
        tr: 'Fiyat: ',
        en: 'Price: ',
    },
    depositTokenAmount: {
        tr: 'Jeton miktarı: ',
        en: 'Token amount: ',
    },
    depositDiscountPercentage: {
        tr: 'İndirim yüzdesi: ',
        en: 'Discount percentage: ',
    },
    tokenText: {
        tr: 'Jeton',
        en: 'Token'
    },
};
