import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop';
import { modalConstants } from './constants';
import Button from '../components/Button';
import UserContext from '../contexts/UserContext';
import { fileToBase64, getCroppedImageToBlob } from '../helpers/file';

const CONTAINER_HEIGHT = 300;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 500,
        zIndex: 1000,
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        overflowY: 'auto',
    },
};

Modal.setAppElement('#root');

const ImageResizeModal = ({
    closeModal,
    file,
    setFile,
    aspect = 1,
    uploadMedia,
}) => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const [crop, setCrop] = useState({ x: 0, y: 0 });

    const [zoom, setZoom] = useState(1);

    const [image, setImage] = useState(null);

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    useEffect(() => {
        const dispatch = async () => {
            try {
                setImage(await fileToBase64(file));
            } catch (error) {
                setImage(null);
            }
        };
        if (file) dispatch();
    }, [file]);

    return (
        <>
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                style={customStyles}
            >
            <h4>{modalConstants.uploadImage[lang]}</h4>
                <div
                    className="d-flex align-items-center justify-content-center my-4"
                    style={{
                        height: CONTAINER_HEIGHT,
                        position: 'relative',
                    }}
                >
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        showGrid={true}
                    />
                </div>
                <div className="d-flex align-items-center justify-content-end">
                    <Button
                        className="danger"
                        onClick={() => {
                            setFile(null);

                            setImage(null);

                            closeModal();
                        }}
                    >
                        {modalConstants.goBack[lang]}
                    </Button>
                    <Button
                        onClick={async () => {
                            const file = await getCroppedImageToBlob(
                                image,
                                croppedAreaPixels
                            );

                            setFile(file);

                            if (uploadMedia) uploadMedia(file);

                            closeModal();
                        }}
                        style={{ width: 90, marginLeft: 8 }}
                        className="success"
                    >
                        {modalConstants.confirm[lang]}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ImageResizeModal;
