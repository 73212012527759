import { useContext, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import pages from './constants/pages';

import UserContext from './contexts/UserContext';

import LoadingOverlay from './components/LoadingOverlay';
import Sidebar from './components/Sidebar';
import { getPage, getParam } from './helpers/url';
import { getCookie, setCookie } from './helpers/cookie';
import cookieNames from './constants/cookieNames';

const App = () => {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);

    const {
        data: userData,
        isLoading: isUserDataLoading,
        isAffiliate,
    } = userContext;

    const page = getPage(userData?.isAdmin, isAffiliate);

    const askNotificationPermission = () => {
        try {
            Notification.requestPermission().then(function (permission) {
                if (permission === 'granted') {
                }
            });
        } catch (error) {}
    };

    useEffect(() => {
        if (getParam('token')) setCookie(cookieNames.jwt, getParam('token'));
    }, []);

    useEffect(() => {
        setIsLoading(true);

        if (!isUserDataLoading) {
            if (
                page.anonymous === undefined &&
                !page.iframe &&
                userData === null
            ) {
                navigate(pages.login.path);
            } else if (
                page.guestPage === true &&
                !page.iframe &&
                userData !== null
            ) {
                navigate(
                    isAffiliate
                        ? pages.affiliateDashboard.path
                        : userData?.isAdmin
                        ? pages.adminDashboard.path
                        : pages.managerDashboard.path
                );
            }
        }

        askNotificationPermission();

        setIsLoading(false);
    }, [isUserDataLoading, userData]);

    if (isLoading) return <LoadingOverlay />;

    return (
        <div id="app">
            <div className="d-flex">
                {userData?._id && !page.iframe && <Sidebar />}
                <div className="flex-grow-1 d-flex flex-column position-relative">
                    <Routes>
                        {Object.keys(pages).map((item) => (
                            <Route
                                key={pages[item].path}
                                path={pages[item].path}
                                element={pages[item].element}
                            />
                        ))}
                    </Routes>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default App;
