import { useContext, useEffect, useState } from 'react';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import FormContext from '../../../contexts/FormContext';
import UserContext from '../../../contexts/UserContext';
import {
    getFieldMapping,
    getLangOptions,
    getNameOptions,
} from '../../../helpers/mapping';
import { routes } from '../../../helpers/request';
import FormContainer from '../../../reusables/FormContainer';
import {
    companyFields,
    companyStrings,
    discountPercentageOptions,
    userFields,
} from './constants';
import TimePicker from '../../../components/TimePicker';
import { getLanguageOptions } from '../../../helpers/option';
import Toggle from '../../../components/Toggle';
import Table from '../../../components/Table';
import { callRoute } from '../../../helpers/request';
import Button from '../../../components/Button';
import iconClasses from '../../../constants/iconClasses';
import UserModal from '../../../modals/UserModal';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../../helpers/popup';
import { getUserStatusMapping, localSort } from '../../../helpers/list';
import Textarea from '../../../components/Textarea';
import { timeZoneOptions } from '../../../constants/timezone';
import { getTimeZone, timeToLocale, timeToUtc } from '../../../helpers/date';
import ImageUpload from '../../../components/ImageUpload';

const CompanyForm = () => {
    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const [companyUsers, setCompanyUsers] = useState([]);

    const [isUserModalOpen, setIsUserModalOpen] = useState(false);

    const [companyUsersLoading, setCompanyUsersLoading] = useState(false);

    const { lists, data } = formContext;

    const { lang, company, load, isAdmin } = userContext;

    const {
        name,
        companyTypeId,
        countryId,
        stateId,
        cityId,
        address,
        googleMapsUrl,
        mondayShiftStartTime,
        mondayShiftEndTime,
        tuesdayShiftStartTime,
        tuesdayShiftEndTime,
        wednesdayShiftStartTime,
        wednesdayShiftEndTime,
        thursdayShiftStartTime,
        thursdayShiftEndTime,
        fridayShiftStartTime,
        fridayShiftEndTime,
        saturdayShiftStartTime,
        saturdayShiftEndTime,
        sundayShiftStartTime,
        sundayShiftEndTime,
        inactive,
        instagramUsername,
        description,
        timezone,
        logoUrl,
        discountPercentage,
    } = companyFields;

    const getCountry = () => {
        const countryId = formContext.get('countryId');

        if (!countryId || !lists[routes.country]) return {};

        return lists[routes.country].find(
            (item) => item._id === formContext.get('countryId')
        );
    };

    const generateCustomValidations = () => {
        const validations = [];

        for (let i = 0; i < 7; i++) {
            const shiftStartTime = getShiftTime(i, 0);

            const shiftEndTime = getShiftTime(i, 1);

            validations.push({
                case:
                    (shiftStartTime === null && shiftEndTime === null) ||
                    shiftEndTime > shiftStartTime,
                message: companyStrings.invalidShiftHours[lang],
            });
        }

        return validations;
    };

    const loadCompanyUsers = async () => {
        if (!formContext.get('_id')) return;

        setCompanyUsersLoading(true);

        try {
            const result = await callRoute({
                route: routes.user,
                method: 'GET',
                params: {
                    companyId: formContext.get('_id'),
                },
                lang,
            });

            setCompanyUsers(result.data);
        } catch (error) {
            showErrorNotification(error);
        }

        setCompanyUsersLoading(false);
    };

    const resendActivationMail = async (item) => {
        if (!item.email) return;

        try {
            await callRoute({
                route: routes.resendActivation,
                method: 'POST',
                body: {
                    email: item.email,
                },
                lang,
            });

            showSuccessNotification(companyStrings.activationMailSent[lang]);
        } catch (error) {
            showErrorNotification(error);
        }
    };

    const getShiftTime = (dayIndex, timeIndex) => {
        const shiftTimes = formContext.get('shiftTimes') || [
            [null, null],
            [null, null],
            [null, null],
            [null, null],
            [null, null],
            [null, null],
            [null, null],
        ];

        const timezone = formContext.get('timezone') || getTimeZone();

        const time = shiftTimes[dayIndex][timeIndex];

        return timeToLocale(time, timezone);

        // return timeToLocale(time, timezone)
    };

    const setShiftTime = (time, dayIndex, timeIndex) => {
        const shiftTimes = formContext.get('shiftTimes') || [
            [null, null],
            [null, null],
            [null, null],
            [null, null],
            [null, null],
            [null, null],
            [null, null],
        ];

        const timezone = formContext.get('timezone') || getTimeZone();

        shiftTimes[dayIndex][timeIndex] = timeToUtc(time, timezone);

        formContext.update(shiftTimes);
    };

    const getCountryOptions = () =>
        getNameOptions(localSort(lists[routes.country], lang));

    const getStateOptions = () =>
        getNameOptions(
            localSort(
                (lists[routes.state] || []).filter(
                    (item) => item.countryId === data[countryId.name]
                ),
                lang
            )
        );

    const getCityOptions = () =>
        getNameOptions(
            localSort(
                (lists[routes.city] || []).filter(
                    (item) => item.stateId === data[stateId.name]
                ),
                lang
            )
        );

    const filterTimeZones = (options) => {
        return options.filter(
            (item) => (getCountry().timezones || []).indexOf(item.value) > -1
        );
    };

    const uploadMedia = async (file) => {
        try {
            if (typeof file !== 'object') return;

            const formData = new FormData();

            formData.append('file', file);

            if (formContext.get('_id'))
                formData.append('companyId', formContext.get('_id'));

            const { data } = await callRoute({
                route: routes.companyLogo,
                method: 'POST',
                body: formData,
            });

            formContext.update({
                logoUrl: data,
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadCompanyUsers();
    }, [formContext.get('_id')]);

    const existingData = isAdmin ? null : company;

    const isLoading = isAdmin ? false : !company;

    const afterSubmit = isAdmin ? () => {} : load;

    return (
        <>
            <FormContainer
                route={routes.company}
                lists={[
                    routes.companyType,
                    routes.country,
                    routes.state,
                    routes.city,
                ]}
                fields={companyFields}
                existingData={existingData}
                isLoading={isLoading}
                afterSubmit={afterSubmit}
                createTitleText={companyStrings.createFormTitleText[lang]}
                customValidations={generateCustomValidations()}
            >
                {isAdmin && (
                    <div className="col-12 row">
                        <h4 className="mt-3">
                            {companyStrings.adminSettingsHeaderText[lang]}
                        </h4>
                        <div className="col-lg-3">
                            <Toggle field={inactive} />
                        </div>
                        <div className="col-lg-3">
                            <Select
                                field={companyFields.discountPercentage}
                                options={discountPercentageOptions}
                            />
                        </div>
                    </div>
                )}
                <h4 className="mt-3">
                    {companyStrings.generalHeaderText[lang]}
                </h4>
                <div className="col-lg-3">
                    <Select
                        disabled={!isAdmin}
                        field={companyTypeId}
                        options={getLangOptions(
                            lists[routes.companyType],
                            lang
                        )}
                    />
                </div>
                <div className="col-lg-3">
                    <Select
                        field={companyFields.lang}
                        options={getLanguageOptions(lang)}
                    />
                </div>
                <div className="col-lg-6">
                    <Input field={name} maxLength={64} />
                </div>
                <div className="col-12">
                    <Textarea field={description} maxLength={600} />
                </div>
                <h4 className="mt-3">
                    {companyStrings.locationHeaderText[lang]}
                </h4>
                <div className="col-lg-3">
                    <Select
                        disabled={!isAdmin}
                        field={countryId}
                        options={getCountryOptions()}
                    />
                </div>
                <div className="col-lg-3">
                    <Select field={stateId} options={getStateOptions()} />
                </div>
                <div className="col-lg-3">
                    <Select field={cityId} options={getCityOptions()} />
                </div>
                <div className="col-lg-3">
                    <Select
                        field={timezone}
                        options={filterTimeZones(timeZoneOptions)}
                    />
                </div>
                <div className="col-12">
                    <Input field={address} maxLength={200} />
                </div>
                <div className="col-lg-6">
                    <h4 className="mt-3">
                        {companyStrings.shiftHoursHeaderText[lang]}
                    </h4>
                    <div className="row">
                        <div className="col-6">
                            <TimePicker
                                field={mondayShiftStartTime}
                                value={getShiftTime(0, 0)}
                                onChange={(time) => setShiftTime(time, 0, 0)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={mondayShiftEndTime}
                                value={getShiftTime(0, 1)}
                                onChange={(time) => setShiftTime(time, 0, 1)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={tuesdayShiftStartTime}
                                value={getShiftTime(1, 0)}
                                onChange={(time) => setShiftTime(time, 1, 0)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={tuesdayShiftEndTime}
                                value={getShiftTime(1, 1)}
                                onChange={(time) => setShiftTime(time, 1, 1)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={wednesdayShiftStartTime}
                                value={getShiftTime(2, 0)}
                                onChange={(time) => setShiftTime(time, 2, 0)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={wednesdayShiftEndTime}
                                value={getShiftTime(2, 1)}
                                onChange={(time) => setShiftTime(time, 2, 1)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={thursdayShiftStartTime}
                                value={getShiftTime(3, 0)}
                                onChange={(time) => setShiftTime(time, 3, 0)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={thursdayShiftEndTime}
                                value={getShiftTime(3, 1)}
                                onChange={(time) => setShiftTime(time, 3, 1)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={fridayShiftStartTime}
                                value={getShiftTime(4, 0)}
                                onChange={(time) => setShiftTime(time, 4, 0)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={fridayShiftEndTime}
                                value={getShiftTime(4, 1)}
                                onChange={(time) => setShiftTime(time, 4, 1)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={saturdayShiftStartTime}
                                value={getShiftTime(5, 0)}
                                onChange={(time) => setShiftTime(time, 5, 0)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={saturdayShiftEndTime}
                                value={getShiftTime(5, 1)}
                                onChange={(time) => setShiftTime(time, 5, 1)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={sundayShiftStartTime}
                                value={getShiftTime(6, 0)}
                                onChange={(time) => setShiftTime(time, 6, 0)}
                            />
                        </div>
                        <div className="col-6">
                            <TimePicker
                                field={sundayShiftEndTime}
                                value={getShiftTime(6, 1)}
                                onChange={(time) => setShiftTime(time, 6, 1)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <h4 className="mt-3">
                        {companyStrings.socialMediaHeaderText[lang]}
                    </h4>
                    <div className="row">
                        <div className="col-12">
                            <ImageUpload
                                field={logoUrl}
                                uploadMedia={uploadMedia}
                            />
                        </div>
                        <div className="col-12">
                            <Input field={instagramUsername} maxLength={48} />
                        </div>
                        <div className="col-12">
                            <Input field={googleMapsUrl} maxLength={600} />
                        </div>
                        {/* <div className="col-12">
                            {data['googleMapsUrl'] && (
                                <iframe
                                    className="mt-4"
                                    title="company-location"
                                    src={extractIframeUrlSrc(
                                        data['googleMapsUrl']
                                    )}
                                    style={{
                                        width: '100%',
                                        height: 450,
                                        border: 0,
                                    }}
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                />
                            )}
                        </div> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="d-flex mt-3 mb-3">
                        <h4>{companyStrings.usersHeaderText[lang]}</h4>
                        <div className="flex-grow-1" />
                        <Button
                            onClick={() => {
                                setIsUserModalOpen(true);
                            }}
                            icon={iconClasses.userPlus}
                        >
                            {companyStrings.newUserButtonText[lang]}
                        </Button>
                    </div>
                    <Table
                        route={routes.user}
                        data={companyUsers}
                        headerMapping={getFieldMapping(userFields, 'label')}
                        valueMapping={{
                            status: getUserStatusMapping,
                        }}
                        load={loadCompanyUsers}
                        customActions={[
                            {
                                icon: iconClasses.envelope,
                                action: resendActivationMail,
                                disabled: (item) => !item.activatedAt,
                            },
                        ]}
                        hideActions={(item) => item.isAdmin}
                        deleteActionAllowed={true}
                        isLoading={companyUsersLoading}
                    />
                </div>
            </FormContainer>
            {isUserModalOpen && (
                <UserModal
                    closeModal={() => {
                        setIsUserModalOpen(false);
                    }}
                    reload={loadCompanyUsers}
                    companyId={formContext.get('_id')}
                />
            )}
        </>
    );
};

export default CompanyForm;
