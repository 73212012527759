import { resolveUrl } from './url';
import { resolveParams } from './param';
import { resolveOptions } from './options';
import { requestStrings, routeUrls } from './constants';
import { deleteCookie } from '../cookie';
import cookieNames from '../../constants/cookieNames';

export const callRoute = async ({
    route,
    method,
    body,
    headers,
    params,
    rewriteUrl,
    id,
    signal,
    lang,
}) => {
    if (!route) {
        throw requestStrings.undefinedEndpoint;
    }

    let url = resolveUrl(route, rewriteUrl, id);

    url += resolveParams(params, lang);

    const options = resolveOptions(method, body, headers, signal);

    const response = await fetch(url, options);

    const result = await response.json();

    if (response.ok && result.statusCode < 400) return result;
    // else if (result.statusCode === 440) deleteCookie(cookieNames.jwt);

    throw result.message || requestStrings.requestFailedWithNoMessage;
};

export const routes = routeUrls;
