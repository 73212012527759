import { useContext, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import UserContext from '../../contexts/UserContext';
import { localeMap } from './constants';
import './styles.scss';
import ReservationViewModal from '../../modals/ReservationViewModal';
import LoadingFixedText from '../LoadingFixedText';
import { reservationStatuses } from '../../constants/reservationStatuses';
import { dateToUtc } from '../../helpers/date';
import Icon from '../Icon';
import iconClasses from '../../constants/iconClasses';
import { truncateText } from '../../helpers/string';

const eventContent = (eventInfo) => {
    const {
        reservationType,
        reservationStaff,
        transactionPrice,
        transactionCurrency,
        note,
    } = eventInfo.event.extendedProps.item;

    return (
        <div className="event-container">
            <div className="event-data">
                <div class="fc-daygrid-event-dot"></div>
                <div className="event-data-time">{eventInfo.timeText}</div>
                <div className="event-data-name">{eventInfo.event.title}</div>
            </div>
            <div className="event-tags">
                <div className="event-tag">
                    <Icon className={iconClasses.user} size={10} />
                    <div className="event-tag-data">
                        {reservationStaff?.name}
                    </div>
                </div>
                <div className="event-tag">
                    <Icon className={iconClasses.tag} size={10} />
                    <div className="event-tag-data">{reservationType?.name}</div>
                </div>
                <div className="event-tag">
                    <Icon className={iconClasses.coins} size={10} />
                    <div className="event-tag-data">
                        {`${transactionPrice} ${transactionCurrency || ''}`}
                    </div>
                </div>
                {note && (
                    <div className="event-tag">
                        <Icon className={iconClasses.edit} size={10} />
                        <div className="event-tag-data">
                            {truncateText(note, 32)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const ReservationGrid = ({
    setStartDate = () => {},
    setEndDate = () => {},
    events = [],
    reload = () => {},
    isLoading = false,
    columnAmount = 4,
}) => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const [selectedReservation, setSelectedReservation] = useState(null);

    useEffect(() => {
        reload();
    }, [columnAmount]);

    return (
        <div>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGrid"
                headerToolbar={{
                    left: '',
                    center: 'title',
                    right: 'prev,next',
                }}
                events={events}
                locale={localeMap[lang]}
                datesSet={(dateInfo) => {
                    const { start, end } = dateInfo;

                    setStartDate(start);

                    setEndDate(end);
                }}
                views={{
                    dayGrid: {
                        duration: { days: columnAmount },
                    },
                }}
                eventClick={(arg) => {
                    setSelectedReservation(arg.event.extendedProps.item);
                }}
                eventClassNames={(arg) => {
                    let { status, startDate, endDate } =
                        arg.event.extendedProps.item;

                    const now = new Date();

                    startDate = dateToUtc(new Date(startDate));

                    endDate = dateToUtc(new Date(endDate));

                    if (status === reservationStatuses.cancelled) {
                        return ['fc-daygrid-event-cancelled'];
                    } else if (status === reservationStatuses.pending) {
                        return ['fc-daygrid-event-pending'];
                    } else if (now > startDate && now < endDate) {
                        return ['fc-daygrid-event-in-progress'];
                    } else if (now < startDate) {
                        return ['fc-daygrid-event-upcoming'];
                    } else {
                        return ['fc-daygrid-event-done'];
                    }
                }}
                eventContent={eventContent}
                displayEventEnd={true}
            />
            <ReservationViewModal
                data={selectedReservation}
                closeModal={() => {
                    setSelectedReservation(null);
                }}
                reload={reload}
            />
            <LoadingFixedText isOpen={isLoading} />
        </div>
    );
};

export default ReservationGrid;
