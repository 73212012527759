import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/UserContext';
import { callRoute, routes } from '../../../helpers/request';
import CompanyPortalHeader from './CompanyPortalHeader';
import pages from '../../../constants/pages';
import ClipLoader from 'react-spinners/ClipLoader';
import { getId } from '../../../helpers/url';
import { showErrorNotification } from '../../../helpers/popup';

const CompanyPortal = () => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const [token, setToken] = useState(null);

    const load = async () => {
        try {
            const { data } = await callRoute({
                route: routes.getCompanyToken,
                method: 'GET',
                params: {
                    companyId: getId(),
                },
                lang,
            });

            setToken(data);
        } catch (e) {
            showErrorNotification(e);
        }
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <>
            <CompanyPortalHeader goBackUrl={pages.adminCompanyList.path} />
            {!token ? (
                <div className="d-flex justify-content-center py-4">
                    <ClipLoader color={'#003850'} size={40} />
                </div>
            ) : (
                <iframe
                allow="screen-wake-lock; cross-origin-isolated"
                    
                    src={`${window.location.origin}?token=${token}`}
                    title="Company portal"
                    style={{
                        height: 'calc(100vh - 60px)',
                        border: '5px solid red',
                    }}
                ></iframe>
            )}
        </>
    );
};

export default CompanyPortal;
