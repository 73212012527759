export const affiliateStrings = {
    listTitleText: {
        tr: 'Satış ortakları',
        en: 'Affiliates',
    },
    newButtonText: {
        tr: 'Yeni satış ortağı',
        en: 'New affiliate',
    },
    createFormTitleText: {
        tr: 'Yeni satış ortağı',
        en: 'New affiliate',
    },
    activationMailSent: {
        tr: 'Hesap aktivasyon maili gönderildi.',
        en: 'Account activation mail has been sent.',
    }
};

export const affiliateFields = {
    name: {
        name: 'name',
        label: {
            tr: 'İsim',
            en: 'Name',
        },
        placeholder: {
            tr: 'Satış ortağı ismi',
            en: 'Affiliate name',
        },
        showInList: true,
        required: true,
    },
    email: {
        name: 'email',
        label: {
            tr: 'E-posta',
            en: 'E-mail',
        },
        placeholder: {
            tr: 'Satış ortağı e-posta adresi',
            en: 'Affiliate e-mail address',
        },
        showInList: true,
        required: true,
    },
    comissionRate: {
        name: 'comissionRate',
        label: {
            tr: 'Komisyon oranı',
            en: 'Comission rate',
        },
        placeholder: {
            tr: 'Satış ortağı komisyon oran',
            en: 'Affiliate comission rate',
        },
        showInList: true,
        required: true,
    },
    lang: {
        name: 'lang',
        label: {
            tr: 'Dil',
            en: 'Language',
        },
        placeholder: {
            tr: 'Dil',
            en: 'Language',
        },
        showInList: false,
        required: true
    },
};

export const comissionRateOptions = [
    {
        label: '10%',
        value: 10,
    },
    {
        label: '20%',
        value: 20,
    },
    {
        label: '30%',
        value: 30,
    },
    {
        label: '40%',
        value: 40,
    },
    {
        label: '50%',
        value: 50,
    },
    {
        label: '60%',
        value: 60,
    },
    {
        label: '70%',
        value: 70,
    },
    {
        label: '80%',
        value: 80,
    },
    {
        label: '90%',
        value: 90,
    },
    {
        label: '100%',
        value: 100,
    },
];
