import React from 'react';
import { useContext } from 'react';
import UserContext from '../../contexts/UserContext';
import { statusBadgeStrings } from './constants';
import './styles.scss';

const StatusBadge = ({ status }) => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    if (!status) return null;

    const classMap = {
        pending: 'cms-status-badge-pending',
        confirmed: 'cms-status-badge-confirmed',
        cancelled: 'cms-status-badge-cancelled',
    };

    const iconMap = {
        pending: 'fas fa-clock',
        confirmed: 'fas fa-check',
        cancelled: 'fas fa-times',
    };

    return (
        <div className={`cms-status-badge ${classMap[status]}`}>
            <i className={iconMap[status]} style={{ marginRight: 6 }} />
            {statusBadgeStrings[status][lang]}
        </div>
    );
};

export default StatusBadge;
