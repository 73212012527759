import { useContext } from 'react';
import './styles.scss';
import UserContext from '../../contexts/UserContext';

const HeaderWrapper = ({ children }) => {
    const userContext = useContext(UserContext);

    const { isSidebarOpen } = userContext;

    return (
        <>
            <div
                className="cms-header-wrapper"
                style={{
                    left: isSidebarOpen ? 300 : 60,
                    width: isSidebarOpen
                        ? 'calc(100% - 300px)'
                        : 'calc(100% - 60px)',
                }}
            >
                {children}
            </div>
            <div className="cms-header-wrapper-dummy" />
        </>
    );
};

export default HeaderWrapper;
