import React from 'react';
import LoginForm from './LoginForm';
import './styles.scss';

const Login = () => {
    return (
        <div className="login-container">
            <LoginForm />
            <div className="login-wallpaper-container">
            </div>
        </div>
    );
};

export default Login;
