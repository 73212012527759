import pages from '../../constants/pages';

export const isCreatePage = () =>
    window.location.pathname.indexOf('create') !== -1;

export const getPage = (isAdmin = false, isAffiliate = false) => {
    const { pathname } = window.location;

    if (pathname === '' || pathname === '/')
        return isAffiliate
            ? pages.affiliateDashboard
            : isAdmin
            ? pages.adminDashboard
            : pages.managerDashboard;

    const pageKey = Object.keys(pages).find(
        (item) =>
            pathname.indexOf(pages[item].path) > -1 &&
            item !== 'adminDashboard' &&
            item !== 'managerDashboard' 
    );

    if (!pageKey) return pages.notFound;

    const page = pages[pageKey];

    return page;
};

export const getId = (url = window.location.pathname) =>
    url.substring(url.lastIndexOf('/') + 1);

export const getParam = (key, url = window.location.href) => {
    const urlObject = new URL(url);

    const result = urlObject.searchParams.get(key);

    return result;
};
