import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import FormContext from '../../../../contexts/FormContext';
import UserContext from '../../../../contexts/UserContext';
import { routes } from '../../../../helpers/request';
import FormContainer from '../../../../reusables/FormContainer';
import {
    affiliateFields,
    affiliateStrings,
    comissionRateOptions,
} from './constants';
import pages from '../../../../constants/pages';
import { getLanguageOptions } from '../../../../helpers/option';

const AdminAffiliateForm = () => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const { name, comissionRate, email, lang: langField } = affiliateFields;

    return (
        <FormContainer
            route={routes.affiliates}
            fields={affiliateFields}
            createTitleText={affiliateStrings.createFormTitleText[lang]}
            goBackUrl={pages.adminAffiliateList.path}
        >
            <div className="col-lg-3">
                <Input field={name} maxLength={48} />
            </div>
            <div className="col-lg-3">
                <Input field={email} maxLength={48} />
            </div>
            <div className="col-lg-3">
                <Select field={comissionRate} options={comissionRateOptions} />
            </div>
            <div className="col-lg-3">
                <Select field={langField} options={getLanguageOptions(lang)} />
            </div>
        </FormContainer>
    );
};

export default AdminAffiliateForm;
