import { useContext, useEffect, useState } from 'react';
import Input from '../../../../components/Input';
import UserContext from '../../../../contexts/UserContext';
import { routes } from '../../../../helpers/request';
import FormContainer from '../../../../reusables/FormContainer';
import Toggle from '../../../../components/Toggle';
import {
    reservationSettingsFields,
    reservationSettingsStrings,
} from './constants';

const ReservationSettings = () => {
    const userContext = useContext(UserContext);

    const { lang, company, load } = userContext;

    const {
        confirmReservations,
        dontAllowConflictingReservations,
        maxPendingReservations,
        canCancelReservationHoursPrior,
        canBookHoursPrior,
        askReservationConfirmationHoursPrior,
        notifyCustomerHoursPrior,
        cancelNotConfirmedReservationHoursPrior,
    } = reservationSettingsFields;

    return (
        <>
            <FormContainer
                route={routes.company}
                fields={reservationSettingsFields}
                existingData={company}
                isLoading={!company}
                afterSubmit={load}
                editTitleText={
                    reservationSettingsStrings.createFormTitleText[lang]
                }
                hasList={false}
            >
                <div className="col-lg-4">
                    <Toggle field={confirmReservations} />
                </div>
                <div className="col-lg-4">
                    <Toggle field={dontAllowConflictingReservations} />
                </div>
                <div className="col-lg-4">
                    <Input field={maxPendingReservations} type="integer" />
                </div>
                <div className="col-lg-4">
                    <Input field={canBookHoursPrior} type="integer" />
                </div>
                <div className="col-lg-4">
                    <Input
                        field={canCancelReservationHoursPrior}
                        type="integer"
                    />
                </div>
                <div className="col-lg-4">
                    <Input field={notifyCustomerHoursPrior} type="integer" />
                </div>
                <div className="col-lg-4">
                    <Input
                        field={askReservationConfirmationHoursPrior}
                        type="integer"
                    />
                </div>
                <div className="col-lg-4">
                    <Input
                        field={cancelNotConfirmedReservationHoursPrior}
                        type="integer"
                    />
                </div>
            </FormContainer>
        </>
    );
};

export default ReservationSettings;
