const iconClasses = {
    chevronUp: 'fa-solid fa-chevron-up',
    chevronRight: 'fa-solid fa-chevron-right',
    chevronDown: 'fa-solid fa-chevron-down',
    chevronLeft: 'fa-solid fa-chevron-left',
    chevronsUp: 'fa-solid fa-chevrons-up',
    chevronsRight: 'fa-solid fa-chevrons-right',
    chevronsDown: 'fa-solid fa-chevrons-down',
    chevronsLeft: 'fa-solid fa-chevrons-left',
    arrowUp: 'fa-solid fa-arrow-up',
    arrowRight: 'fa-solid fa-arrow-right',
    arrowDown: 'fa-solid fa-arrow-down',
    arrowLeft: 'fa-solid fa-arrow-left',
    tag: 'fa-solid fa-tag',
    tags: 'fa-solid fa-tags',
    edit: 'fa-solid fa-pen-to-square',
    delete: 'fa-solid fa-trash',
    share: 'fa-solid fa-share-from-square',
    listUl: 'fa-solid fa-list-ul',
    listOl: 'fa-solid fa-list-ol',
    listTree: 'fa-solid fa-list-tree',
    signOut: 'fa-solid fa-right-from-bracket',
    bars: 'fa-solid fa-bars',
    building: 'fa-solid fa-building',
    gear: 'fa-solid fa-gear',
    branch: 'fa-solid fa-code-branch',
    user: 'fa-solid fa-user',
    users: 'fa-solid fa-users',
    calendar: 'fa-solid fa-calendar',
    calendars: 'fa-solid fa-calendars',
    calendarWeek: 'fa-solid fa-calendar-week',
    calendarCheck: 'fa-solid fa-calendar-check',
    calendarPlus: 'fa-solid fa-calendar-plus',
    calendarMinus: 'fa-solid fa-calendar-minus',
    house: 'fa-solid fa-house',
    ticket: 'fa-solid fa-ticket',
    bell: 'fa-solid fa-bell',
    deposit: 'fa-solid fa-money-bill-transfer',
    lock: 'fa-solid fa-lock',
    unlock: 'fa-solid fa-unlock',
    check: 'fa-solid fa-check',
    circleCheck: 'fa-solid fa-circle-check',
    userPlus: 'fa-solid fa-user-plus',
    plus: 'fa-solid fa-plus',
    save: 'fa-solid fa-floppy-disk',
    times: 'fa-solid fa-x',
    envelope: 'fa-solid fa-envelope',
    eye: 'fa-solid fa-eye',
    eyeSlash: 'fa-solid fa-eye-slash',
    clock: 'fa-solid fa-clock',
    coins: 'fa-solid fa-coins',
    cart: 'fa-solid fa-cart-shopping',
    bolt: 'fa-solid fa-bolt',
    upload: 'fa-solid fa-upload',
    cubes: 'fa-solid fa-cubes',
    creditCard: 'fa-solid fa-credit-card',
    anglesLeft: 'fa-solid fa-angles-left',
    anglesRight: 'fa-solid fa-angles-right'
};

export default iconClasses;
