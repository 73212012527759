export const depositStrings = {
    packagesTitleText: {
        tr: 'Bakiye paketleri',
        en: 'Deposit products',
    },
    historyTitleText: {
        tr: 'Bakiye yükleme geçmişi',
        en: 'Deposit history',
    },
    modalTitleText: {
        tr: 'Bakiye yükleme',
        en: 'Deposit',
    },
    depositCreatedSuccessfuly: {
        tr: 'Bakiye yükleme isteği oluşturuldu',
        en: 'Deposit request has been created',
    },
    depositInfoDetails: {
        tr: [
            'HAVALE İLE ÖDEME İÇİN BİLGİLENDİRME',
            '• IBAN: TR54 0006 4000 0013 4270 5462 56',
            '• IBAN hesap ismi: Utku Akgüner',
            '• Açıklama: Açıklama kısmına sadece ÖDEME NUMARASI girilmesi gereklidir ve ZORUNLUDUR. Girilmediği takdirde ödeme kabul edilmez.',
            '• Ödeme yapıldıktan en geç 2 gün sonra sistem yöneticisi para aktarımınızı onaylar ve jetonlarınız aktif hale gelir.',
            '• Bir sorunla karşılaştığınız takdirde +90 531 917 02 22 telefon numarasından sistem yöneticisine ulaşabilirsiniz.',
        ],
        en: [
            'INFORMATION FOR PAYMENT VIA IBAN',
            '• IBAN: TR54 0006 4000 0013 4270 5462 56',
            '• IBAN account name: Utku Akgüner',
            '• Description: Only the PAYMENT NUMBER must be entered in the description section and it is MANDATORY. If it is not entered, payment will not be accepted.',
            '• At the latest 2 days after payment, the system administrator approves your money transfer and your tokens become active.',
            '• If you encounter a problem, you can reach the system administrator at +90 531 917 02 22.',
        ],
    }
};

export const depositFields = {
    paymentId: {
        name: 'paymentId',
        label: {
            tr: 'Ödeme numarası',
            en: 'Payment number',
        },
        showInList: true,
    },
    paymentMethod: {
        name: 'paymentMethod',
        label: {
            tr: 'Ödeme tipi',
            en: 'Payment type',
        },
        showInList: true,
    },
    createdAt: {
        name: 'createdAt',
        label: {
            tr: 'Tarih',
            en: 'Date',
        },
        showInList: true,
    },
    amount: {
        name: 'amount',
        label: {
            tr: 'Fiyat',
            en: 'Price',
        },
        showInList: true,
    },
    tokenAmount: {
        name: 'tokenAmount',
        label: {
            tr: 'Jeton miktarı',
            en: 'Token amount',
        },
        showInList: true,
    },
    status: {
        name: 'status',
        label: {
            tr: 'Durum',
            en: 'Status',
        },
        showInList: true,
    },
};