import { useContext } from 'react';
import { getFieldMapping } from '../../../../helpers/mapping';
import { callRoute, routes } from '../../../../helpers/request';
import ListContainer from '../../../../reusables/ListContainer';
import { affiliateFields, affiliateStrings } from './constants';
import UserContext from '../../../../contexts/UserContext';
import { getPercentageListMapping } from '../../../../helpers/list';
import iconClasses from '../../../../constants/iconClasses';
import { showErrorNotification, showSuccessNotification } from '../../../../helpers/popup';

const AdminAffiliateList = () => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const resendActivationMail = async (item) => {
        if (!item.email) return;

        try {
            await callRoute({
                route: routes.resendActivation,
                method: 'POST',
                body: {
                    email: item.email,
                },
                lang,
            });

            showSuccessNotification(affiliateStrings.activationMailSent[lang]);
        } catch (error) {
            showErrorNotification(error);
        }
    };

    return (
        <ListContainer
            route={routes.affiliates}
            headerMapping={getFieldMapping(affiliateFields, 'label')}
            valueMapping={{
                comissionRate: getPercentageListMapping,
            }}
            titleText={affiliateStrings.listTitleText[lang]}
            newButtonText={affiliateStrings.newButtonText[lang]}
            customActions={[
                {
                    icon: iconClasses.envelope,
                    action: resendActivationMail,
                    disabled: (item) => !item.activatedAt,
                },
            ]}
        />
    );
};

export default AdminAffiliateList;
