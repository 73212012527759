import React, { useContext } from 'react';

import './styles.scss';
import UserContext from '../../contexts/UserContext';

const Overlay = ({ children, hasSidebar }) => {
    const userContext = useContext(UserContext);

    const { isSidebarOpen } = userContext;

    return (
        <div
            className="cms-overlay"
            style={{
                left: hasSidebar ? (isSidebarOpen ? 300 : 60) : 0,
                width: hasSidebar
                    ? isSidebarOpen
                        ? 'calc(100% - 300px)'
                        : 'calc(100% - 60px)'
                    : '100%',
            }}
        >
            {children}
        </div>
    );
};

export default Overlay;
