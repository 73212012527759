export const getDurationOptions = (lang) => {
    if (lang === 'tr') {
        return [
            {
                label: '15 Dakika',
                value: 15,
            },
            {
                label: '30 Dakika',
                value: 30,
            },
            {
                label: '45 Dakika',
                value: 45,
            },
            {
                label: '1 Saat',
                value: 60,
            },
            {
                label: '1 Saat 15 Dakika',
                value: 75,
            },
            {
                label: '1 Saat 30 Dakika',
                value: 90,
            },
            {
                label: '1 Saat 45 Dakika',
                value: 105,
            },
            {
                label: '2 Saat',
                value: 120,
            },
        ];
    } else {
        return [
            {
                label: '15 Minute',
                value: 15,
            },
            {
                label: '30 Minute',
                value: 30,
            },
            {
                label: '45 Minute',
                value: 45,
            },
            {
                label: '1 Hour',
                value: 60,
            },
            {
                label: '1 Hour 15 Minute',
                value: 75,
            },
            {
                label: '1 Hour 30 Minute',
                value: 90,
            },
            {
                label: '1 Hour 45 Minute',
                value: 105,
            },
            {
                label: '2 Hour',
                value: 120,
            },
        ];
    }
};

export const getLanguageOptions = (lang) => {
    if (lang === 'tr') {
        return [
            {
                label: 'İngilizce',
                value: 'en',
            },
            {
                label: 'Türkçe',
                value: 'tr',
            },
        ];
    } else {
        return [
            {
                label: 'English',
                value: 'en',
            },
            {
                label: 'Turkish',
                value: 'tr',
            },
        ];
    }
};