import React, { createContext, useContext, useState } from 'react';
import { callRoute } from '../helpers/request';
import UserContext from './UserContext';

const FormContext = createContext(null);

export const FormContextProvider = ({ children }) => {
    const userContext = useContext(UserContext);

    const [data, setData] = useState({});
    const [lists, setLists] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { lang } = userContext;

    const init = async (fields, lists = []) => {
        setIsLoading(true);

        const newData = {};
        
        const newLists = {};

        fields.forEach((item) => {
            newData[item] = null;
        });

        const listResults = await Promise.all(
            lists.map((list) =>
                callRoute({
                    route: list,
                    method: 'GET',
                    lang,
                })
            )
        );

        lists.forEach((item, i) => {
            newLists[item] = listResults[i].data;
        });

        setData(newData);

        setLists(newLists);

        setIsLoading(false);
    };

    const get = (field) => {
        return data[field];
    };

    const update = (object) => {
        // TODO add input control
        setData({ ...data, ...object });
    };

    const reset = () => {
        setData({});

        setLists({});

        setIsLoading(true);
    };

    return (
        <FormContext.Provider
            value={{
                data,
                lists,
                isLoading,
                get,
                update,
                init,
                reset,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export default FormContext;
