import { useContext, useEffect, useState } from 'react';
import { callRoute, routes } from '../../../../helpers/request';
import images from '../../../../images';
import './styles.scss';
import { adminDashboardStrings } from './constants';
import UserContext from '../../../../contexts/UserContext';
import CardWrapper from '../../../../reusables/CardWrapper';

const AdminDashboard = () => {
    const userContext = useContext(UserContext);

    const { lang , currency} = userContext;

    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState({});

    const load = async () => {
        try {
            const { data } = await callRoute({
                route: routes.adminReport,
                method: 'GET',
            });

            setData(data);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        load();
    }, []);
    return (
        <div className="admin-dashboard-container">
            <CardWrapper>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="admin-dashboard-summary-item">
                            <div className="admin-dashboard-summary-item-head">
                                {adminDashboardStrings.todayReservations[lang]}
                            </div>
                            <div className="admin-dashboard-summary-item-body">
                                {data?.todayReservations}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="admin-dashboard-summary-item">
                            <div className="admin-dashboard-summary-item-head">
                                {adminDashboardStrings.weekReservations[lang]}
                            </div>
                            <div className="admin-dashboard-summary-item-body">
                                {data?.weekReservations}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="admin-dashboard-summary-item">
                            <div className="admin-dashboard-summary-item-head">
                                {adminDashboardStrings.monthReservations[lang]}
                            </div>
                            <div className="admin-dashboard-summary-item-body">
                                {data?.monthReservations}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="admin-dashboard-summary-item">
                            <div className="admin-dashboard-summary-item-head">
                                {adminDashboardStrings.todayDepositSize[lang]}
                            </div>
                            <div className="admin-dashboard-summary-item-body">
                                {data?.todayDepositSize}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="admin-dashboard-summary-item">
                            <div className="admin-dashboard-summary-item-head">
                                {adminDashboardStrings.weekDepositSize[lang]}
                            </div>
                            <div className="admin-dashboard-summary-item-body">
                                {data?.weekDepositSize}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="admin-dashboard-summary-item">
                            <div className="admin-dashboard-summary-item-head">
                                {adminDashboardStrings.monthDepositSize[lang]}
                            </div>
                            <div className="admin-dashboard-summary-item-body">
                                {data?.monthDepositSize}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="admin-dashboard-summary-item">
                            <div className="admin-dashboard-summary-item-head">
                                {adminDashboardStrings.todayDepositAmount[lang]}
                            </div>
                            <div className="admin-dashboard-summary-item-body">
                                {data?.todayDepositAmount} {currency}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="admin-dashboard-summary-item">
                            <div className="admin-dashboard-summary-item-head">
                                {adminDashboardStrings.weekDepositAmount[lang]}
                            </div>
                            <div className="admin-dashboard-summary-item-body">
                                {data?.weekDepositAmount} {currency}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="admin-dashboard-summary-item">
                            <div className="admin-dashboard-summary-item-head">
                                {adminDashboardStrings.monthDepositAmount[lang]}
                            </div>
                            <div className="admin-dashboard-summary-item-body">
                                {data?.monthDepositAmount} {currency}
                            </div>
                        </div>
                    </div>
                </div>
            </CardWrapper>
        </div>
    );
};

export default AdminDashboard;
