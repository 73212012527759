import { useContext } from 'react';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import FormContext from '../../../../contexts/FormContext';
import UserContext from '../../../../contexts/UserContext';
import { getNameOptions } from '../../../../helpers/mapping';
import { routes } from '../../../../helpers/request';
import FormContainer from '../../../../reusables/FormContainer';
import { reservationStaffFields, reservationStaffStrings } from './constants';
import ReservationStaffAvailabilityCalendar from './ReservationStaffAvailabilityCalendar';
import Toggle from '../../../../components/Toggle';

const ReservationStaffForm = () => {
    const userContext = useContext(UserContext);
    const formContext = useContext(FormContext);

    const { lists } = formContext;
    const { lang } = userContext;
    const { name, reservationTypeIds, availabilityCalendar, inactive } =
        reservationStaffFields;

    return (
        <FormContainer
            route={routes.reservationStaff}
            lists={[routes.reservationType]}
            fields={reservationStaffFields}
            createTitleText={reservationStaffStrings.createFormTitleText[lang]}
        >
            <div className="col-lg-6">
                <Input field={name} maxLength={48} />
            </div>
            <div className="col-lg-6">
                <Select
                    field={reservationTypeIds}
                    options={getNameOptions(lists[routes.reservationType])}
                    isMulti={true}
                />
            </div>
            <div className="col-lg-3">
                <Toggle field={inactive} />
            </div>
            <div className="col-12">
                <ReservationStaffAvailabilityCalendar
                    field={availabilityCalendar}
                />
            </div>
        </FormContainer>
    );
};

export default ReservationStaffForm;
