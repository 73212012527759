import React from 'react';

import './styles.scss';
import Icon from '../Icon';

const Button = ({
    children,
    onClick,
    loading,
    className = '',
    style = {},
    disabled = false,
    icon = null,
}) => {
    return (
        <button
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...style,
            }}
            className={'btn cms-button ' + className}
            onClick={onClick}
            disabled={disabled}
        >
            {icon && <Icon color="#fff" className={icon + ' me-2'} size={12} />}
            {loading ? (
                <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                ></div>
            ) : (
                children
            )}
        </button>
    );
};

export default Button;
