import React, { useContext } from 'react';
import FormContext from '../../contexts/FormContext';
import UserContext from '../../contexts/UserContext';
import './styles.scss';

const Textarea = ({
    field = null,
    name = '',
    label = '',
    placeholder = '',
    value = '',
    onChange = null,
    inputStyle = { width: '100%' },
    labelStyle = {},
    containerStyle = {},
    required = false,
    disabled = false,
    maxLength = null,
}) => {
    const formContext = useContext(FormContext);
    const userContext = useContext(UserContext);

    if (field) {
        if (field.name) name = field.name;
        if (field.label) label = field.label[userContext.lang];
        if (field.placeholder)
            placeholder = field.placeholder[userContext.lang];
        if (field.required) required = field.required;
    }

    const update = (value) => {
        const updateObject = {};
        updateObject[name] = value;
        formContext.update(updateObject);
    };

    return (
        <div style={containerStyle} className="cms-form-control">
            {label && (
                <label
                    style={labelStyle}
                    htmlFor={name}
                    className="cms-form-label"
                >
                    {label}{' '}
                    {required && <span style={{ color: 'red' }}>*</span>}
                </label>
            )}
            <div style={{ position: 'relative' }}>
                <textarea
                    disabled={disabled}
                    style={inputStyle}
                    className="cms-form-textarea"
                    id={name}
                    placeholder={placeholder}
                    onChange={(e) => {
                        if (maxLength && e.target.value.length > maxLength)
                            return;

                        onChange
                            ? onChange(e.target.value)
                            : update(e.target.value);
                    }}
                    value={value || (formContext?.data || {})[name] || ''}
                />
            </div>
        </div>
    );
};

export default Textarea;
