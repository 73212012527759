import { useContext, useEffect } from 'react';
import UserContext from '../../../contexts/UserContext';
import FormContext from '../../../contexts/FormContext';
import { getFieldMapping } from '../../../helpers/mapping';
import { callRoute, routes } from '../../../helpers/request';
import ListContainer from '../../../reusables/ListContainer';
import { companyFields, companyStrings } from './constants';
import {
    getCityListMapping,
    getCompanyTypeListMapping,
    getCountryListMapping,
    getStateListMapping,
    getInactiveListMapping,
} from '../../../helpers/list';
import iconClasses from '../../../constants/iconClasses';
import { showErrorNotification } from '../../../helpers/popup';
import cookieNames from '../../../constants/cookieNames';
import { getCookie, setCookie } from '../../../helpers/cookie';

const CompanyList = () => {
    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const { lang } = userContext;

    const openPortal = async (item) => {
        try {
            const { data } = await callRoute({
                route: routes.adminToken,
                method: 'GET',
                params: {
                    companyId: item._id,
                },
                lang,
            });

            if (!data?.token) {
                showErrorNotification(companyStrings.failedToOpenPortal[lang])
            }

            setCookie(cookieNames.jwtOriginal, getCookie(cookieNames.jwt));

            setCookie(cookieNames.jwt, data.token);

            window.location.href = '/';
        } catch (e) {
            showErrorNotification(e);
        }
    };

    useEffect(() => {
        formContext.init(
            [],
            [routes.country, routes.city, routes.state, routes.companyType]
        );

        return () => {
            formContext.reset();
        };
    }, []);

    return (
        <ListContainer
            route={routes.company}
            valueMapping={{
                countryId: getCountryListMapping,
                cityId: getCityListMapping,
                stateId: getStateListMapping,
                companyTypeId: getCompanyTypeListMapping,
                inactive: getInactiveListMapping,
            }}
            headerMapping={{
                ...getFieldMapping(companyFields, 'label'),
                inactive: companyFields.inactive.listText,
            }}
            titleText={companyStrings.listTitleText[lang]}
            newButtonText={companyStrings.newButtonText[lang]}
            customActions={[
                {
                    icon: iconClasses.bolt,
                    action: openPortal,
                },
            ]}
            deleteActionAllowed={false}
            // filters={companyListFilters[lang]}
        />
    );
};

export default CompanyList;
