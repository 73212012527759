export const routeUrls = {
    login: '/api/auth/login',
    user: '/api/user',
    userData: '/api/user/me',
    reservation: '/api/reservation',
    reservationType: '/api/reservation/type',
    reservationStaff: '/api/reservation/staff',
    reservationSetStatus: '/api/reservation/set-status',
    companyType: '/api/enum/company_type',
    country: '/api/enum/country',
    state: '/api/enum/state',
    city: '/api/enum/city',
    company: '/api/company',
    reservationsByRange: '/api/reservation/reservations-by-range',
    setPassword: '/api/auth/set-password',
    resendActivation: '/api/user/resend-activation',
    forgotPassword: '/api/auth/forgot-password',
    getNotifications: '/api/reservation/get-notifications',
    depositProduct: '/api/enum/deposit-product',
    deposit: '/api/company/deposit',
    depositTransfer: '/api/company/deposit/transfer',
    depositSetStatus: '/api/company/deposit/set-status',
    depositPending: '/api/company/deposit/pending',
    affiliates: '/api/affiliate',
    adminToken: '/api/admin/token',
    reservationAvailableSlots: '/api/reservation/available-slots',
    adminReport: '/api/admin/report',
    companyLogo: '/api/company/logo',
    blackout: '/api/company/blackout',
    reservationTypeLogo:  '/api/reservation/type/logo'
};

export const requestStrings = {
    undefinedEndpoint: 'Undefined endpoint',
    requestFailedWithNoMessage: 'Request failed with no message',
};
