export const paymentStatuses = {
    pending: 'pending',
    cancelled: 'cancelled',
    confirmed: 'confirmed'
}

export const paymentStatusStrings = {
    pending: {
        tr: 'Onay bekliyor',
        en: 'Pending',
    },
    confirmed: {
        tr: 'Onaylandı',
        en: 'Confirmed',
    },
    cancelled: {
        tr: 'İptal edildi',
        en: 'Cancelled',
    },
};
