import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../Icon';
import iconClasses from '../../constants/iconClasses';
import UserContext from '../../contexts/UserContext';
import { MARGIN_PER_DEPTH } from './constants';
import pages from '../../constants/pages';

const SidebarItem = ({
    path,
    title,
    elements,
    depth,
    iconClass,
    openParent = () => {},
    onClick = () => {},
    show = false,
    className = '',
    isSidebarOpen,
}) => {
    const location = useLocation();

    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const [isActive, setIsActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsActive(path === location.pathname);
    }, [location]);

    useEffect(() => {
        if (isActive || isOpen) openParent();
    }, [isActive, isOpen]);

    if (!show) return null;

    // remove bootstrap classes
    return (
        <>
            <Link
                to={{
                    pathname: path,
                }}
                onClick={() => {
                    setIsOpen(!isOpen);
                    onClick();
                }}
            >
                <div
                    className={
                        isActive
                            ? `cms-sidebar-item cms-sidebar-item-active ${className}`
                            : `cms-sidebar-item ${className}`
                    }
                >
                    <div
                        className="flex-grow-1 d-flex align-items-center"
                        style={{
                            marginLeft: isSidebarOpen
                                ? MARGIN_PER_DEPTH * (depth || 0)
                                : 0,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                minWidth: 24,
                            }}
                        >
                            <Icon className={iconClass} size={16} />
                        </div>
                        {isSidebarOpen && (
                            <p className="d-flex align-items-center">
                                {title}
                                {path === pages.reservationPending.path &&
                                userContext.pendingReservationCount > 0 ? (
                                    <span className="badge bg-danger ms-3 pending-reservations-badge">
                                        {userContext.pendingReservationCount}
                                    </span>
                                ) : null}
                            </p>
                        )}
                    </div>
                    {!isOpen && elements && isSidebarOpen? (
                        <Icon className={iconClasses.chevronDown} size={16} />
                    ) : elements && isSidebarOpen ? (
                        <Icon className={iconClasses.chevronUp} size={16} />
                    ) : null}
                </div>
            </Link>
            {Object.keys(elements || {}).map((item) => (
                <SidebarItem
                    key={elements[item].title[lang]}
                    path={elements[item].path}
                    title={elements[item].title[lang]}
                    elements={elements[item].elements}
                    iconClass={elements[item].icon}
                    depth={depth + 1}
                    openParent={() => setIsOpen(true)}
                    show={isOpen || !isSidebarOpen}
                    isSidebarOpen={isSidebarOpen}
                />
            ))}
        </>
    );
};

export default SidebarItem;
