import React, { useContext } from 'react';
import FormContext from '../../contexts/FormContext';
import UserContext from '../../contexts/UserContext';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import tr from 'date-fns/locale/tr';
registerLocale('tr', tr);

const DatePicker = ({
    field = null,
    name = '',
    label = '',
    value = null,
    onChange = null,
    containerStyle = {},
    labelStyle = {},
    placeholder = '',
    required = false,
    disabled = false,
    minDate = null,
    maxDate = null,
}) => {
    const formContext = useContext(FormContext);

    const userContext = useContext(UserContext);

    const { lang } = userContext;

    if (field) {
        if (field.name) name = field.name;
        if (field.label) label = field.label[lang];
        if (field.placeholder) placeholder = field.placeholder[lang];
        if (field.required) required = field.required;
    }

    const update = (value) => {
        const updateObject = {};
        updateObject[name] = value;
        formContext.update(updateObject);
    };

    const selected = value || formContext.data[name];

    return (
        <div style={containerStyle} className="cms-form-control">
            {label && (
                <label
                    style={labelStyle}
                    htmlFor={name}
                    className="cms-form-label"
                >
                    {label}{' '}
                    {required && <span style={{ color: 'red' }}>*</span>}
                </label>
            )}
            <ReactDatePicker
                id={name}
                className="cms-date-picker"
                onChange={(date) => (onChange ? onChange(date) : update(date))}
                selected={selected}
                placeholderText={placeholder}
                locale={lang}
                isClearable={!disabled}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                onFocus={(e) => {
                    e.target.readOnly = true;
                }}
            />
        </div>
    );
};

export default DatePicker;
