export const statusBadgeStrings = {
    pending: {
        tr: 'Onay bekliyor',
        en: 'Pending',
    },
    confirmed: {
        tr: 'Onaylandı',
        en: 'Confirmed',
    },
    cancelled: {
        tr: 'İptal edildi',
        en: 'Cancelled',
    },
};
