import React, { useContext } from 'react';
import FormContext from '../../contexts/FormContext';
import { default as ReactSelect } from 'react-select';
import { generateValueFromOptions } from '../../helpers/mapping';
import UserContext from '../../contexts/UserContext';
import { selectConstants } from './constants';
import './styles.scss';

const Select = ({
    field = null,
    name = '',
    label = '',
    placeholder = '',
    value = null,
    onChange = null,
    inputStyle = {},
    labelStyle = {},
    containerStyle = {},
    options = [],
    isMulti = false,
    required = false,
    disabled = false,
}) => {
    const formContext = useContext(FormContext);

    const userContext = useContext(UserContext);

    const { lang } = userContext;

    if (field) {
        if (field.name) name = field.name;

        if (field.label) label = field.label[lang];

        if (field.placeholder) placeholder = field.placeholder[lang];

        if (field.required) required = field.required;
    }

    const update = (object) => {
        const updateObject = {};

        let updateValue;

        if (isMulti) {
            updateValue = object ? object.map(({ value }) => value) : [];
        } else {
            updateValue = object.value;
        }

        updateObject[name] = updateValue;
        
        formContext.update(updateObject);
    };

    // https://react-select.com/styles#inner-components

    return (
        <div style={containerStyle} className="cms-form-control">
            {label && (
                <label
                    style={labelStyle}
                    htmlFor={name}
                    className="cms-form-label"
                >
                    {label}{' '}
                    {required && <span style={{ color: 'red' }}>*</span>}
                </label>
            )}
            <ReactSelect
                isDisabled={disabled}
                className="cms-form-select"
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#989ea4',
                        ...inputStyle,
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        zIndex: 9999,
                    }),
                }}
                options={options}
                isSearchable={true}
                placeholder={placeholder}
                onChange={onChange ? onChange : update}
                isMulti={isMulti}
                value={
                    value ||
                    generateValueFromOptions(
                        formContext.data[name],
                        options,
                        isMulti
                    ) ||
                    null
                }
                noOptionsMessage={() => selectConstants.noOptionsText[lang]}
            />
        </div>
    );
};

export default Select;
