import React, { useContext, useState } from 'react';
import FormContext from '../../contexts/FormContext';
import UserContext from '../../contexts/UserContext';
import { isFloat, isInteger } from '../../helpers/type';
import { inputTypes } from './constants';

import './styles.scss';
import Icon from '../Icon';
import iconClasses from '../../constants/iconClasses';

const Input = ({
    field = null,
    name = '',
    label = '',
    placeholder = '',
    value = '',
    onChange = null,
    inputType = inputTypes.text,
    inputStyle = { width: '100%' },
    labelStyle = {},
    containerStyle = {},
    type = 'text',
    required = false,
    disabled = false,
    postFix = null,
    maxLength = null
}) => {
    const formContext = useContext(FormContext);
    const userContext = useContext(UserContext);

    const [showPassword, setShowPassword] = useState(false);

    const { lang } = userContext;

    if (field) {
        if (field.name) name = field.name;
        if (field.label) label = field.label[lang];
        if (field.placeholder) placeholder = field.placeholder[lang];
        if (field.required) required = field.required;
    }

    const update = (value) => {
        if (
            (type === 'integer' && !isInteger(value)) ||
            (type === 'float' && !isFloat(value))
        )
            return;
        const updateObject = {};
        updateObject[name] = value;
        formContext.update(updateObject);
    };

    return (
        <div style={containerStyle} className="cms-form-control">
            {label && (
                <label
                    style={labelStyle}
                    htmlFor={name}
                    className="cms-form-label"
                >
                    {label}
                    {` ${postFix ? postFix : ''} `}
                    {required && <span style={{ color: 'red' }}>*</span>}
                </label>
            )}
            <div style={{ position: 'relative' }}>
                <input
                    style={inputStyle}
                    type={showPassword ? inputTypes.text : inputType}
                    className="cms-form-input"
                    id={name}
                    placeholder={`${placeholder} ${postFix ? postFix : ''}`}
                    onChange={(e) => {
                        if (maxLength && e.target.value.length > maxLength) return

                        onChange
                            ? onChange(e.target.value)
                            : update(e.target.value);
                    }}
                    value={value || (formContext?.data || {})[name] || ''}
                    disabled={disabled}
                />
                {inputType === inputTypes.password && (
                    <Icon
                        className={
                            showPassword
                                ? iconClasses.eyeSlash
                                : iconClasses.eye
                        }
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                        size={16}
                        color="gray"
                        onClick={() => setShowPassword(!showPassword)}
                    />
                )}
            </div>
        </div>
    );
};

export default Input;
