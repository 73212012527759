import { useContext, useEffect, useRef, useState } from 'react';
import { callRoute, routes } from '../../../../helpers/request';
import ListHeader from '../../../../reusables/ListContainer/ListHeader';
import './styles.scss';
import {
    allReservationTypeOption,
    allStaffOption,
    columnAmountOptions,
    reservationCalendarFields,
    reservationStatusOptions,
    reservationStrings,
} from './constants';
import UserContext from '../../../../contexts/UserContext';
import ReservationGrid from '../../../../components/ReservationGrid';
import Select from '../../../../components/Select';
import {
    generateEventsFromReservations,
    getNameOptions,
} from '../../../../helpers/mapping';
import { dateToLocale } from '../../../../helpers/date';
import { showErrorNotification } from '../../../../helpers/popup';

const ReservationCalendar = ({ iframe, isDashboard = false }) => {
    const userContext = useContext(UserContext);

    const abortController = useRef(null);

    const [isLoading, setIsLoading] = useState(false);

    const [startDate, setStartDate] = useState(null);

    const [endDate, setEndDate] = useState(null);

    const [staff, setStaff] = useState([]);

    const [reservationTypes, setReservationTypes] = useState([]);

    const [selectedStaff, setSelectedStaff] = useState(null);

    const [reservations, setReservations] = useState([]);

    const [columnAmount, setColumnAmount] = useState({
        label: iframe ? '1' : '3',
        value: iframe ? 1 : 3,
    });

    const [reservationStatus, setReservationStatus] = useState(null);

    const [reservationType, setReservationType] = useState(null);

    const { lang } = userContext;

    const loadReservationTypes = async () => {
        try {
            const { data } = await callRoute({
                route: routes.reservationType,
                method: 'GET',
                lang,
            });

            setReservationTypes(data);

            setReservationType(allReservationTypeOption[lang]);
        } catch (e) {
            showErrorNotification(e);
        }
    };

    const loadStaff = async () => {
        try {
            const { data } = await callRoute({
                route: routes.reservationStaff,
                method: 'GET',
                lang,
            });

            setStaff(data);

            setSelectedStaff(allStaffOption[lang]);
        } catch (e) {
            showErrorNotification(e);
        }
    };

    const loadFilters = async () => {
        setIsLoading(true);

        await Promise.all([loadStaff(), loadReservationTypes()]);

        setTimeout(() => {
            setIsLoading(false);
        }, 250);
    };

    const loadReservations = async () => {
        if (!selectedStaff) return;

        setIsLoading(true);

        let reservationStaffIds;

        if (selectedStaff.value !== 'all') {
            reservationStaffIds = [selectedStaff.value];
        } else {
            reservationStaffIds = staff.map((item) => item._id);
        }

        try {
            if (abortController.current) abortController.current.abort();

            abortController.current = new AbortController();

            const { data: reservations } = await callRoute({
                route: routes.reservationsByRange,
                method: 'POST',
                body: {
                    reservationStaffIds,
                    reservationType: reservationType.value,
                    startDate: dateToLocale(startDate).toISOString(),
                    endDate: dateToLocale(endDate).toISOString(),
                    status: reservationStatus.value,
                },
                lang,
                signal: abortController.current.signal,
            });

            setReservations(
                reservations.map((item) => ({
                    ...item,
                    startDate: dateToLocale(
                        new Date(item.startDate)
                    ).toISOString(),
                    endDate: dateToLocale(new Date(item.endDate)).toISOString(),
                }))
            );
        } catch (e) {
            showErrorNotification(e);
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 250);
    };

    useEffect(() => {
        loadFilters();

        loadReservations();

        setReservationStatus(reservationStatusOptions[lang][0]);
    }, []);

    useEffect(() => {
        loadReservations();
    }, [selectedStaff, startDate, endDate, reservationStatus, reservationType]);

    return (
        <>
            {!iframe && (
                <ListHeader
                    newButtonText={reservationStrings.newButtonText[lang]}
                    newRecordUrl={'/reservations/0'}
                />
            )}
            <div className="container pt-3">
                <div className="row mb-4">
                    <div
                        className={
                            isDashboard ? 'col-6' : 'col-6 col-xl-3'
                        }
                    >
                        <Select
                            field={reservationCalendarFields.staffFilter}
                            value={selectedStaff}
                            onChange={setSelectedStaff}
                            options={[
                                allStaffOption[lang],
                                ...getNameOptions(staff),
                            ]}
                        />
                    </div>
                    <div
                        className={
                            isDashboard ? 'col-6' : 'col-6 col-xl-3'
                        }
                    >
                        <Select
                            field={
                                reservationCalendarFields.reservationStatusFilter
                            }
                            value={reservationStatus}
                            onChange={setReservationStatus}
                            options={reservationStatusOptions[lang]}
                        />
                    </div>
                    {!isDashboard && !iframe && (
                        <>
                            <div className="col-6 col-xl-3">
                                <Select
                                    field={
                                        reservationCalendarFields.reservationTypeFilter
                                    }
                                    value={reservationType}
                                    onChange={setReservationType}
                                    options={[
                                        allReservationTypeOption[lang],
                                        ...getNameOptions(reservationTypes),
                                    ]}
                                />
                            </div>
                            <div className="col-6 col-xl-3">
                                <Select
                                    field={
                                        reservationCalendarFields.columnAmountFilter
                                    }
                                    value={columnAmount}
                                    onChange={setColumnAmount}
                                    options={columnAmountOptions}
                                />
                            </div>
                        </>
                    )}
                </div>
                <ReservationGrid
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    events={generateEventsFromReservations(reservations)}
                    reload={loadReservations}
                    isLoading={isLoading}
                    columnAmount={columnAmount.value}
                />
            </div>
        </>
    );
};

export default ReservationCalendar;
