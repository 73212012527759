import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';
import Icon from '../Icon';
import iconClasses from '../../constants/iconClasses';
import FormContext from '../../contexts/FormContext';
import UserContext from '../../contexts/UserContext';
import ImageResizeModal from '../../modals/ImageUploadModal';
import { fileToBase64 } from '../../helpers/file';

const DEFAULT_VALUE = '';

export default function ImageUpload({
    field = null,
    name,
    label = '',
    required = false,
    disabled = false,
    labelStyle = {},
    aspect = 1,
    uploadMedia = () => {}
}) {
    const formContext = useContext(FormContext);

    const userContext = useContext(UserContext);

    const [imageResizerOpen, setImageResizerOpen] = useState(false);

    const [preview, setPreview] = useState(DEFAULT_VALUE);

    const { lang } = userContext;

    if (field) {
        if (field.name) name = field.name;
        if (field.label) label = field.label[lang];
        if (field.required) required = field.required;
    }

    const fileInputId = `${name}-file-input`;

    const setValue = (value) => {
        const updateObject = {};

        updateObject[name] = value;

        formContext.update(updateObject);
    };

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setValue(null);

            return;
        }

        setValue(e.target.files[0]);

        setImageResizerOpen(true);
    };

    const loadPreview = async () => {
        const form = formContext.data;

        if (name in form && form[name] && typeof form[name] === 'object') {
            setPreview(await fileToBase64(form[name]));
        } else if (
            name in form &&
            form[name] &&
            typeof form[name] === 'string'
        ) {
            setPreview(form[name]);
        } else {
            setPreview(DEFAULT_VALUE);
        }
    };

    useEffect(() => {
        loadPreview()
    }, [formContext.data])

    return (
        <>
            <div className="cms-form-control">
                {label && (
                    <label
                        style={labelStyle}
                        htmlFor={name}
                        className="cms-form-label"
                    >
                        {label}
                        {required && <span style={{ color: 'red' }}>*</span>}
                    </label>
                )}
                <div
                    className={'cms-image-upload'}
                    onClick={() => document.getElementById(fileInputId).click()}
                >
                    {preview ? (
                        <img src={preview} />
                    ) : (
                        <Icon
                            className={iconClasses.upload}
                            size={18}
                            color="#003850"
                        />
                    )}
                </div>
            </div>
            {imageResizerOpen && (
                <ImageResizeModal
                    closeModal={() => {
                        setImageResizerOpen(false);
                    }}
                    file={name in formContext.data ?  formContext.data[name] : null}
                    setFile={setValue}
                    aspect={aspect}
                    uploadMedia={uploadMedia}
                />
            )}
            <input
                id={fileInputId}
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                style={{ display: 'none' }}
                disabled={disabled}
            />
        </>
    );
}
