export const reservationSettingsFields = {
    confirmReservations: {
        name: 'confirmReservations',
        label: {
            tr: 'Randevuları onayla',
            en: 'Confirm reservations',
        },
        showInList: false,
    },
    dontAllowConflictingReservations: {
        name: 'dontAllowConflictingReservations',
        label: {
            tr: 'Çakışan randevu isteklerine izin verme',
            en: 'Don\'t allow conflicting reservation requests',
        },
        showInList: false,
    },
    maxPendingReservations: {
        name: 'maxPendingReservations',
        label: {
            tr: 'Müşteri blok randevu kotası',
            en: 'Customer block reservation quota',
        },
        placeholder: {
            tr: 'Müşteri blok randevu kotası',
            en: 'Customer block reservation quota',
        },
        showInList: false,
    },
    canCancelReservationHoursPrior: {
        name: 'canCancelReservationHoursPrior',
        label: {
            tr: 'Müşteri X saat önceden randevuyu iptal edebilir',
            en: 'Customer can cancel X hours before reservation',
        },
        placeholder: {
            tr: 'Saat (X)',
            en: 'Hours (X)',
        },
        showInList: false,
    },
    canBookHoursPrior: {
        name: 'canBookHoursPrior',
        label: {
            tr: 'Müşteri X saat önce randevu almalı',
            en: 'Customer can book X hours before reservation',
        },
        placeholder: {
            tr: 'Saat (X)',
            en: 'Hours (X)',
        },
        showInList: false,
    },
    askReservationConfirmationHoursPrior: {
        name: 'askReservationConfirmationHoursPrior',
        label: {
            tr: 'Müşteriden X saat önce randevu onayı iste',
            en: 'Ask customer confirmation X hours before reservation',
        },
        placeholder: {
            tr: 'Saat (X)',
            en: 'Hours (X)',
        },
        showInList: false,
    },
    cancelNotConfirmedReservationHoursPrior: {
        name: 'cancelNotConfirmedReservationHoursPrior',
        label: {
            tr: 'Müşteri X saat önce onaylamazsa iptal et',
            en: 'Cancel if not confirmed X hours prior',
        },
        placeholder: {
            tr: 'Saat (X)',
            en: 'Hours (X)',
        },
        showInList: false,
    },
    notifyCustomerHoursPrior: {
        name: 'notifyCustomerHoursPrior',
        label: {
            tr: 'Müşteriye randevudan X saat önce bildirim at',
            en: 'Notify customer X hours before reservation',
        },
        placeholder: {
            tr: 'Saat (X)',
            en: 'Hours (X)',
        },
        showInList: false,
    },
};

export const reservationSettingsStrings = {
    createFormTitleText: {
        tr: 'Randevu ayarları',
        en: 'Reservation settings',
    },
};
