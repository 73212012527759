import React from 'react';
import SetPasswordForm from './SetPasswordForm';
import './styles.scss';

const SetPassword = () => {
    return (
        <div className="set-password-container">
            <SetPasswordForm />
            <div className="set-password-wallpaper-container">
            </div>
        </div>
    );
};

export default SetPassword;