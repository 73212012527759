import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DateTimePicker from '../../../../components/DateTimePicker';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import FormContext from '../../../../contexts/FormContext';
import UserContext from '../../../../contexts/UserContext';
import { getNameOptions } from '../../../../helpers/mapping';
import { routes } from '../../../../helpers/request';
import FormContainer from '../../../../reusables/FormContainer';
import { reservationFields, reservationStrings } from './constants';
import pages from '../../../../constants/pages';
import { reservationStatuses } from '../../../../constants/reservationStatuses';
import Textarea from '../../../../components/Textarea';

const ReservationForm = (iframe = false) => {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const { lists } = formContext;

    const { lang, company } = userContext;

    const { name, reservationTypeId, reservationStaffId, startDate, note } =
        reservationFields;

    useEffect(() => {
        const updateObject = { status: reservationStatuses.confirmed };

        updateObject[reservationStaffId.name] = null;

        formContext.update(updateObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formContext.get(reservationTypeId.name)]);

    return (
        <FormContainer
            route={routes.reservation}
            lists={[routes.reservationStaff, routes.reservationType]}
            fields={reservationFields}
            createTitleText={reservationStrings.createFormTitleText[lang]}
            goBackUrl={pages.reservationCalendar.path}
            afterSubmit={() => navigate(pages.reservationCalendar.path)}
            forceCreate={iframe}
            noHeader={iframe}
        >
            <div className="col-lg-6">
                <Input field={name} maxLength={48} />
            </div>
            <div className="col-lg-6">
                <Select
                    field={reservationTypeId}
                    options={getNameOptions(lists[routes.reservationType])}
                />
            </div>
            <div className="col-lg-6">
                <Select
                    field={reservationStaffId}
                    options={getNameOptions(
                        (lists[routes.reservationStaff] || []).filter(
                            (item) =>
                                item.reservationTypeIds.indexOf(
                                    formContext.get(reservationTypeId.name)
                                ) > -1
                        )
                    )}
                    disabled={!formContext.get(reservationTypeId.name)}
                />
            </div>
            <div className="col-lg-6">
                {company && (
                    <DateTimePicker
                        field={startDate}
                        useAvailability
                        reservationTypeId={formContext.get(
                            reservationTypeId.name
                        )}
                        reservationStaffId={formContext.get(
                            reservationStaffId.name
                        )}
                        disabled={
                            !formContext.get(reservationTypeId.name) ||
                            !formContext.get(reservationStaffId.name)
                        }
                        minDate={new Date()}
                    />
                )}
            </div>
            <div className="col-12">
                <Textarea field={note} maxLength={600} />
            </div>
        </FormContainer>
    );
};

export default ReservationForm;
