import React, { useContext, useState } from 'react';
import './styles.scss';
import UserContext from '../../contexts/UserContext';
import SidebarItem from './SidebarItem';
import sidebar from '../../constants/sidebar';
import iconClasses from '../../constants/iconClasses';
import { sidebarStrings } from './constants';
import { useNavigate } from 'react-router-dom';
import pages from '../../constants/pages';
import { deleteCookie, getCookie, setCookie } from '../../helpers/cookie';
import cookieNames from '../../constants/cookieNames';
import Icon from '../Icon';

const Sidebar = () => {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const {
        lang,
        data,
        isAdmin,
        setIsAdmin,
        company,
        credit,
        isAffiliate,
        isSidebarOpen,
        setIsSidebarOpen,
    } = userContext;

    const switchViewItems = () => {
        if (isAdmin) {
            return (
                <SidebarItem
                    key={'user-view'}
                    title={sidebarStrings.switchToUser[lang]}
                    iconClass={iconClasses.lock}
                    onClick={() => {
                        setIsAdmin(false);
                        setTimeout(() => {
                            navigate(pages.managerDashboard.path);
                        }, 200);
                    }}
                    show={true}
                    isSidebarOpen={isSidebarOpen}
                />
            );
        }

        if (!isAffiliate) {
            return (
                <SidebarItem
                    key={'admin-view'}
                    title={sidebarStrings.switchToAdmin[lang]}
                    iconClass={iconClasses.lock}
                    onClick={() => {
                        setIsAdmin(true);
                        setTimeout(() => {
                            navigate(pages.adminDashboard.path);
                        }, 200);
                    }}
                    show={true}
                    isSidebarOpen={isSidebarOpen}
                />
            );
        }

        return null;
    };

    return (
        <>
            <div
                className={
                    isSidebarOpen
                        ? 'cms-sidebar-dummy'
                        : 'cms-sidebar-dummy cms-sidebar-dummy-closed'
                }
            />
            <div
                className={
                    isSidebarOpen
                        ? 'cms-sidebar'
                        : 'cms-sidebar cms-sidebar-closed'
                }
            >
                <div className="cms-sidebar-section cms-sidebar-brand">
                    {company.logoUrl && isSidebarOpen && (
                        <img src={company.logoUrl} />
                    )}
                    {isSidebarOpen && (
                        <p>
                            {!isAffiliate
                                ? company?.name
                                : sidebarStrings.affiliateTitleText[lang]}
                        </p>
                    )}

                    <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        <Icon
                            className={
                                isSidebarOpen
                                    ? iconClasses.anglesLeft
                                    : iconClasses.anglesRight
                            }
                        />
                    </button>
                </div>
                <div
                    className="cms-sidebar-section cms-sidebar-items"
                    style={{
                        overflowY: window.innerHeight < 568 ? 'scroll' : 'auto',
                    }}
                >
                    {sidebar[
                        isAffiliate
                            ? 'affiliate'
                            : isAdmin
                            ? 'admin'
                            : 'manager'
                    ].map((item) => {
                        const { path, title, elements, icon, key } = item;
                        return (
                            <SidebarItem
                                key={key || title[lang]}
                                path={path}
                                title={title[lang]}
                                elements={elements}
                                iconClass={icon}
                                depth={0}
                                show={true}
                                isSidebarOpen={isSidebarOpen}
                            />
                        );
                    })}
                </div>
                {isSidebarOpen && (
                    <div className="cms-sidebar-info mt-3">
                        {sidebarStrings.userInfo[lang].replace(
                            '<fullName>',
                            data?.fullName || data?.name
                        )}
                    </div>
                )}
                {!isAffiliate && isSidebarOpen && (
                    <div
                        className={
                            credit < 50
                                ? 'cms-sidebar-info-danger'
                                : 'cms-sidebar-info'
                        }
                    >
                        {sidebarStrings.creditInfo[lang].replace(
                            '<credit>',
                            credit
                        )}
                    </div>
                )}
                <div className="cms-sidebar-section mt-3">
                    {getCookie(cookieNames.jwtOriginal) && (
                        <SidebarItem
                            title={sidebarStrings.exitPortal[lang]}
                            iconClass={iconClasses.times}
                            onClick={() => {
                                setCookie(
                                    cookieNames.jwt,
                                    getCookie(cookieNames.jwtOriginal)
                                );

                                deleteCookie(cookieNames.jwtOriginal);

                                window.location.reload(false);
                            }}
                            show={true}
                            className="bg-danger"
                            isSidebarOpen={isSidebarOpen}
                        />
                    )}
                    {data?.isAdmin && switchViewItems()}
                    <SidebarItem
                        title={sidebarStrings.logoutText[lang]}
                        iconClass={iconClasses.signOut}
                        onClick={() => userContext.logout()}
                        show={true}
                        isSidebarOpen={isSidebarOpen}
                    />
                </div>
            </div>
        </>
    );
};

export default Sidebar;
