export const reservationPendingStrings = {
    listTitleText: {
        tr: 'Bekleyen randevular',
        en: 'Pending reservations',
    },
    cancelConfirmationTitle: {
        tr: 'Randevuyu reddetmek istediğinizden emin misiniz?',
        en: 'Are you sure you want to reject the reservation?',
    },
    confirmSuccessText: {
        tr: 'Randevu onaylandı.',
        en: 'Confirmed reservation.',
    },
    cancelSuccessText: {
        tr: 'Randevu reddedildi.',
        en: 'Rejected reservation.',
    },
    noRecord: {
        tr: 'Bekleyen randevu yok.',
        en: 'No pending reservations.',
    },
    staff: {
        tr: 'Personel: ',
        en: 'Staff: ',
    },
    type: {
        tr: 'Hizmet: ',
        en: 'Service: ',
    },
    date: {
        tr: 'Tarih: ',
        en: 'Date: ',
    },
    note: {
        tr: 'Not: ',
        en: 'Note: ',
    },
    confirm: {
        tr: 'Onayla',
        en: 'Confirm',
    },
    reject: {
        tr: 'Reddet',
        en: 'Reject',
    }
};

export const reservationPendingFields = {
    name: {
        name: 'name',
        label: {
            tr: 'İsim',
            en: 'Name',
        },
        showInList: true,
    },
    reservationStaff: {
        name: 'reservationStaff',
        label: {
            tr: 'Personel',
            en: 'Staff',
        },
        showInList: true,
    },
    reservationType: {
        name: 'reservationType',
        label: {
            tr: 'Tip',
            en: 'Type',
        },
        showInList: true,
    },
    startDate: {
        name: 'startDate',
        label: {
            tr: 'Başlangıç tarihi',
            en: 'Start date',
        },
        showInList: true,
    },
    endDate: {
        name: 'endDate',
        label: {
            tr: 'Bitiş tarihi',
            en: 'End date',
        },
        showInList: true,
    },
};
