import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../contexts/UserContext';
import { reservationPendingStrings } from './constants';
import { callRoute, routes } from '../../../../helpers/request';
import {
    showCancellationModal,
    showErrorNotification,
    showSuccessNotification,
} from '../../../../helpers/popup';
import { reservationStatuses } from '../../../../constants/reservationStatuses';
import CardWrapper from '../../../../reusables/CardWrapper';
import ClipLoader from 'react-spinners/ClipLoader';
import {
    getDatetimeListMapping,
    getTimeListMapping,
} from '../../../../helpers/list';
import './styles.scss';
import Button from '../../../../components/Button';

const ReservationPending = ({ iframe, isDashboard = false }) => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState([]);

    const load = async () => {
        setIsLoading(true);

        try {
            // add pagination by states + cache on pages
            const { data } = await callRoute({
                route: routes.reservation,
                method: 'GET',
                lang,
                params: {
                    status: reservationStatuses.pending,
                },
            });

            setData(data);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    };

    const setReservationStatus = async (
        reservationId,
        status,
        cancellationReason = ''
    ) => {
        await callRoute({
            route: routes.reservationSetStatus,
            method: 'POST',
            body: {
                reservationId,
                status,
                cancellationReason,
            },
            lang,
        });
    };

    const confirmReservation = async (item) => {
        try {
            await setReservationStatus(
                item?._id,
                reservationStatuses.confirmed
            );

            showSuccessNotification(
                reservationPendingStrings.confirmSuccessText[lang]
            );

            userContext.checkPendingReservationCount();
        } catch (e) {
            showErrorNotification(e);
        }

        await load();
    };

    const cancelReservation = async (item) => {
        try {
            showCancellationModal(
                async (cancellationReason) => {
                    try {
                        await setReservationStatus(
                            item?._id,
                            reservationStatuses.cancelled,
                            cancellationReason
                        );

                        showSuccessNotification(
                            reservationPendingStrings.cancelSuccessText[lang]
                        );

                        userContext.checkPendingReservationCount();

                        setTimeout(() => {
                            load();
                        }, 200);
                    } catch (e) {
                        showErrorNotification(e);
                    }
                },
                lang,
                reservationPendingStrings.cancelConfirmationTitle[lang]
            );
        } catch (error) {
            showErrorNotification(error);
        }
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <CardWrapper>
            <h3 className="my-3">
                {reservationPendingStrings.listTitleText[lang]}
            </h3>
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 32,
                        marginBottom: 32,
                    }}
                >
                    <ClipLoader color={'#003850'} size={40} />
                </div>
            ) : data.length === 0 ? (
                <p className="my-4 text-align-center" style={{ fontSize: 17 }}>
                    {reservationPendingStrings.noRecord[lang]}
                </p>
            ) : (
                <div className="row">
                    {data.map((item) => (
                        <div className={iframe ? "col-12 col-lg-6 d-flex flex-column" : "col-sm-6 col-lg-4 d-flex flex-column"}>
                            <div className="reservation-pending-item">
                                <div className="reservation-pending-item-head">
                                    <div className="reservation-pending-item-title">
                                        {item.name}
                                    </div>
                                    <Button
                                        className="ms-2 success"
                                        onClick={() => confirmReservation(item)}
                                        style={{ fontSize: 14 }}
                                    >
                                        {
                                            reservationPendingStrings.confirm[
                                                lang
                                            ]
                                        }
                                    </Button>
                                    <Button
                                        className="ms-2 danger"
                                        onClick={() => cancelReservation(item)}
                                        style={{ fontSize: 14 }}
                                    >
                                        {reservationPendingStrings.reject[lang]}
                                    </Button>
                                </div>
                                <div className="reservation-pending-item-content">
                                    <div className="reservation-pending-item-content-section">
                                        <span className="reservation-pending-item-label">
                                            {
                                                reservationPendingStrings.staff[
                                                    lang
                                                ]
                                            }
                                        </span>
                                        {item?.reservationStaff?.name}
                                    </div>
                                    <div className="reservation-pending-item-content-section">
                                        <span className="reservation-pending-item-label">
                                            {
                                                reservationPendingStrings.type[
                                                    lang
                                                ]
                                            }
                                        </span>
                                        {item?.reservationType?.name}
                                    </div>
                                    <div className="reservation-pending-item-content-section">
                                        <span className="reservation-pending-item-label">
                                            {
                                                reservationPendingStrings.date[
                                                    lang
                                                ]
                                            }
                                        </span>
                                        {getDatetimeListMapping({
                                            value: item.startDate,
                                            lang,
                                        })}
                                        {` - `}
                                        {getTimeListMapping({
                                            value: item.endDate,
                                            lang,
                                        })}
                                    </div>
                                    {item.note && (
                                        <div className="reservation-pending-item-content-section">
                                            <span className="reservation-pending-item-label">
                                                {
                                                    reservationPendingStrings
                                                        .note[lang]
                                                }
                                            </span>
                                            {item.note}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </CardWrapper>
    );
};

export default ReservationPending;
