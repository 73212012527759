import { useContext } from 'react';
import images from '../../../../images';
import CardWrapper from '../../../../reusables/CardWrapper';
import ReservationCalendar from '../../reservation/Reservation/ReservationCalendar';
import ReservationPending from '../../reservation/ReservationPending';
import { managerDashboardStrings } from './constants';
import './styles.scss';
import UserContext from '../../../../contexts/UserContext';

const ManagerDashboard = () => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    return (
        <div className="manager-dashboard-container">
            <div className="row">
                <div className="col-xl-6">
                    <CardWrapper>
                        <h3 className="my-3">
                            {
                                managerDashboardStrings
                                    .reservationCalendarHeaderText[lang]
                            }
                        </h3>
                        <ReservationCalendar isDashboard={true} iframe={true} />
                    </CardWrapper>
                </div>
                <div className="col-xl-6 mt-xl-0 mt-4">
                    <ReservationPending isDashboard={true} iframe={true} />
                </div>
            </div>
        </div>
    );
};

export default ManagerDashboard;
