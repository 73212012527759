import { useContext } from 'react';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import FormContext from '../../../../contexts/FormContext';
import UserContext from '../../../../contexts/UserContext';
import { getNameOptions } from '../../../../helpers/mapping';
import { callRoute, routes } from '../../../../helpers/request';
import FormContainer from '../../../../reusables/FormContainer';
import { reservationTypeFields, reservationTypeStrings } from './constants';
import { getDurationOptions } from '../../../../helpers/option';
import Textarea from '../../../../components/Textarea';
import Toggle from '../../../../components/Toggle';
import ImageUpload from '../../../../components/ImageUpload';

const ReservationTypeForm = () => {
    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const { lists } = formContext;

    const { lang, currency } = userContext;

    const {
        name,
        duration,
        reservationStaffIds,
        description,
        inactive,
        originalPrice,
        discountedPrice,
        logoUrl,
    } = reservationTypeFields;

    const uploadMedia = async (file) => {
        try {
            if (typeof file !== 'object') return;

            const formData = new FormData();

            formData.append('file', file);

            if (formContext.get('_id'))
                formData.append('reservationTypeId', formContext.get('_id'));

            const { data } = await callRoute({
                route: routes.reservationTypeLogo,
                method: 'POST',
                body: formData,
            });

            formContext.update({
                logoUrl: data,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <FormContainer
            route={routes.reservationType}
            lists={[routes.reservationStaff]}
            fields={reservationTypeFields}
            createTitleText={reservationTypeStrings.createFormTitleText[lang]}
        >
            <div className="col-lg-3">
                <Input field={name} maxLength={48} />
            </div>
            <div className="col-lg-3">
                <Select field={duration} options={getDurationOptions(lang)} />
            </div>
            <div className="col-lg-6">
                <Select
                    field={reservationStaffIds}
                    options={getNameOptions(lists[routes.reservationStaff])}
                    isMulti={true}
                />
            </div>
            <div className="col-lg-3">
                <Input
                    type="float"
                    field={originalPrice}
                    postFix={`(${currency})`}
                />
            </div>
            <div className="col-lg-3">
                <Input
                    type="float"
                    field={discountedPrice}
                    postFix={`(${currency})`}
                />
            </div>
            <div className="col-3">
                <ImageUpload field={logoUrl} uploadMedia={uploadMedia} />
            </div>
            <div className="col-lg-3">
                <Toggle field={inactive} />
            </div>
            <div className="col-12">
                <Textarea field={description} />
            </div>
        </FormContainer>
    );
};

export default ReservationTypeForm;
