import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import FormContext from '../../contexts/FormContext';
import UserContext from '../../contexts/UserContext';
import { callRoute } from '../../helpers/request';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../../helpers/popup';
import CardWrapper from '../CardWrapper';
import { formContainerStrings } from './constants';
import FormHeader from './FormHeader';
import LoadingOverlay from '../../components/LoadingOverlay';
import iconClasses from '../../constants/iconClasses';

const FormContainer = ({
    children,
    route = '',
    lists = [],
    fields = [],
    createTitleText = 'Title',
    editTitleText = null,
    hasList = true,
    existingData = null,
    isLoading = false,
    afterSubmit = null,
    noHeader = false,
    goBackUrl = null,
    customValidations = [],
    forceCreate = false,
}) => {
    const { pathname } = window.location;

    const id = pathname.split('/').pop();

    const isCreatePage = forceCreate || id === '0';

    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const [editName, setEditName] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isIniting, setIsIniting] = useState(!isCreatePage);

    const navigate = useNavigate();

    const { lang } = userContext;

    const { data } = formContext;

    const checkRequiredFields = () => {
        for (let i = 0; i < Object.keys(fields).length; i++) {
            const field = fields[Object.keys(fields)[i]];

            if (field.required && !data[field.name]) {
                showErrorNotification(formContainerStrings.checkFields[lang]);
                return false;
            }
        }

        return true;
    };

    const save = async () => {
        if (!checkRequiredFields() || !checkCustomValidations()) return;

        setIsSubmitting(true);

        try {
            const { data: result } = await callRoute({
                route,
                method: isCreatePage ? 'POST' : 'PUT',
                body: data,
                lang,
            });

            const lastSlashIndex = pathname.lastIndexOf('/');

            const url = pathname.substring(0, lastSlashIndex + 1) + result._id;

            if (result.name)
                showSuccessNotification(
                    result.name + formContainerStrings.savedSuccessfuly[lang]
                );
            else showSuccessNotification(formContainerStrings.saved[lang]);

            setEditName(result.name);

            if (!existingData) navigate(url);

            if (afterSubmit) afterSubmit();
        } catch (error) {
            showErrorNotification(error);
        }

        setIsSubmitting(false);
    };

    const load = async () => {
        setIsIniting(true);

        if (existingData) {
            setEditName(existingData.name);

            formContext.update(existingData);

            setIsIniting(false);

            return;
        }

        try {
            const { data } = await callRoute({
                route,
                method: 'GET',
                params: {
                    _id: id,
                    noWrap: true,
                },
                lang,
            });

            if (!data.length) {
                showErrorNotification(
                    formContainerStrings.recordNotFound[lang]
                );

                navigate(-1);
            }

            const record = data.pop();

            setEditName(record.name);

            formContext.update(record);
        } catch (e) {
            showErrorNotification(e);
        }

        setIsIniting(false);
    };

    const checkCustomValidations = () => {
        for (let i = 0; i < customValidations.length; i++) {
            if (!customValidations[i].case) {
                showErrorNotification(customValidations[i].message);
                return false;
            }
        }

        return true;
    };

    useEffect(() => {
        const dispatch = async () => {
            await formContext.init(Object.keys(fields), lists);

            if (!isCreatePage) await load();
        };

        if (!isLoading && !isSubmitting) dispatch();

        return () => {
            formContext.reset();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isSubmitting]);

    if (isLoading || isIniting) return <LoadingOverlay hasSidebar={true} />;

    return (
        <>
            {!noHeader && (
                <FormHeader hasList={hasList} goBackUrl={goBackUrl} />
            )}
            <CardWrapper>
                <h3 className="my-3">
                    {isCreatePage
                        ? createTitleText
                        : editTitleText ||
                          `${editName}${formContainerStrings.editPostFix[lang]}`}
                </h3>
                <div className="row">{children}</div>
                <div className="d-flex justify-content-end mt-4">
                    <Button
                        loading={isSubmitting}
                        onClick={save}
                        style={{ minWidth: 100 }}
                        icon={iconClasses.save}
                    >
                        {formContainerStrings.saveButtonText[lang]}
                    </Button>
                </div>
            </CardWrapper>
        </>
    );
};

export default FormContainer;
