export const tableStrings = {
    actions: {
        tr: 'Aksiyon',
        en: 'Action',
    },
    deletedSuccessfuly: {
        tr: ' başarıyla silindi.',
        en: ' has been deleted successfuly.',
    },
    deleteConfirmationTitle: {
        tr: 'Bu öğeyi silmek istediğinizden emin misiniz?',
        en: 'Are you sure you want to delete this item?',
    },
    deleteConfirmationDescription: {
        tr: 'Bu geri döndürülemez bir işlemdir.',
        en: 'This is a irreversible action.',
    },
    noRecord: {
        tr: 'Kayıt yok',
        en: 'No record',
    },
};
