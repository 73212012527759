export const setPasswordStrings = {
    headerText: {
        tr: 'Şifre belirleme',
        en: 'Set password',
    },
    passwordLabel: {
        tr: 'Yeni şifreniz',
        en: 'New password',
    },
    passwordPlaceholder: {
        tr: 'Yeni hesap şifreniz',
        en: 'New acconut password',
    },
    passwordConfirmLabel: {
        tr: 'Yeni şifreniz tekrar',
        en: 'New password confirmation',
    },
    passwordConfirmPlaceholder: {
        tr: 'Yeni hesap şifreniz tekrar',
        en: 'New acconut password confirmation',
    },
    buttonText: {
        tr: 'Şifreyi belirle',
        en: 'Set password',
    },
    emailText: {
        tr: 'E-posta adresi: <email>',
        en: 'E-mail address: <email>',
    },
    completedText: {
        tr: '<email> için yeni şifre ayarlandı. Yeni şifreniz ile hesabınıza giriş yapabilirsiniz.',
        en: 'New password has been set for <email>. You can login to your account with the new password.',
    },
    loginText: {
        tr: 'Giriş yap',
        en: 'Login',
    },
    passwordsNotMatching: {
        tr: 'Şifreler eşleşmiyor!',
        en: 'Passwords are not matching!',
    }
};
