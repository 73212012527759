export const blackoutFields = {
    startDate: {
        name: 'startDate',
        label: {
            tr: 'Başlangıç tarihi',
            en: 'Start date',
        },
        placeholder: {
            tr: 'Başlangıç tarihi',
            en: 'Start date',
        },
        required: true,
        showInList: true,
    },
    endDate: {
        name: 'endDate',
        label: {
            tr: 'Bitiş tarihi',
            en: 'End date',
        },
        placeholder: {
            tr: 'Bitiş tarihi',
            en: 'End date',
        },
        required: true,
        showInList: true,
    },
};

export const blackoutStrings = {
    listTitleText: {
        tr: 'Tatil aralıkları',
        en: 'Blackouts',
    },
    newDepositProductButtonText: {
        tr: 'Yeni tatil aralığı',
        en: 'New blackout',
    },
    createFormTitleText: {
        tr: 'Yeni tatil aralığı',
        en: 'New blackout',
    }
};
