export const formContainerStrings = {
    saveButtonText: {
        tr: 'Kaydet',
        en: 'Save',
    },
    savedSuccessfuly: {
        tr: ' başarıyla kaydedildi.',
        en: ' has been saved successfuly.',
    },
    editPostFix: {
        tr: ' (Düzenle)',
        en: ' (Edit)',
    },
    goBackText: {
        tr: 'Geri git',
        en: 'Go back',
    },
    recordNotFound: {
        tr: 'Kayıt bulunamadı',
        en: 'Record not found.',
    },
    saved: {
        tr: 'Kaydedildi.',
        en: 'Saved.',
    },
    checkFields: {
        tr: 'Lütfen tüm zorunlu alanları doldurun.',
        en: 'Please fill all required fields.',
    },
};
