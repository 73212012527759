export const reservationStaffStrings = {
    newButtonText: {
        tr: 'Yeni randevu personeli',
        en: 'New reservation staff',
    },
    listTitleText: {
        tr: 'Randevu personelleri',
        en: 'Reservation staffs',
    },
    createFormTitleText: {
        tr: 'Yeni Randevu personeli',
        en: 'New reservation staff',
    },
};

export const reservationStaffFields = {
    name: {
        name: 'name',
        label: {
            tr: 'İsim',
            en: 'Name',
        },
        placeholder: {
            tr: 'Randevu personel ismi',
            en: 'Reservation staff name',
        },
        showInList: true,
        required: true,
    },
    reservationTypeIds: {
        name: 'reservationTypeIds',
        label: {
            tr: 'Randevu tipleri',
            en: 'Reservation types',
        },
        placeholder: {
            tr: 'Personelin ilgilendiği randevu tipleri',
            en: 'Reservation types staff is responsible for',
        },
        showInList: false,
    },
    availabilityCalendar: {
        name: 'availabilityCalendar',
        showInList: false,
    },
    inactive: {
        name: 'inactive',
        label: {
            tr: 'İnaktif',
            en: 'Inactive',
        },
        showInList: true,
        listText: {
            tr: 'Durum',
            en: 'Status',
        },
    },
};

export const reservationStaffAvailabilityCalendarStrings = {
    headerText: {
        tr: 'Müsaitlik takvimi',
        en: 'Availability calendar',
    },
};

export const reservationStaffAvailabilityCalendarConstants = {
    days: {
        tr: [
            'Pazartesi',
            'Salı',
            'Çarşamba',
            'Perşembe',
            'Cuma',
            'Cumartesi',
            'Pazar',
        ],
        en: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ],
    },
};
