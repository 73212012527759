import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import HeaderWrapper from '../HeaderWrapper';
import { listContainerStrings } from './constants';
import { useContext } from 'react';
import UserContext from '../../contexts/UserContext';
import iconClasses from '../../constants/iconClasses';

const ListHeader = ({
    newButtonText,
    newRecordUrl,
    goBackUrl,
    disableNew = false,
}) => {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const { lang } = userContext;

    return (
        <HeaderWrapper>
            <Button
                className="cms-button-outlined"
                onClick={() => navigate(goBackUrl)}
                disabled={!goBackUrl}
                icon={iconClasses.arrowLeft}
            >
                {listContainerStrings.goBackText[lang]}
            </Button>
            <div className="flex-grow-1" />
            <div>
                {!disableNew && (
                    <Link to={newRecordUrl || `${window.location.pathname}/0`}>
                        <Button
                            className="cms-button-outlined"
                            icon={iconClasses.plus}
                        >
                            {newButtonText}
                        </Button>
                    </Link>
                )}
            </div>
        </HeaderWrapper>
    );
};

export default ListHeader;
