export const getFieldMapping = (object, field) => {
    const result = {};

    Object.keys(object).forEach((key) => {
        if (object[key].showInList) result[key] = object[key][field];
    });

    return result;
};

export const getNameOptions = (list) =>
    list
        ? list.map((item) => ({
              label: item.name,
              value: item._id,
          }))
        : [];

export const getLangOptions = (list, lang) =>
    list
        ? list.map((item) => ({
              label: item[lang],
              value: item._id,
          }))
        : [];

export const generateValueFromOptions = (value, options, isMulti = false) => {
    if (!value && value !== 0) return null;
    else if (isMulti)
        return options.filter((item) => value.indexOf(item.value) > -1);
    else return options.find((item) => item.value === value);
};

export const generateEventsFromReservations = (reservations) => {
    return reservations.map((item) => ({
        title: item.reservationType
            ? `${item.name}`
            : item.name,
        start: item.startDate.replace('Z', ''),
        end: item.endDate.replace('Z', ''),
        allDay: false,
        id: item._id,
        item,
    }));
};
