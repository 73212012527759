import React, { useContext, useEffect } from 'react';
import UserContext from '../../../../contexts/UserContext';
import { depositProductFields, depositProductStrings } from './constants';
import { routes } from '../../../../helpers/request';
import ListContainer from '../../../../reusables/ListContainer';
import { getFieldMapping } from '../../../../helpers/mapping';
import { getCountryListMapping, getPriceListMapping } from '../../../../helpers/list';
import FormContext from '../../../../contexts/FormContext';

const AdminDepositProductList = ({}) => {
    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const { lang } = userContext;

    useEffect(() => {
        formContext.init(
            [],
            [routes.country]
        );

        return () => {
            formContext.reset();
        };
    }, []);

    return (
        <ListContainer
            route={routes.depositProduct}
            headerMapping={getFieldMapping(depositProductFields, 'label')}
            valueMapping={{
                countryId: getCountryListMapping,
                price: getPriceListMapping,
            }}
            titleText={depositProductStrings.listTitleText[lang]}
            newButtonText={depositProductStrings.newDepositProductButtonText[lang]}
        />
    );
};

export default AdminDepositProductList;
