export const loginStrings = {
    headerText: {
        tr: 'Giriş yap',
        en: 'Login',
    },
    emailLabel: {
        tr: 'E-posta',
        en: 'E-mail',
    },
    emailPlaceholder: {
        tr: 'E-posta adresiniz',
        en: 'Your e-mail address',
    },
    passwordLabel: {
        tr: 'Şifre',
        en: 'Password',
    },
    passwordPlaceholder: {
        tr: 'Hesap şifreniz',
        en: 'Your password',
    },
    buttonText: {
        tr: 'Giriş yap',
        en: 'Login',
    },
    orText: {
        tr: 'veya',
        en: 'or',
    },
    forgotPasswordText: {
        tr: 'Şifrenizi mi unuttunuz?',
        en: 'Forgot your password?',
    },
    affiliateLoginLabel: {
        tr: 'Satış ortağıyım',
        en: 'I am an affiliate',
    },
};
