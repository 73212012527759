import './styles.scss';

const AffiliateDashboard = () => {
    return (
        <div className="dashboard-container">
            Affiliate
        </div>
    );
};

export default AffiliateDashboard;
