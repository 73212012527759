import React from 'react';
import { modalConstants } from './constants';
import Modal from 'react-modal';
import Button from '../components/Button';
import iconClasses from '../constants/iconClasses';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 500,
        zIndex: 1000,
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        overflowY: 'auto',
    },
};

Modal.setAppElement('#root');

const ConfirmModal = ({ title, message, onSubmit, onClose, lang }) => {
    return (
        <Modal isOpen={true} style={customStyles} onRequestClose={onClose}>
            <h4 className="mb-3">{title}</h4>
            <p>{message}</p>
            <div className="d-flex mt-5 justify-content-end">
                <Button
                    onClick={onClose}
                    style={{ width: 90 }}
                    icon={iconClasses.times}
                    className="danger"
                >
                    {modalConstants.no[lang]}
                </Button>
                <Button
                    onClick={() => {
                        onSubmit();
                        onClose();
                    }}
                    style={{ width: 90, marginLeft: 8 }}
                    icon={iconClasses.check}
                    className="success"
                >
                    {modalConstants.yes[lang]}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
