export const depositPendingStrings = {
    listTitleText: {
        tr: 'Bekleyen bakiye yüklemeleri',
        en: 'Pending deposits',
    },
    cancelConfirmationTitle: {
        tr: 'Bakiye yüklemeyi reddetmek istediğinizden emin misiniz?',
        en: 'Are you sure you want to cancel the deposit?',
    },
    confirmSuccessText: {
        tr: 'Bakiye yükleme onaylandı.',
        en: 'Confirmed deposit.',
    },
    cancelSuccessText: {
        tr: 'Bakiye yükleme reddedildi.',
        en: 'Rejected deposit.',
    },
    confirmTitle: {
        tr: 'Transferi kabul etmek istediğinizden emin misiniz?',
        en: 'Are you sure you want to confirm the deposit?',
    },
};

export const depositPendingFields = {
    createdAt: {
        name: 'createdAt',
        label: {
            tr: 'Tarih',
            en: 'Date',
        },
        showInList: true,
    },
    user: {
        name: 'user',
        label: {
            tr: 'Kullanıcı',
            en: 'User',
        },
        showInList: true,
    },
    amount: {
        name: 'amount',
        label: {
            tr: 'Fiyat',
            en: 'Price',
        },
        showInList: true,
    },
    currency: {
        name: 'currency',
        label: {
            tr: 'Para birimi',
            en: 'Currency',
        },
        showInList: true,
    },
    tokenAmount: {
        name: 'tokenAmount',
        label: {
            tr: 'Jeton miktarı',
            en: 'Token amount',
        },
        showInList: true,
    },
    code: {
        name: 'code',
        label: {
            tr: 'Kod',
            en: 'Code',
        },
        showInList: true,
    },
};
