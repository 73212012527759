import React, { useContext } from 'react';
import { modalConstants } from './constants';
import Modal from 'react-modal';
import Button from '../components/Button';
import iconClasses from '../constants/iconClasses';
import UserContext from '../contexts/UserContext';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 600,
        zIndex: 1000,
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        overflowY: 'auto',
    },
};

Modal.setAppElement('#root');

const DepositCheckoutModal = ({ title, onTransfer, onClose, item }) => {
    const userContext = useContext(UserContext);

    const { lang, company } = userContext;

    const appliedPrice = parseFloat(
        company.discountPercentage !== 0
            ? item.price * ((100 - company.discountPercentage) / 100)
            : item.price
    ).toFixed(2);

    return (
        <Modal isOpen={true} style={customStyles} onRequestClose={onClose}>
            <h4 className="mb-3">{title}</h4>
            <div className="my-3">
                <p>
                    <b>{modalConstants.depositPrice[lang]}</b>
                    {appliedPrice} {item.currency}
                </p>
                <p>
                    <b>{modalConstants.depositTokenAmount[lang]}</b>
                    {item.tokenAmount} {modalConstants.tokenText[lang]}
                </p>
                {company.discountPercentage !== 0 && (
                    <p>
                        <b>{modalConstants.depositDiscountPercentage[lang]}</b>
                        {company.discountPercentage}%
                    </p>
                )}
            </div>
            <div className="alert alert-info" role="alert">
                {modalConstants.depositCheckoutDetails[lang].map((item) => (
                    <p>{item}</p>
                ))}
            </div>
            <div className="d-flex mt-5 justify-content-end">
                <Button
                    onClick={onClose}
                    icon={iconClasses.times}
                    className="danger"
                >
                    {modalConstants.cancel[lang]}
                </Button>
                <div className="flex-grow-1" />
                <Button
                    onClick={() => {
                        // onClose();
                    }}
                    style={{ marginLeft: 8 }}
                    icon={iconClasses.creditCard}
                    className="primary"
                    disabled={true}
                >
                    {modalConstants.payCreditCard[lang]}
                </Button>
                <Button
                    onClick={() => {
                        onTransfer();

                        onClose();
                    }}
                    style={{ marginLeft: 8 }}
                    icon={iconClasses.deposit}
                    className="primary"
                >
                    {modalConstants.payTransfer[lang]}
                </Button>
            </div>
        </Modal>
    );
};

export default DepositCheckoutModal;
