import Login from '../pages/auth/Login';
import NotFound from '../pages/shared/NotFound';
import AnnouncementForm from '../pages/manager/announcement/Announcement/AnnouncementForm';
import AnnouncementList from '../pages/manager/announcement/Announcement/AnnouncementList';
import CompanyForm from '../pages/shared/Company/CompanyForm';
import CompanyList from '../pages/shared/Company/CompanyList';
import TicketForm from '../pages/manager/ticket/Ticket/TicketForm';
import TicketList from '../pages/manager/ticket/Ticket/TicketList';
import ReservationCalendar from '../pages/manager/reservation/Reservation/ReservationCalendar';
import ReservationStaffForm from '../pages/manager/reservation/ReservationStaff/ReservationStaffForm';
import ReservationStaffList from '../pages/manager/reservation/ReservationStaff/ReservationStaffList';
import ReservationTypeForm from '../pages/manager/reservation/ReservationType/ReservationTypeForm';
import ReservationTypeList from '../pages/manager/reservation/ReservationType/ReservationTypeList';
import AdminDashboard from '../pages/admin/home/AdminDashboard';
import ManagerDashboard from '../pages/manager/home/ManagerDashboard';
import TicketView from '../pages/manager/ticket/Ticket/TicketView';
import AdminTicketList from '../pages/admin/ticket/AdminTicket/AdminTicketList';
import AdminTicketView from '../pages/admin/ticket/AdminTicket/AdminTicketView';
import ReservationForm from '../pages/manager/reservation/Reservation/ReservationForm';
import SetPassword from '../pages/auth/SetPassword';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ReservationPending from '../pages/manager/reservation/ReservationPending';
import ReservationCalendarIframe from '../pages/iframe/ReservationCalendarIframe';
import ReservationPendingIframe from '../pages/iframe/ReservationPendingIframe';
import ReservationSettings from '../pages/manager/reservation/ReservationSettings';
import Deposit from '../pages/manager/deposit/Deposit';
import AdminAffiliateList from '../pages/admin/affiliate/AdminAffiliate/AdminAffiliateList';
import AdminAffiliateForm from '../pages/admin/affiliate/AdminAffiliate/AdminAffiliateForm';
import AffiliateDashboard from '../pages/affiliate/home/AffiliateDashboard';
import AdminDepositPending from '../pages/admin/deposit/AdminDepositPending';
import CompanyPortal from '../pages/shared/Company/CompanyPortal';
import DepositIframe from '../pages/iframe/DepositIframe';
import AdminDepositProductList from '../pages/admin/deposit/AdminDepositProduct/AdminDepositProductList';
import AdminDepositProductForm from '../pages/admin/deposit/AdminDepositProduct/AdminDepositProductForm';
import BlackoutList from '../pages/manager/reservation/Blackout/BlackoutList';
import BlackoutForm from '../pages/manager/reservation/Blackout/BlackoutForm';
import ReservationFormIframe from '../pages/iframe/ReservationFormIframe';

const pages = {
    managerDashboard: { path: '/', element: <ManagerDashboard /> },
    depositIframe: {
        path: '/deposit/iframe',
        element: <DepositIframe />,
        iframe: true,
    },
    reservationCalendarIframe: {
        path: '/reservations/calendar/iframe',
        element: <ReservationCalendarIframe />,
        iframe: true,
    },
    reservationCalendar: {
        path: '/reservations/calendar',
        element: <ReservationCalendar />,
    },
    reservationPendingIframe: {
        path: '/reservations/pending/iframe',
        element: <ReservationPendingIframe />,
        iframe: true,
    },
    reservationFormIframe: {
        path: '/reservations/form/iframe',
        element: <ReservationFormIframe />,
        iframe: true,
    },
    reservationPending: {
        path: '/reservations/pending',
        element: <ReservationPending />,
    },
    reservationTypeList: {
        path: '/reservations/type',
        element: <ReservationTypeList />,
    },
    reservationTypeForm: {
        path: '/reservations/type/*',
        element: <ReservationTypeForm />,
    },
    reservationStaffList: {
        path: '/reservations/staff',
        element: <ReservationStaffList />,
    },
    reservationStaffForm: {
        path: '/reservations/staff/*',
        element: <ReservationStaffForm />,
    },
    reservationSettings: {
        path: '/reservations/settings',
        element: <ReservationSettings />,
    },
    reservationForm: {
        path: '/reservations/*',
        element: <ReservationForm />,
    },
    blackoutList: {
        path: '/company/blackout',
        element: <BlackoutList />,
    },
    blackoutForm: {
        path: '/company/blackout/*',
        element: <BlackoutForm />,
    },
    companyForm: {
        path: '/company',
        element: <CompanyForm />,
    },
    announcementList: {
        path: '/announcements',
        element: <AnnouncementList />,
    },
    announcementForm: {
        path: '/announcements/*',
        element: <AnnouncementForm />,
    },
    ticketList: {
        path: '/tickets',
        element: <TicketList />,
    },
    ticketView: {
        path: '/tickets/view/*',
        element: <TicketView />,
    },
    ticketForm: {
        path: '/tickets/*',
        element: <TicketForm />,
    },
    deposit: {
        path: '/deposit',
        element: <Deposit />,
    },
    adminDashboard: {
        path: '/admin',
        element: <AdminDashboard />,
        isAdmin: true,
    },
    adminCompanyList: {
        path: '/admin/companies',
        element: <CompanyList />,
        isAdmin: true,
    },
    adminCompanyForm: {
        path: '/admin/companies/*',
        element: <CompanyForm />,
        isAdmin: true,
    },
    adminCompanyPortal: {
        path: '/admin/companies/portal/*',
        element: <CompanyPortal />,
        isAdmin: true,
    },
    adminAffiliateList: {
        path: '/admin/affiliates',
        element: <AdminAffiliateList />,
        isAdmin: true,
    },
    adminAffiliateForm: {
        path: '/admin/affiliates/*',
        element: <AdminAffiliateForm />,
        isAdmin: true,
    },
    adminTicketList: {
        path: '/admin/tickets',
        element: <AdminTicketList />,
        isAdmin: true,
    },
    adminTicketView: {
        path: '/admin/tickets/view/*',
        element: <AdminTicketView />,
        isAdmin: true,
    },
    adminDepositPending: {
        path: '/admin/deposit/pending',
        element: <AdminDepositPending />,
        isAdmin: true,
    },
    adminDepositProductList: {
        path: '/admin/deposit/product',
        element: <AdminDepositProductList />,
        isAdmin: true,
    },
    adminDepositProductForm: {
        path: '/admin/deposit/product/*',
        element: <AdminDepositProductForm />,
        isAdmin: true,
    },
    affiliateDashboard: {
        path: '/affiliate',
        element: <AffiliateDashboard />,
        isAffiliate: true,
    },
    login: {
        path: '/login',
        element: <Login />,
        anonymous: true,
        guestPage: true,
    },
    setPassword: {
        path: '/set-password',
        element: <SetPassword />,
        anonymous: true,
        guestPage: true,
    },
    forgotPassword: {
        path: '/forgot-password',
        element: <ForgotPassword />,
        anonymous: true,
        guestPage: true,
    },
    notFound: { path: '/*', element: <NotFound /> },
};

export default pages;
