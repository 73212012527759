import React, { useState } from 'react';
import { modalConstants } from './constants';
import Modal from 'react-modal';
import Button from '../components/Button';
import iconClasses from '../constants/iconClasses';
import Textarea from '../components/Textarea';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 500,
        zIndex: 1000,
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 1000,
        overflowY: 'auto',
    },
};

Modal.setAppElement('#root');

const CancellationModal = ({ title, onSubmit, onClose, lang }) => {
    const [cancellationReason, setCancellationReason] = useState('');

    const cancellationReasonPlaceholder = {
        en: 'Cancellation reason',
        tr: 'İptal nedeni',
    };

    return (
        <Modal isOpen={true} style={customStyles} onRequestClose={onClose}>
            <h4 className="mb-4">{title}</h4>
            <div>
                <Textarea
                    value={cancellationReason}
                    onChange={setCancellationReason}
                    placeholder={cancellationReasonPlaceholder[lang]}
                />
            </div>
            <div className="d-flex mt-3 justify-content-end">
                <Button
                    onClick={onClose}
                    icon={iconClasses.times}
                    className="danger"
                >
                    {modalConstants.no[lang]}
                </Button>
                <Button
                    disabled={!cancellationReason}
                    onClick={async () => {
                        await onSubmit(cancellationReason);
                        onClose();
                    }}
                    style={{ marginLeft: 8 }}
                    icon={iconClasses.check}
                    className="success"
                >
                    {modalConstants.yes[lang]}
                </Button>
            </div>
        </Modal>
    );
};

export default CancellationModal;
