import { useContext, useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { inputTypes } from '../../../components/Input/constants';
import FormContext from '../../../contexts/FormContext';
import UserContext from '../../../contexts/UserContext';
import { loginStrings } from './constants';
import { Link } from 'react-router-dom';
import pages from '../../../constants/pages';
import iconClasses from '../../../constants/iconClasses';
import images from '../../../images';
import Toggle from '../../../components/Toggle';

const LoginForm = () => {
    const userContext = useContext(UserContext);
    const formContext = useContext(FormContext);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isAffiliate, setIsAffiliate] = useState(false);

    const { lang } = userContext;

    const submit = async () => {
        setIsSubmitting(true);

        await userContext.login(
            formContext.get('email'),
            formContext.get('password'),
            isAffiliate
        );

        setIsSubmitting(false);
    };

    useEffect(() => {
        formContext.reset();
    }, []);

    return (
        <div className="login-form-container">
            <img src={images.logo} className="mb-3" />
            <h3>{loginStrings.headerText[lang]}</h3>
            <Input
                name="email"
                label={loginStrings.emailLabel[lang]}
                placeholder={loginStrings.emailPlaceholder[lang]}
            />
            <Input
                name="password"
                label={loginStrings.passwordLabel[lang]}
                placeholder={loginStrings.passwordPlaceholder[lang]}
                inputType={inputTypes.password}
            />
            {/* <Toggle
                label={loginStrings.affiliateLoginLabel[lang]}
                inline
                containerStyle={{ margin: 0 }}
                labelStyle={{ color: '#343a40', fontSize: 15 }}
                value={isAffiliate}
                onChange={setIsAffiliate}
            /> */}
            <div className="d-flex mt-4">
                <Button
                    style={{ minWidth: 80, flex: 1 }}
                    onClick={submit}
                    loading={isSubmitting}
                    icon={iconClasses.chevronRight}
                >
                    {loginStrings.buttonText[lang]}
                </Button>
            </div>
            <div className="login-form-or-text">
                {loginStrings.orText[lang]}
            </div>
            <Link
                className="cms-link login-form-forgot-password-link"
                onClick={() => {
                    window.location.href = pages.forgotPassword.path;
                }}
            >
                {loginStrings.forgotPasswordText[lang]}
            </Link>
        </div>
    );
};

export default LoginForm;
