export const turkishSort = (a, b) => {
    var atitle = a.name;
    var btitle = b.name;

    var alfabe =
        'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (atitle.length === 0 || btitle.length === 0) {
        return atitle.length - btitle.length;
    }
    for (var i = 0; i < atitle.length && i < btitle.length; i++) {
        var ai = alfabe.indexOf(atitle[i]);
        var bi = alfabe.indexOf(btitle[i]);
        if (ai !== bi) {
            return ai - bi;
        }
    }
};

export const englishSort = (a, b) => (a.name > b.name ? 1 : -1);

export const formatNumberLength = (digit) => {
    const result = String(digit);

    if (result.length === 1) {
        return `0${result}`;
    } else if (result.length === 0) {
        return '00';
    }

    return result;
};

export const numbersToTime = (hour, minute) => {
    return `${formatNumberLength(hour)}:${formatNumberLength(minute)}`;
};

export const extractIframeUrlSrc = (url) => {
    if (!url) return '';

    const match = url.match(/<iframe.*?src=["|'](.*?)["|']/i);

    if (match.length < 2) return '';

    return match[1];
};

export const truncateText = (text, length = 60) => {
    if (text.length < length) return text
    
    return text.substring(0, length - 3) + '...';
}