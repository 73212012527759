import React, { useContext, useState } from 'react';
import { modalConstants } from './constants';
import Modal from 'react-modal';
import Button from '../components/Button';
import iconClasses from '../constants/iconClasses';
import UserContext from '../contexts/UserContext';
import { callRoute, routes } from '../helpers/request';
import {
    showErrorNotification,
    showSuccessNotification,
} from '../helpers/popup';
import Input from '../components/Input';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 500,
        zIndex: 1000,
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        overflowY: 'auto',
    },
};

Modal.setAppElement('#root');

const UserModal = ({ closeModal, reload, companyId }) => {
    const userContext = useContext(UserContext);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [fullName, setFullName] = useState(null);

    const [email, setEmail] = useState(null);

    const { lang } = userContext;

    const submit = async () => {
        setIsSubmitting(true);

        try {
            await callRoute({
                route: routes.user,
                method: 'POST',
                body: {
                    fullName,
                    email,
                    companyId,
                },
                lang,
            });

            showSuccessNotification(
                `${fullName} ${modalConstants.hasBeenSavedSuccessfully[lang]}`
            );

            reload();

            closeModal();
        } catch (e) {
            showErrorNotification(e);
        }

        setIsSubmitting(false);
    };

    return (
        <Modal isOpen={true} style={customStyles} onRequestClose={closeModal}>
            <h4 className="mb-3">{modalConstants.userModalHeaderText[lang]}</h4>
            <Input
                value={fullName}
                onChange={setFullName}
                label={modalConstants.userModalFullNameLabel[lang]}
                placeholder={modalConstants.userModalFullNamePlaceholder[lang]}
                maxLength={48}
            />
            <Input
                value={email}
                onChange={setEmail}
                label={modalConstants.userModalEmailLabel[lang]}
                placeholder={modalConstants.userModalEmailPlaceholder[lang]}
                maxLength={48}
            />
            <div className="d-flex mt-5 justify-content-end">
                <Button
                    onClick={closeModal}
                    style={{ width: 90 }}
                    icon={iconClasses.times}
                >
                    {modalConstants.cancel[lang]}
                </Button>
                <Button
                    onClick={submit}
                    style={{ width: 90, marginLeft: 8 }}
                    icon={iconClasses.save}
                    loading={isSubmitting}
                    disabled={!fullName || !email || isSubmitting}
                >
                    {modalConstants.save[lang]}
                </Button>
            </div>
        </Modal>
    );
};

export default UserModal;
