export const adminDashboardStrings = {
    todayReservations: {
        tr: 'Bugün - Randevular',
        en: 'Today - Reservations'
    },
    weekReservations: {
        tr: 'Bu hafta - Randevular',
        en: 'This week - Reservations'
    },
    monthReservations: {
        tr: 'Bu ay - Randevular',
        en: 'This month - Reservations'
    },
    todayDepositSize: {
        tr: 'Bugün - Bakiye yüklemeleri',
        en: 'Today - Deposit amount'
    },
    weekDepositSize: {
        tr: 'Bu hafta - Bakiye yüklemeleri',
        en: 'This week - Deposit amount'
    },
    monthDepositSize: {
        tr: 'Bu ay - Bakiye yüklemeleri',
        en: 'This month - Deposit amount'
    },
    todayDepositAmount: {
        tr: 'Bugün - Ciro',
        en: 'Today - Revenue'
    },
    weekDepositAmount: {
        tr: 'Bu hafta - Ciro',
        en: 'This week - Revenue'
    },
    monthDepositAmount: {
        tr: 'Bu ay - Ciro',
        en: 'This month - Revenue'
    }
}