import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/UserContext';
import FormContext from '../../../contexts/FormContext';
import { setPasswordStrings } from './constants';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { getParam } from '../../../helpers/url';
import Icon from '../../../components/Icon';
import iconClasses from '../../../constants/iconClasses';
import { Link } from 'react-router-dom';
import pages from '../../../constants/pages';
import { callRoute, routes } from '../../../helpers/request';
import { showErrorNotification } from '../../../helpers/popup';
import { inputTypes } from '../../../components/Input/constants';
import images from '../../../images';

const SetPasswordForm = () => {
    const userContext = useContext(UserContext);
    const formContext = useContext(FormContext);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    const { lang } = userContext;

    const submit = async () => {
        if (
            formContext.get('password') !== formContext.get('confirmPassword')
        ) {
            showErrorNotification(
                setPasswordStrings.passwordsNotMatching[lang]
            );
            return;
        }

        setIsSubmitting(true);

        try {
            await callRoute({
                route: routes.setPassword,
                method: 'POST',
                body: {
                    password: formContext.get('password'),
                    token: getParam('token'),
                },
                lang,
            });

            setIsCompleted(true);
        } catch (e) {
            showErrorNotification(e);
        }

        setIsSubmitting(false);
    };

    return (
        <div className="set-password-form-container">
            <img src={images.logo} className="mb-3" />
            <h3>{setPasswordStrings.headerText[lang]}</h3>
            {!isCompleted ? (
                <>
                    <Input
                        name="password"
                        label={setPasswordStrings.passwordLabel[lang]}
                        placeholder={
                            setPasswordStrings.passwordPlaceholder[lang]
                        }
                        inputType={inputTypes.password}
                    />
                    <Input
                        name="confirmPassword"
                        label={setPasswordStrings.passwordConfirmLabel[lang]}
                        placeholder={
                            setPasswordStrings.passwordConfirmPlaceholder[lang]
                        }
                        inputType={inputTypes.password}
                    />
                    <div className="mb-3">
                        {setPasswordStrings.emailText[lang].replace(
                            '<email>',
                            getParam('email')
                        )}
                    </div>
                    <div className="d-flex mt-4">
                        <Button
                            style={{ minWidth: 80, flex: 1 }}
                            onClick={submit}
                            loading={isSubmitting}
                            icon={iconClasses.chevronRight}
                            disabled={
                                !getParam('token') &&
                                !formContext.get('password') &&
                                !formContext.get('confirmPassword')
                            }
                        >
                            {setPasswordStrings.buttonText[lang]}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <div className="d-flex justify-content-center mt-5">
                        <Icon
                            className={iconClasses.circleCheck}
                            size={40}
                            color={'#198754'}
                        />
                    </div>
                    <div className="set-password-form-completed-text my-3">
                        {setPasswordStrings.completedText[lang].replace(
                            '<email>',
                            getParam('email')
                        )}
                    </div>
                    <Link
                        className="cms-link set-password-form-login-link"
                        onClick={() => {
                            window.location.href = pages.login.path;
                        }}
                    >
                        {setPasswordStrings.loginText[lang]}
                    </Link>
                </>
            )}
        </div>
    );
};

export default SetPasswordForm;
