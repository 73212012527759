import { useContext } from 'react';
import UserContext from '../../../../contexts/UserContext';
import { getFieldMapping } from '../../../../helpers/mapping';
import { routes } from '../../../../helpers/request';
import ListContainer from '../../../../reusables/ListContainer';
import { reservationTypeFields, reservationTypeStrings } from './constants';
import {
    getDurationListMapping,
    getInactiveListMapping,
    getPriceListMapping
} from '../../../../helpers/list';

const ReservationTypeList = () => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    return (
        <ListContainer
            route={routes.reservationType}
            headerMapping={getFieldMapping(
                reservationTypeFields,
                'label'
            )}
            valueMapping={{
                duration: getDurationListMapping,
                inactive: getInactiveListMapping,
                originalPrice: getPriceListMapping,
                discountedPrice: getPriceListMapping,
            }}
            titleText={reservationTypeStrings.listTitleText[lang]}
            newButtonText={reservationTypeStrings.newButtonText[lang]}
        />
    );
};

export default ReservationTypeList;
