export const reservationStrings = {
    newButtonText: {
        tr: 'Yeni randevu oluştur',
        en: 'New reservation',
    },
    createFormTitleText: {
        tr: 'Yeni Randevu',
        en: 'New reservation',
    },
};

export const reservationFields = {
    name: {
        name: 'name',
        label: {
            tr: 'İsim',
            en: 'Name',
        },
        placeholder: {
            tr: 'Musteri ismi',
            en: 'Customer name',
        },
        required: true,
    },
    reservationTypeId: {
        name: 'reservationTypeId',
        label: {
            tr: 'Tip',
            en: 'Type',
        },
        placeholder: {
            tr: 'Randevu tipi',
            en: 'Reservation type',
        },
        required: true,
    },
    reservationStaffId: {
        name: 'reservationStaffId',
        label: {
            tr: 'Personel',
            en: 'Staff',
        },
        placeholder: {
            tr: 'Randevu personeli',
            en: 'Reservation staff',
        },
        required: true,
    },
    startDate: {
        name: 'startDate',
        label: {
            tr: 'Tarih',
            en: 'Date',
        },
        placeholder: {
            tr: 'Randevu tarihi',
            en: 'Reservation date',
        },
        required: true,
    },
    note: {
        name: 'note',
        label: {
            tr: 'Not',
            en: 'Note',
        },
        placeholder: {
            tr: 'Randevu notu',
            en: 'Reservation note',
        },
    },
};

export const reservationCalendarFields = {
    staffFilter: {
        label: {
            tr: 'Personel',
            en: 'Staff',
        },
    },
    columnAmountFilter: {
        label: {
            tr: 'Sütun sayısı',
            en: 'Column amount',
        },
    },
    reservationStatusFilter: {
        label: {
            tr: 'Randevu durumu',
            en: 'Reservation status',
        },
    },
    reservationTypeFilter: {
        label: {
            tr: 'Randevu tipi',
            en: 'Reservation type',
        },
    }
};

export const columnAmountOptions = [
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '4',
        value: 4,
    },
    {
        label: '5',
        value: 5,
    },
    {
        label: '6',
        value: 6,
    },
    {
        label: '7',
        value: 7,
    },
];

export const reservationStatusOptions = {
    en: [
        {
            label: 'Active',
            value: 'active',
        },
        {
            label: 'Confirmed',
            value: 'confirmed',
        },
        {
            label: 'Cancelled',
            value: 'cancelled',
        },
        {
            label: 'Pending',
            value: 'pending',
        },
        {
            label: 'All',
            value: 'all',
        },
    ],
    tr: [
        {
            label: 'Aktif',
            value: 'active',
        },
        {
            label: 'Onaylanmış',
            value: 'confirmed',
        },
        {
            label: 'İptal edilmiş',
            value: 'cancelled',
        },
        {
            label: 'Bekleyen',
            value: 'pending',
        },
        {
            label: 'Tüm',
            value: 'all',
        },
    ],
};

export const allStaffOption = {
    en: {
        label: 'All staff',
        value: 'all',
    },
    tr: {
        label: 'Tüm personel',
        value: 'all',
    },
};

export const allReservationTypeOption = {
    en: {
        label: 'All types',
        value: 'all',
    },
    tr: {
        label: 'Tüm tipler',
        value: 'all',
    },
}