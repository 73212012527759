import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import Overlay from '../Overlay';

const LoadingOverlay = ({ hasSidebar }) => {
    return (
        <Overlay hasSidebar={hasSidebar}>
            <ClipLoader color={'#fff'} size={60} />
        </Overlay>
    );
};

export default LoadingOverlay;
