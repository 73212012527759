function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

export const isFloat = (value) => !value || isNumeric(value);

export const isInteger = (value) =>
    !value ||
    (isNumeric(value) &&
        value.indexOf('.') === -1);
