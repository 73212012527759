import React from 'react';

const Icon = ({ className, size = 13, color = '#fff', style = {}, onClick= () => {} }) => {
    return (
        <i
            style={{ fontSize: size, color, ...style }}
            className={className}
            onClick={onClick}
        />
    );
};

export default Icon;
