export const reservationTypeStrings = {
    newButtonText: {
        tr: 'Yeni randevu tipi',
        en: 'New reservation type',
    },
    listTitleText: {
        tr: 'Randevu tipleri',
        en: 'Reservation types',
    },
    createFormTitleText: {
        tr: 'Yeni Randevu tipi',
        en: 'New reservation type',
    },
};

export const reservationTypeFields = {
    name: {
        name: 'name',
        label: {
            tr: 'İsim',
            en: 'Name',
        },
        placeholder: {
            tr: 'Randevu tipinin ismi',
            en: 'Name of the reservation type',
        },
        showInList: true,
        required: true,
    },
    duration: {
        name: 'duration',
        label: {
            tr: 'Süre',
            en: 'Duration',
        },
        placeholder: {
            tr: 'Randevu suresi',
            en: 'Duration of reservations',
        },
        showInList: true,
        required: true,
    },
    reservationStaffIds: {
        name: 'reservationStaffIds',
        label: {
            tr: 'Personel',
            en: 'Staff',
        },
        placeholder: {
            tr: 'Randevu ile ilgilenen personel',
            en: 'Staff responsible for the reservation',
        },
        showInList: false,
    },
    description: {
        name: 'description',
        label: {
            tr: 'Açıklama',
            en: 'Description',
        },
        placeholder: {
            tr: 'Randevu tipi açıklaması',
            en: 'Description of reservation type',
        },
        showInList: false,
    },
    originalPrice: {
        name: 'originalPrice',
        label: {
            tr: 'Fiyat',
            en: 'Price',
        },
        placeholder: {
            tr: 'Fiyat',
            en: 'Price',
        },
        showInList: true,
        required: true,
    },
    discountedPrice: {
        name: 'discountedPrice',
        label: {
            tr: 'İndirimli fiyat',
            en: 'Price',
        },
        placeholder: {
            tr: 'İndirimli fiyat',
            en: 'Price',
        },
        showInList: true,
    },
    inactive: {
        name: 'inactive',
        label: {
            tr: 'İnaktif',
            en: 'Inactive',
        },
        showInList: true,
        listText: {
            tr: 'Durum',
            en: 'Status',
        },
    },
    logoUrl: {
        name: 'logoUrl',
        label: {
            tr: 'Logo',
            en: 'Logo',
        },
        showInList: false,
    },
};
