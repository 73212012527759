import { numbersToTime } from '../string';

export const getSlotsRange = (startTime, endTime) => {
    const result = [];

    if (!startTime || !endTime) return result;

    const startTimeComponents = startTime.split(':');

    const endTimeComponents = endTime.split(':');

    let hourIterator = parseInt(startTimeComponents[0]);

    let minuteIterator = parseInt(startTimeComponents[1]);

    const hourEnd = parseInt(endTimeComponents[0]);

    const minuteEnd = parseInt(endTimeComponents[1]);

    while (
        hourIterator < hourEnd ||
        (hourIterator === hourEnd && minuteIterator < minuteEnd)
    ) {
        result.push(numbersToTime(hourIterator, minuteIterator));

        minuteIterator += 15;

        if (minuteIterator === 60) {
            minuteIterator = 0;

            hourIterator += 1;
        }
    }

    return result;
};

export const parseSlot = (slot) => {
    const slotComponents = slot.split(':');

    return [parseInt(slotComponents[0]), parseInt(slotComponents[1])];
};
