import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { modalConstants } from './constants';
import UserContext from '../contexts/UserContext';
import Button from '../components/Button';
import { callRoute, routes } from '../helpers/request';
import {
    showCancellationModal,
    showErrorNotification,
    showSuccessNotification,
} from '../helpers/popup';
import Input from '../components/Input';
import { reservationStatuses } from '../constants/reservationStatuses';
import { dateToUtc } from '../helpers/date';
import iconClasses from '../constants/iconClasses';
import Textarea from '../components/Textarea';
import DateTimePicker from '../components/DateTimePicker';
import StatusBadge from '../components/StatusBadge';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        height: '100%',
        maxHeight: 700,
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 500,
        zIndex: 1000,
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 1000,
        overflowY: "auto",

    },
};

Modal.setAppElement('#root');

const ReservationViewModal = ({ afterOpen, closeModal, data, reload }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const [isUpdating, setIsUpdating] = useState(false);

    const [name, setName] = useState(null);

    const [reservationStartDate, setReservationStartDate] = useState(null);

    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const isUpcoming = () => {
        if (!data?.startDate || !data?.status) return false;

        let { startDate, status } = data;

        return (
            new Date() < dateToUtc(new Date(startDate)) &&
            status !== reservationStatuses.cancelled
        );
    };

    const isPending = () => {
        if (!data?.startDate || !data?.status) return false;

        let { status } = data;

        return status === reservationStatuses.pending;
    };

    const updateReservation = async () => {
        if (isUpdating) return;

        setIsUpdating(true);

        const { _id } = data;

        try {
            await callRoute({
                route: routes.reservation,
                method: 'PUT',
                lang,
                body: {
                    _id,
                    name,
                    startDate: reservationStartDate,
                },
            });

            await reload();

            closeModal();

            showSuccessNotification(
                name + modalConstants.hasBeenSavedSuccessfully[lang]
            );
        } catch (e) {
            showErrorNotification(e);
        }

        setIsUpdating(false);
    };

    const deleteReservation = async () => {
        if (isDeleting) return;

        setIsDeleting(true);

        const { _id } = data;

        showCancellationModal(
            async (cancellationReason) => {
                try {
                    await callRoute({
                        route: routes.reservationSetStatus,
                        method: 'POST',
                        lang,
                        body: {
                            reservationId: _id,
                            status: reservationStatuses.cancelled,
                            cancellationReason,
                        },
                    });

                    closeModal();

                    showSuccessNotification(
                        modalConstants.reservationCancelledSuccessfully[lang]
                    );

                    await reload();
                } catch (e) {
                    showErrorNotification(e);
                }
            },
            lang,
            modalConstants.cancelConfirmationTitle[lang]
        );

        setIsDeleting(false);
    };

    const confirmReservation = async () => {
        try {
            const { _id } = data;

            await callRoute({
                route: routes.reservationSetStatus,
                method: 'POST',
                lang,
                body: {
                    reservationId: _id,
                    status: reservationStatuses.confirmed,
                },
            });

            showSuccessNotification(modalConstants.confirmSuccessText[lang]);

            userContext.checkPendingReservationCount();
        } catch (e) {
            showErrorNotification(e);
        }

        await reload();

        closeModal();
    };

    useEffect(() => {
        setName(data?.name);
        setReservationStartDate(dateToUtc(new Date(data?.startDate || null)));
    }, [data]);

    return (
        <>
            <Modal
                isOpen={data !== null}
                onAfterOpen={afterOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <h4>{modalConstants.viewReservationHeaderText[lang]}</h4>
                    <StatusBadge status={data?.status} />
                </div>
                <div className="row">
                    <div className="col-12">
                        <Input
                            value={name}
                            onChange={setName}
                            label={modalConstants.name[lang]}
                            placeholder={modalConstants.name[lang]}
                            disabled={!isUpcoming() || isPending()}
                            maxLength={48}
                        />
                    </div>
                    <div className="col-6">
                        <DateTimePicker
                            value={reservationStartDate}
                            onChange={setReservationStartDate}
                            label={modalConstants.startDate[lang]}
                            placeholder={modalConstants.startDate[lang]}
                            disabled={!isUpcoming() || isPending()}
                            useAvailability
                            reservationTypeId={data?.reservationType?._id}
                            reservationStaffId={data?.reservationStaff?._id}
                            excludeReservationIds={[data?._id]}
                        />
                    </div>
                    <div className="col-6">
                        <Input
                            value={`${data?.transactionPrice} ${
                                data?.transactionCurrency || ''
                            }`}
                            onChange={() => {}}
                            label={modalConstants.price[lang]}
                            placeholder={modalConstants.price[lang]}
                            disabled={true}
                            maxLength={48}
                        />
                    </div>
                    <div className="col-6">
                        <Input
                            value={data?.reservationType?.name}
                            onChange={() => {}}
                            label={modalConstants.reservationType[lang]}
                            placeholder={modalConstants.reservationType[lang]}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6">
                        <Input
                            value={data?.reservationStaff?.name}
                            onChange={() => {}}
                            label={modalConstants.reservationStaff[lang]}
                            placeholder={modalConstants.reservationStaff[lang]}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6">
                        <Input
                            value={data?.customer?.fullName}
                            onChange={() => {}}
                            label={modalConstants.customerName[lang]}
                            placeholder={modalConstants.customerName[lang]}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6">
                        <Input
                            value={data?.customer?.phoneNumber}
                            onChange={() => {}}
                            label={modalConstants.customerPhone[lang]}
                            placeholder={modalConstants.customerPhone[lang]}
                            disabled={true}
                        />
                    </div>
                    <div className="col-12">
                        <Textarea
                            value={data?.note}
                            onChange={() => {}}
                            label={modalConstants.note[lang]}
                            placeholder={modalConstants.note[lang]}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="d-flex mt-3 justify-content-end">
                    <Button
                        onClick={() => closeModal()}
                        className="primary"
                        icon={iconClasses.arrowLeft}
                    >
                        {modalConstants.goBack[lang]}
                    </Button>
                    <div  className='flex-grow-1' />
                    <Button
                        onClick={deleteReservation}
                        loading={isDeleting}
                        className="danger"
                        disabled={!isUpcoming()}
                        icon={iconClasses.times}
                        style={{ marginLeft: 8 }}
                    >
                        {modalConstants.cancelReservation[lang]}
                    </Button>
                    {isPending() ? (
                        <>
                            <Button
                                onClick={confirmReservation}
                                loading={isUpdating}
                                style={{ marginLeft: 8 }}
                                className="success"
                                icon={iconClasses.check}
                            >
                                {modalConstants.confirm[lang]}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={updateReservation}
                                loading={isUpdating}
                                style={{ marginLeft: 8 }}
                                className="success"
                                disabled={!isUpcoming()}
                                icon={iconClasses.save}
                            >
                                {modalConstants.update[lang]}
                            </Button>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default ReservationViewModal;
