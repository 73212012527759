import iconClasses from './iconClasses';
import pages from './pages';

const sidebar = {
    manager: [
        {
            title: {
                tr: 'Ana sayfa',
                en: 'Home',
            },
            icon: iconClasses.house,
            path: pages.managerDashboard.path,
        },
        {
            title: {
                tr: 'Randevular',
                en: 'Reservations',
            },
            icon: iconClasses.calendar,
            elements: [
                {
                    title: {
                        tr: 'Randevu takvimi',
                        en: 'Reservation calendar',
                    },
                    icon: iconClasses.calendarCheck,
                    path: pages.reservationCalendar.path,
                },

                {
                    title: {
                        tr: 'Bekleyen randevular',
                        en: 'Pending reservations',
                    },
                    icon: iconClasses.clock,
                    path: pages.reservationPending.path,
                },

                {
                    title: {
                        tr: 'Randevu tipleri',
                        en: 'Reservation types',
                    },
                    icon: iconClasses.tags,
                    path: pages.reservationTypeList.path,
                },
                {
                    title: {
                        tr: 'Randevu personeli',
                        en: 'Reservation staff',
                    },
                    icon: iconClasses.users,
                    path: pages.reservationStaffList.path,
                },
                {
                    title: {
                        tr: 'Tatil aralıkları',
                        en: 'Blackouts',
                    },
                    icon: iconClasses.calendarMinus,
                    path: pages.blackoutList.path,
                },
                {
                    title: {
                        tr: 'Randevu ayarları',
                        en: 'Reservation settings',
                    },
                    icon: iconClasses.gear,
                    path: pages.reservationSettings.path,
                },
            ],
        },
        {
            title: {
                tr: 'Şirket',
                en: 'Company',
            },
            icon: iconClasses.building,
            path: pages.companyForm.path,
        },
        // {
        //     title: {
        //         tr: 'Duyurular',
        //         en: 'Announcements',
        //     },
        //     icon: iconClasses.bell,
        //     path: pages.announcementList.path,
        // },
        // {
        //     title: {
        //         tr: 'Destek',
        //         en: 'Support',
        //     },
        //     icon: iconClasses.ticket,
        //     path: pages.ticketList.path,
        // },
        {
            title: {
                tr: 'Bakiye Yukleme',
                en: 'Deposit',
            },
            icon: iconClasses.coins,
            path: pages.deposit.path,
        },
    ],
    admin: [
        {
            title: {
                tr: 'Ana sayfa',
                en: 'Home',
            },
            icon: iconClasses.house,
            path: pages.adminDashboard.path,
        },
        {
            title: {
                tr: 'Şirketler',
                en: 'Companies',
            },
            icon: iconClasses.building,
            path: pages.adminCompanyList.path,
        },
        // {
        //     title: {
        //         tr: 'Satış ortakları',
        //         en: 'Affiliate',
        //     },
        //     icon: iconClasses.users,
        //     path: pages.adminAffiliateList.path,
        // },
        // {
        //     title: {
        //         tr: 'Destek talepleri',
        //         en: 'Tickets',
        //     },
        //     icon: iconClasses.ticket,
        //     path: pages.adminTicketList.path,
        // },
        {
            title: {
                tr: 'Bakiye yükleme',
                en: 'Deposit',
            },
            icon: iconClasses.coins,
            elements: [
                {
                    title: {
                        tr: 'Bekleyen yüklemeler',
                        en: 'Pending deposits',
                    },
                    icon: iconClasses.clock,
                    path: pages.adminDepositPending.path,
                },
                {
                    title: {
                        tr: 'Bakiye paketleri',
                        en: 'Deposit products',
                    },
                    icon: iconClasses.cubes,
                    path: pages.adminDepositProductList.path,
                },
            ],
        },
    ],
    affiliate: [
        {
            title: {
                tr: 'Ana sayfa',
                en: 'Home',
            },
            icon: iconClasses.house,
            path: pages.affiliateDashboard.path,
        },
    ],
};

export default sidebar;
