import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../../contexts/UserContext';
import FormContext from '../../../../contexts/FormContext';
import './styles.scss';
import {
    reservationStaffAvailabilityCalendarConstants,
    reservationStaffAvailabilityCalendarStrings,
} from './constants';
import { timeToLocale } from '../../../../helpers/date';
import { getSlotsRange } from '../../../../helpers/reservation';

const availabilityCalendarDefault = {
    availableSlots: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    },
    unavailableSlots: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    },
};

const ReservationStaffAvailabilityCalendar = ({ name, field }) => {
    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const [slots, setSlots] = useState(null);

    const [availabilityCalendarData, setAvailabilityCalendarData] = useState(
        {}
    );

    const [mouseDownSlot, setMouseDownSlot] = useState(null);

    const [mouseOverSlot, setMouseOverSlot] = useState(null);

    const { lang, company, isLoading: userContextLoading } = userContext;

    if (field) name = field.name;

    const getAvailabilityCalendar = () => {
        const availabilityCalendar = formContext.get(name);

        slots.forEach((slotItem, dayIndex) => {
            slotItem.forEach((slot) => {
                if (
                    availabilityCalendar &&
                    availabilityCalendar.availableSlots[dayIndex].indexOf(
                        slot
                    ) === -1 &&
                    availabilityCalendar.unavailableSlots[dayIndex].indexOf(
                        slot
                    ) === -1
                ) {
                    availabilityCalendar.availableSlots[dayIndex].push(slot);
                }
            });
        });

        return availabilityCalendar;
    };

    const setAvailabilityCalendar = (value) => {
        if (!value) return;

        const updateObject = {};

        updateObject[name] = value;

        formContext.update(updateObject);
    };

    const getDisplaySlots = () => {
        const result = [];

        slots.forEach((slotItem) => {
            slotItem.forEach((slot) => {
                if (result.indexOf(slot) === -1) result.push(slot);
            });
        });

        return result.sort();
    };

    const loadSlots = () => {
        const slots = [[], [], [], [], [], [], []];

        const shiftTimes = company.shiftTimes;

        for (let i = 0; i < 7; i++) {
            slots[i] = getSlotsRange(shiftTimes[i][0], shiftTimes[i][1]);
        }

        setSlots(slots);
    };

    const initializeAvailabilityCalendar = (availabilityCalendarData) => {
        const result = availabilityCalendarDefault;

        Object.keys(availabilityCalendarData).forEach((slot) => {
            availabilityCalendarData[slot].forEach((value, dayIndex) => {
                if (value === 1) {
                    result.availableSlots[dayIndex].push(slot);
                } else if (value === -1) {
                    result.unavailableSlots[dayIndex].push(slot);
                }
            });
        });

        setAvailabilityCalendar(result);
    };

    const loadAvailabilityCalendarData = (
        existingAvailabilityCalendar = null
    ) => {
        let result = {};

        const availabilityCalendar =
            existingAvailabilityCalendar || getAvailabilityCalendar();

        getDisplaySlots().forEach((slot) => {
            result[slot] = [];

            for (let i = 0; i < 7; i++) {
                const isInSlots = slots[i].indexOf(slot) > -1;

                const isAvailable =
                    isInSlots &&
                    (!availabilityCalendar ||
                        availabilityCalendar.availableSlots[i].indexOf(slot) >
                            -1);

                const isUnavailable =
                    isInSlots &&
                    availabilityCalendar &&
                    availabilityCalendar.unavailableSlots[i].indexOf(slot) > -1;

                const value = isAvailable ? 1 : isUnavailable ? -1 : 0;

                result[slot].push(value);
            }
        });

        setAvailabilityCalendarData(Object.assign({}, result));

        if (!availabilityCalendar) {
            setTimeout(() => {
                initializeAvailabilityCalendar(result);
            }, 1000);
        }
    };

    const getSelectionList = () => {
        const result = [];

        if (!mouseOverSlot || !mouseDownSlot) return result;

        let startSlot = {};

        let endSlot = {};

        if (mouseDownSlot.slot > mouseOverSlot.slot) {
            startSlot.slot = mouseOverSlot.slot

            endSlot.slot = mouseDownSlot.slot
        } else {
            startSlot.slot = mouseDownSlot.slot

            endSlot.slot = mouseOverSlot.slot
        }

        if (mouseDownSlot.dayIndex > mouseOverSlot.dayIndex) {
            startSlot.dayIndex = mouseOverSlot.dayIndex

            endSlot.dayIndex = mouseDownSlot.dayIndex
        } else {
            startSlot.dayIndex = mouseDownSlot.dayIndex

            endSlot.dayIndex = mouseOverSlot.dayIndex
        }

        Object.keys(availabilityCalendarData).forEach((slot) => {
            availabilityCalendarData[slot].forEach((_, dayIndex) => {
                if (
                    dayIndex >= startSlot.dayIndex &&
                    endSlot.dayIndex >= dayIndex &&
                    slot >= startSlot.slot &&
                    endSlot.slot >= slot
                ) {
                    result.push({
                        slot,
                        dayIndex,
                    });
                }
            });
        });

        return result;
    };

    const handleSelection = () => {
        const selectionList = getSelectionList();

        const availabilityCalendar = Object.assign(
            {},
            getAvailabilityCalendar()
        );

        for (let selection of selectionList) {
            const dayIndex = selection.dayIndex;

            const slot = selection.slot;

            const slotValue = availabilityCalendarData[slot][dayIndex];

            if (
                slotValue === 0 ||
                !Object.keys(availabilityCalendar || {}).length
            )
                return;
            else if (slotValue === 1) {
                const slotIndex =
                    availabilityCalendar.availableSlots[dayIndex].indexOf(slot);
                availabilityCalendar.availableSlots[dayIndex].splice(
                    slotIndex,
                    1
                );
                availabilityCalendar.unavailableSlots[dayIndex].push(slot);
                availabilityCalendar.unavailableSlots[dayIndex] =
                    availabilityCalendar.unavailableSlots[dayIndex].sort();
            } else if (slotValue === -1) {
                const slotIndex =
                    availabilityCalendar.unavailableSlots[dayIndex].indexOf(
                        slot
                    );
                availabilityCalendar.unavailableSlots[dayIndex].splice(
                    slotIndex,
                    1
                );
                availabilityCalendar.availableSlots[dayIndex].push(slot);
                availabilityCalendar.availableSlots[dayIndex] =
                    availabilityCalendar.availableSlots[dayIndex].sort();
            }
        }

        setAvailabilityCalendar(availabilityCalendar);

        loadAvailabilityCalendarData(availabilityCalendar);

        setMouseDownSlot(null);

        setMouseOverSlot(null);
    };

    const resolveCellClass = (availability, slot, dayIndex) => {
        let className = 'disabled';

        const selectionList = getSelectionList();

        const inSelection =
            selectionList.find(
                (selection) =>
                    selection.slot === slot && selection.dayIndex === dayIndex
            ) !== undefined;

        if (
            (!inSelection && availability === 1) ||
            (inSelection && availability === -1)
        ) {
            className = 'available';
        } else if (
            (!inSelection && availability === -1) ||
            (inSelection && availability === 1)
        ) {
            className = 'unavailable';
        }

        return 'cms-staff-availability-calendar-cell ' + className;
    };

    useEffect(() => {
        if (company && !userContextLoading && slots === null) loadSlots();
    }, [company, userContextLoading]);

    useEffect(() => {
        if ((slots || []).length > 0) loadAvailabilityCalendarData();
    }, [slots]);

    return (
        <>
            <h4 className="mt-5 mb-4 text-center">
                {reservationStaffAvailabilityCalendarStrings.headerText[lang]}
            </h4>
            <table className="table table-borderless cms-staff-availability-calendar-container" onMouseLeave={() => {
                setMouseDownSlot(null)

                setMouseOverSlot(null)
            }}>
                <thead>
                    <tr>
                        <th colSpan={1}></th>
                        {reservationStaffAvailabilityCalendarConstants.days[
                            lang
                        ].map((day, index) => (
                            <th
                                key={index}
                                className="cms-staff-availability-calendar-head-cell"
                                colSpan={2}
                            >
                                {day}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(availabilityCalendarData).map(
                        (slot, slotIndex) => (
                            <tr key={'row-' + slotIndex}>
                                <td
                                    colSpan={1}
                                    className="cms-staff-availability-calendar-display-cell"
                                >
                                    {timeToLocale(slot)}
                                </td>
                                {availabilityCalendarData[slot].map(
                                    (availabilityValue, dayIndex) => (
                                        <td
                                            key={
                                                'cell-' +
                                                (
                                                    10 * slotIndex +
                                                    dayIndex
                                                ).toString()
                                            }
                                            colSpan={2}
                                            className={resolveCellClass(
                                                availabilityValue,
                                                slot,
                                                dayIndex
                                            )}
                                            onMouseDown={() =>
                                                setMouseDownSlot({
                                                    dayIndex,
                                                    slot,
                                                })
                                            }
                                            onMouseOver={() => {
                                                setMouseOverSlot({
                                                    dayIndex,
                                                    slot,
                                                });
                                            }}
                                            onMouseUp={() => handleSelection()}
                                        />
                                    )
                                )}
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </>
    );
};

export default ReservationStaffAvailabilityCalendar;
