export const resolveUrl = (route = '', rewriteUrl = {}, id = null) => {
    // const origin = process.env.REACT_APP_BACKEND_URL;
    const origin = '';

    let url = origin + route;

    if (url[0] !== '/') url = `/${url}`;

    Object.keys(rewriteUrl || {}).forEach((item) => {
        url = url.replace(item, rewriteUrl[item]);
    });

    if (id) url += `/${id}`;

    return url;
};
