import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import FormContext from '../../../../contexts/FormContext';
import UserContext from '../../../../contexts/UserContext';
import { getNameOptions } from '../../../../helpers/mapping';
import { routes } from '../../../../helpers/request';
import FormContainer from '../../../../reusables/FormContainer';
import { depositProductFields, depositProductStrings } from './constants';
import pages from '../../../../constants/pages';
import { localSort } from '../../../../helpers/list';

const AdminDepositProductForm = () => {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const formContext = useContext(FormContext);

    const { lists } = formContext;

    const { lang } = userContext;

    const { name, price, tokenAmount, countryId } = depositProductFields;

    const getCountryOptions = () =>
        getNameOptions(localSort(lists[routes.country], lang));

    const getSelectedCountry = () => {
        const countryId = formContext.data?.countryId;

        if (countryId)
            return lists[routes.country].find((item) => item._id === countryId);

        return null;
    };

    useEffect(() => {
        formContext.update({
            currency: getSelectedCountry()?.currency
        })
    }, [formContext.data?.countryId])

    return (
        <FormContainer
            route={routes.depositProduct}
            lists={[routes.country]}
            fields={depositProductFields}
            createTitleText={depositProductStrings.createFormTitleText[lang]}
            goBackUrl={pages.adminDepositProductList.path}
            afterSubmit={() => navigate(pages.adminDepositProductList.path)}
        >
            <div className="col-lg-6">
                <Input field={name} maxLength={48} />
            </div>
            <div className="col-lg-6">
                <Select field={countryId} options={getCountryOptions()} />
            </div>
            <div className="col-lg-6">
                <Input
                    type="float"
                    field={price}
                    postFix={
                        getSelectedCountry()
                            ? `(${getSelectedCountry().currency})`
                            : ''
                    }
                />
            </div>
            <div className="col-lg-6">
                <Input type="float" field={tokenAmount} />
            </div>
        </FormContainer>
    );
};

export default AdminDepositProductForm;
