import React, { useContext } from 'react';
import UserContext from '../../../../contexts/UserContext';
import { depositPendingFields, depositPendingStrings } from './constants';
import { callRoute, routes } from '../../../../helpers/request';
import ListContainer from '../../../../reusables/ListContainer';
import { getFieldMapping } from '../../../../helpers/mapping';
import {
    getDatetimeListMapping,
    getNameListMapping,
} from '../../../../helpers/list';
import iconClasses from '../../../../constants/iconClasses';
import {
    showConfirmationModal,
    showErrorNotification,
    showSuccessNotification,
} from '../../../../helpers/popup';
import { paymentStatuses } from '../../../../constants/paymentStatuses';

const AdminDepositPending = ({ iframe }) => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    const setDepositStatus = async (depositId, status) => {
        await callRoute({
            route: routes.depositSetStatus,
            method: 'POST',
            body: {
                depositId,
                status,
            },
            lang,
        });
    };

    const confirmReservation = async (item, load) => {
        try {
            showConfirmationModal(
                async () => {
                    try {
                        await setDepositStatus(
                            item?._id,
                            paymentStatuses.confirmed
                        );

                        showSuccessNotification(
                            depositPendingStrings.confirmSuccessText[lang]
                        );

                        setTimeout(() => {
                            load();
                        }, 200);
                    } catch (e) {
                        showErrorNotification(e);
                    }
                },
                lang,
                depositPendingStrings.confirmTitle[lang]
            );
        } catch (error) {
            showErrorNotification(error);
        }
    };

    const cancelReservation = async (item, load) => {
        try {
            showConfirmationModal(
                async () => {
                    try {
                        await setDepositStatus(
                            item?._id,
                            paymentStatuses.cancelled
                        );

                        showSuccessNotification(
                            depositPendingStrings.cancelSuccessText[lang]
                        );

                        setTimeout(() => {
                            load();
                        }, 200);
                    } catch (e) {
                        showErrorNotification(e);
                    }
                },
                lang,
                depositPendingStrings.cancelConfirmationTitle[lang]
            );
        } catch (error) {
            showErrorNotification(error);
        }
    };

    return (
        <ListContainer
            route={routes.depositPending}
            headerMapping={getFieldMapping(depositPendingFields, 'label')}
            valueMapping={{
                user: getNameListMapping,
                createdAt: getDatetimeListMapping,
            }}
            titleText={depositPendingStrings.listTitleText[lang]}
            disableNew={true}
            editActionAllowed={false}
            deleteActionAllowed={false}
            customActions={[
                {
                    icon: iconClasses.check,
                    action: confirmReservation,
                    className: 'success',
                },
                {
                    icon: iconClasses.times,
                    action: cancelReservation,
                    className: 'danger',
                },
            ]}
            disableHeader={iframe}
        />
    );
};

export default AdminDepositPending;
