import { useContext } from 'react';
import UserContext from '../../../../contexts/UserContext';
import { getFieldMapping } from '../../../../helpers/mapping';
import { routes } from '../../../../helpers/request';
import ListContainer from '../../../../reusables/ListContainer';
import { reservationStaffFields, reservationStaffStrings } from './constants';
import { getInactiveListMapping } from '../../../../helpers/list';

const ReservationStaffList = () => {
    const userContext = useContext(UserContext);

    const { lang } = userContext;

    return (
        <ListContainer
            route={routes.reservationStaff}
            headerMapping={getFieldMapping(reservationStaffFields, 'label')}
            titleText={reservationStaffStrings.listTitleText[lang]}
            newButtonText={reservationStaffStrings.newButtonText[lang]}
            valueMapping={{
                inactive: getInactiveListMapping,
            }}
        />
    );
};

export default ReservationStaffList;
