import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/UserContext';
import FormContext from '../../../contexts/FormContext';
import { forgotPasswordStrings } from './constants';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';
import pages from '../../../constants/pages';
import Icon from '../../../components/Icon';
import iconClasses from '../../../constants/iconClasses';
import { callRoute, routes } from '../../../helpers/request';
import { showErrorNotification } from '../../../helpers/popup';
import images from '../../../images';

const ForgotPasswordForm = () => {
    const userContext = useContext(UserContext);
    const formContext = useContext(FormContext);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    const { lang } = userContext;

    const submit = async () => {
        setIsSubmitting(true);

        try {
            await callRoute({
                route: routes.forgotPassword,
                method: 'POST',
                body: {
                    email: formContext.get('email'),
                },
                lang,
            });

            setIsCompleted(true);
        } catch (e) {
            showErrorNotification(e);
        }

        setIsSubmitting(false);
    };

    useEffect(() => {
        formContext.reset();
    }, []);

    return (
        <div className="forgot-password-form-container">
            <img src={images.logo} className="mb-3" />
            <h3>{forgotPasswordStrings.headerText[lang]}</h3>
            {!isCompleted ? (
                <>
                    <Input
                        name="email"
                        label={forgotPasswordStrings.emailLabel[lang]}
                        placeholder={
                            forgotPasswordStrings.emailPlaceholder[lang]
                        }
                    />
                    <div className="d-flex mt-4">
                        <Button
                            style={{ minWidth: 80, flex: 1 }}
                            onClick={submit}
                            loading={isSubmitting}
                            icon={iconClasses.chevronRight}
                            disabled={!formContext.get('email')}
                        >
                            {forgotPasswordStrings.buttonText[lang]}
                        </Button>
                    </div>
                    <div className="forgot-password-form-or-text">
                        {forgotPasswordStrings.orText[lang]}
                    </div>
                </>
            ) : (
                <>
                    <div className="d-flex justify-content-center mt-5">
                        <Icon
                            className={iconClasses.circleCheck}
                            size={40}
                            color={'#500038'}
                        />
                    </div>
                    <div className="forgot-password-form-completed-text my-3">
                        {forgotPasswordStrings.completedText[lang].replace(
                            '<email>',
                            formContext.get('email')
                        )}
                    </div>
                </>
            )}
            <Link
                className="cms-link forgot-password-form-login-link"
                onClick={() => {
                    window.location.href = pages.login.path;
                }}
            >
                {forgotPasswordStrings.loginText[lang]}
            </Link>
        </div>
    );
};

export default ForgotPasswordForm;
