import { useContext } from 'react';
import iconClasses from '../../constants/iconClasses';
import Icon from '../Icon';
import { depositProductStrings } from './ constants';
import './styles.scss';
import UserContext from '../../contexts/UserContext';

const DepositProduct = ({ item, onClick }) => {
    const userContext = useContext(UserContext);

    const { lang, company } = userContext;

    const appliedPrice = parseFloat(
        company.discountPercentage !== 0
            ? item.price * ((100 - company.discountPercentage) / 100)
            : item.price
    ).toFixed(2);

    const perTokenPrice = parseFloat(appliedPrice / item.tokenAmount).toFixed(
        2
    );

    return (
        <div className="deposit-product" onClick={onClick}>
            <div className="deposit-product-name">
                {item.name.toUpperCase()}
            </div>
            <div className="deposit-product-token">
                {`${item.tokenAmount} ${depositProductStrings.tokenText[lang]} x ${perTokenPrice} ${item.currency}`}
            </div>
            <div className="deposit-product-price-container">
                {company.discountPercentage !== 0 && (
                    <div className="deposit-product-price-old">
                        {`${item.price} ${item.currency}`}
                    </div>
                )}
                <div className="deposit-product-price">
                    {`${appliedPrice} ${item.currency}`}
                </div>
            </div>
            <div className="deposit-product-overlay">
                <Icon
                    className={iconClasses.cart}
                    color={'#fff'}
                    size={20}
                    style={{ marginRight: 12 }}
                />
                {depositProductStrings.checkoutText[lang]}
            </div>
            {company.discountPercentage !== 0 && (
                <div className="deposit-product-discount-badge">
                    -{company.discountPercentage}%
                </div>
            )}
        </div>
    );
};

export default DepositProduct;
